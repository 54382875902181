@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@300;400;500;600;700&amp;family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700;800&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700;800&amp;display=swap");
:root {
  --font-body: "Roboto", sans-serif;
  --font-heading: "Livvic", sans-serif;
  --gradient-color-from: #fc7632;
  --gradient-color-to: #f72750;
  --color-primary: #f72750;
  --thm-color-2: #bfef2d;
  --thm-color-3: #dc860f;
  --thm-color-4: #5f2dde;
  --thm-color-5: #ff613c;
  --color-violet: #361f61;
  --color-violet-2: #453266;
  --color-deep: #0e0027;
  --color-deep-2: #0a183f;
  --color-deep-3: #00071a;
  --color-white: #fff;
  --color-black: #000;
  --color-default: #1c1d20;
  --color-gray: #eeedef;
}

/* reset css start */
html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 30px;
  color: #6e6d79;
  background-color: var(--color-white);
}

body.demo-two {
  background-color: #00061a;
}
body.demo-two main {
  background-color: #00061a;
}

body.demo-dark {
  background-color: var(--color-black);
}
body.demo-dark main {
  background-color: var(--color-black);
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0px;
  padding: 0px;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button {
  border: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
a:hover {
  color: inherit;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
form select,
textarea {
  width: 100%;
  height: 60px;
  border-radius: 0;
  padding: 20px;
  border: 1px solid transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 20px;
  color: var(--color-black);
  font-weight: 400;
  background-color: #f4f3f6;
  line-height: 1;
}
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
form select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
form select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
form select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
form select:-moz-placeholder,
textarea:-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
form select:focus,
textarea:focus {
  border-color: var(--color-primary);
}

select {
  height: 55px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../img/icon/select-arrow.png);
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  padding-right: 20px;
  background-color: transparent;
  border: 0;
}

textarea {
  min-height: 150px;
}

button {
  border: 0;
}

table {
  width: 100%;
}

p,
li,
span {
  margin-bottom: 0;
}

/* reset css end */
/* global css start */
.nice-select {
  background-color: transparent;
  height: 40px !important;
  line-height: 40px !important;
  min-height: 40px !important;
  padding: 0 30px;
}
.nice-select span {
  color: var(--color-black);
}
.nice-select .list {
  -webkit-box-shadow: 0.975px 7.94px 21px 0px rgba(239, 239, 239, 0.5);
  box-shadow: 0.975px 7.94px 21px 0px rgba(239, 239, 239, 0.5);
}
.nice-select .list li {
  margin-right: 0 !important;
}
.nice-select .list .option {
  color: var(--color-black);
}
.nice-select .list .option.selected,
.nice-select .list .option:hover {
  border: none !important;
}

/* global css end */
.body_wrap {
  position: relative;
  z-index: 1;
}

main {
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: var(--color-white);
  overflow: hidden;
}

.bg_img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.footer-bg {
  background-color: #04060a;
}

.black-bg {
  background: var(--color-black);
}

.gray-bg {
  background-color: var(--color-gray);
}

.bg-deep {
  background-color: var(--color-deep);
}

.bg-violet {
  background-color: var(--color-violet);
}

.white {
  color: var(--color-white);
}

.pos-rel {
  position: relative;
}

.section-bg-gray {
  background-color: #090909;
}

.pos-absolute {
  position: absolute;
}

.f-right {
  float: right;
}

.border-effect a,
.border-effect-2 a {
  display: inline !important;
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: -2px;
  background-image: linear-gradient(
    transparent calc(100% - 2px),
    currentColor 1px
  );
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.border-effect a:hover,
.border-effect-2 a:hover {
  background-size: 100% 100%;
  color: inherit;
}

.border-effect-2 a {
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
}

.btn-video {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-white);
  position: relative;
  font-size: 14px;
  background-color: #201f1f;
  color: #f2b908;
  z-index: 1;
}
.btn-video:hover {
  color: #f2b908;
}
.btn-video__gradient {
  color: var(--color-white);
}
.btn-video__gradient:hover {
  color: var(--color-white);
}
.btn-video::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #201f1f;
  /* background-color: rgba(15,103,246,.63); */
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  z-index: -2;
}
.btn-video__white {
  background: var(--color-white);
  color: var(--color-deep);
}
.btn-video__white::before {
  background-color: var(--color-white);
}
.btn-video__white:hover {
  color: var(--color-deep);
}
.btn-video__xl {
  width: 100px;
  height: 100px;
  font-size: 22px;
}
@media (max-width: 767px) {
  .btn-video__xl {
    width: 70px;
    height: 70px;
    font-size: 17px;
  }
}
.btn-video__lg {
  width: 80px;
  height: 80px;
  font-size: 18px;
}
@media (max-width: 767px) {
  .btn-video__lg {
    width: 70px;
    height: 70px;
    font-size: 17px;
  }
}
.btn-video__middle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.border_effect a {
  display: inline !important;
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: -2px;
  background-image: linear-gradient(
    transparent calc(100% - 2px),
    currentColor 1px
  );
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.border_effect a:hover {
  background-size: 100% 100%;
  color: inherit;
}

@media (max-width: 991px) {
  .tx-col-md-6 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .tx-col-md-6 {
    width: 100%;
  }
}

.tx-close {
  background: rgba(0, 0, 0, 0.04);
  border: 9px solid transparent;
  color: #777;
  width: 36px;
  height: 36px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.tx-close::before,
.tx-close::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #1b1b1b;
}
.tx-close::before {
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  transform: rotate(45deg);
}
.tx-close::after {
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.tx-close:hover::before,
.tx-close:hover::after {
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  transform: rotate(0);
}

/* order & unorder list reset - start */
.ul_li,
.ul_li_right,
.ul_li_center,
.ul_li_between {
  margin: 0px;
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ul_li > li,
.ul_li_right > li,
.ul_li_center > li,
.ul_li_between > li {
  float: left;
  list-style: none;
  display: inline-block;
}

.ul_li {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.ul_li_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ul_li_right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.ul_li_between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ul_li_block {
  margin: 0px;
  padding: 0px;
  display: block;
}

.ul_li_block > li {
  display: block;
  list-style: none;
}

.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.color-black {
  color: var(--color-black);
}

.pagination_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
  list-style: none;
}
.pagination_wrap ul li {
  padding: 5px;
}
.pagination_wrap ul li a {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  border: 1px solid #ededed;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
}
.pagination_wrap ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pagination_wrap ul li a:hover::before,
.pagination_wrap ul li a.current_page::before {
  opacity: 1;
}
.pagination_wrap ul li a.current_page,
.pagination_wrap ul li a:hover {
  color: var(--color-white);
  border-color: transparent;
}

.mr-none-60 {
  margin-right: -60px;
}

.ml-none-60 {
  margin-left: -60px;
}

.pb-8 {
  padding-bottom: 8px;
}

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-none-5 {
  margin-top: -5px;
}

.mt-none-10 {
  margin-top: -10px;
}

.mt-none-15 {
  margin-top: -15px;
}

.mt-none-20 {
  margin-top: -20px;
}

.mt-none-25 {
  margin-top: -25px;
}

.mt-none-30 {
  margin-top: -30px;
}

.mt-none-35 {
  margin-top: -35px;
}

.mt-none-40 {
  margin-top: -40px;
}

.mt-none-45 {
  margin-top: -45px;
}

.mt-none-50 {
  margin-top: -50px;
}

.mt-none-55 {
  margin-top: -55px;
}

.mt-none-60 {
  margin-top: -60px;
}

.mt-none-65 {
  margin-top: -65px;
}

.mt-none-70 {
  margin-top: -70px;
}

.mt-none-75 {
  margin-top: -75px;
}

.mt-none-80 {
  margin-top: -80px;
}

.mt-none-85 {
  margin-top: -85px;
}

.mt-none-90 {
  margin-top: -90px;
}

.mt-none-95 {
  margin-top: -95px;
}

.mt-none-100 {
  margin-top: -100px;
}

/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/* typography css start */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.3;
  color: var(--color-black);
  font-weight: 700;
  font-family: var(--font-heading);
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

/* typography css end */
@-webkit-keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@-webkit-keyframes rotatedHalfTwo {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes rotatedHalfTwo {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@-webkit-keyframes scale-upOne {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
}
@keyframes scale-upOne {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
}
@-webkit-keyframes scale-right {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  50% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@keyframes scale-right {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  50% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
@-webkit-keyframes hvr-ripple-out {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
  }
}
@keyframes hvr-ripple-out {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
  }
}
@-webkit-keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -18px;
    right: -18px;
    bottom: -18px;
    left: -18px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -18px;
    right: -18px;
    bottom: -18px;
    left: -18px;
    opacity: 0;
  }
}
@-webkit-keyframes scale-up-one {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-one {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes scale-up-two {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes scale-up-two {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@-webkit-keyframes scale-up-three {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}
@keyframes scale-up-three {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    -moz-transform: translate(0px, 0px) rotate(0deg);
    -ms-transform: translate(0px, 0px) rotate(0deg);
    -o-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    -moz-transform: translate(73px, -1px) rotate(36deg);
    -ms-transform: translate(73px, -1px) rotate(36deg);
    -o-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, -20px) rotate(72deg);
    -webkit-transform: translate(141px, -20px) rotate(72deg);
    -moz-transform: translate(141px, -20px) rotate(72deg);
    -ms-transform: translate(141px, -20px) rotate(72deg);
    -o-transform: translate(141px, -20px) rotate(72deg);
  }
  60% {
    transform: translate(83px, -60px) rotate(108deg);
    -webkit-transform: translate(83px, -60px) rotate(108deg);
    -moz-transform: translate(83px, -60px) rotate(108deg);
    -ms-transform: translate(83px, -60px) rotate(108deg);
    -o-transform: translate(83px, -60px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    -moz-transform: translate(-40px, 72px) rotate(144deg);
    -ms-transform: translate(-40px, 72px) rotate(144deg);
    -o-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    -moz-transform: translate(0px, 0px) rotate(0deg);
    -ms-transform: translate(0px, 0px) rotate(0deg);
    -o-transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}
@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}
@keyframes animationFramesThree {
  0% {
    transform: translate(165px, -30px);
    -webkit-transform: translate(165px, -30px);
    -moz-transform: translate(165px, -30px);
    -ms-transform: translate(165px, -30px);
    -o-transform: translate(165px, -30px);
  }
  100% {
    transform: translate(-60px, 80px);
    -webkit-transform: translate(-60px, 80px);
    -moz-transform: translate(-60px, 80px);
    -ms-transform: translate(-60px, 80px);
    -o-transform: translate(-60px, 80px);
  }
}
@-webkit-keyframes animationFramesThree {
  0% {
    transform: translate(165px, -30px);
    -webkit-transform: translate(165px, -30px);
    -moz-transform: translate(165px, -30px);
    -ms-transform: translate(165px, -30px);
    -o-transform: translate(165px, -30px);
  }
  100% {
    transform: translate(-60px, 80px);
    -webkit-transform: translate(-60px, 80px);
    -moz-transform: translate(-60px, 80px);
    -ms-transform: translate(-60px, 80px);
    -o-transform: translate(-60px, 80px);
  }
}
@keyframes animationFramesFour {
  0% {
    transform: translate(0px, 60px) rotate(0deg);
    -webkit-transform: translate(0px, 60px) rotate(0deg);
    -moz-transform: translate(0px, 60px) rotate(0deg);
    -ms-transform: translate(0px, 60px) rotate(0deg);
    -o-transform: translate(0px, 60px) rotate(0deg);
  }
  100% {
    transform: translate(-100px, -100px) rotate(180deg);
    -webkit-transform: translate(-100px, -100px) rotate(180deg);
    -moz-transform: translate(-100px, -100px) rotate(180deg);
    -ms-transform: translate(-100px, -100px) rotate(180deg);
    -o-transform: translate(-100px, -100px) rotate(180deg);
  }
}
@-webkit-keyframes animationFramesFour {
  0% {
    transform: translate(0px, 60px) rotate(0deg);
    -webkit-transform: translate(0px, 60px) rotate(0deg);
    -moz-transform: translate(0px, 60px) rotate(0deg);
    -ms-transform: translate(0px, 60px) rotate(0deg);
    -o-transform: translate(0px, 60px) rotate(0deg);
  }
  100% {
    transform: translate(-100px, -100px) rotate(180deg);
    -webkit-transform: translate(-100px, -100px) rotate(180deg);
    -moz-transform: translate(-100px, -100px) rotate(180deg);
    -ms-transform: translate(-100px, -100px) rotate(180deg);
    -o-transform: translate(-100px, -100px) rotate(180deg);
  }
}
@keyframes animationFramesFive {
  0% {
    transform: translate(0, 0) rotate(0deg);
    -webkit-transform: translate(0, 0) rotate(0deg);
    -moz-transform: translate(0, 0) rotate(0deg);
    -ms-transform: translate(0, 0) rotate(0deg);
    -o-transform: translate(0, 0) rotate(0deg);
  }
  21% {
    transform: translate(4px, -20px) rotate(38deg);
    -webkit-transform: translate(4px, -20px) rotate(38deg);
    -moz-transform: translate(4px, -20px) rotate(38deg);
    -ms-transform: translate(4px, -20px) rotate(38deg);
    -o-transform: translate(4px, -20px) rotate(38deg);
  }
  41% {
    transform: translate(-50px, -60px) rotate(74deg);
    -webkit-transform: translate(-50px, -60px) rotate(74deg);
    -moz-transform: translate(-50px, -60px) rotate(74deg);
    -ms-transform: translate(-50px, -60px) rotate(74deg);
    -o-transform: translate(-50px, -60px) rotate(74deg);
  }
  60% {
    transform: translate(-20px, -30px) rotate(108deg);
    -webkit-transform: translate(-20px, -30px) rotate(108deg);
    -moz-transform: translate(-20px, -30px) rotate(108deg);
    -ms-transform: translate(-20px, -30px) rotate(108deg);
    -o-transform: translate(-20px, -30px) rotate(108deg);
  }
  80% {
    transform: translate(-195px, -49px) rotate(144deg);
    -webkit-transform: translate(-195px, -49px) rotate(144deg);
    -moz-transform: translate(-195px, -49px) rotate(144deg);
    -ms-transform: translate(-195px, -49px) rotate(144deg);
    -o-transform: translate(-195px, -49px) rotate(144deg);
  }
  100% {
    transform: translate(-1px, 0px) rotate(180deg);
    -webkit-transform: translate(-1px, 0px) rotate(180deg);
    -moz-transform: translate(-1px, 0px) rotate(180deg);
    -ms-transform: translate(-1px, 0px) rotate(180deg);
    -o-transform: translate(-1px, 0px) rotate(180deg);
  }
}
@-webkit-keyframes animationFramesFive {
  0% {
    transform: translate(0, 0) rotate(0deg);
    -webkit-transform: translate(0, 0) rotate(0deg);
    -moz-transform: translate(0, 0) rotate(0deg);
    -ms-transform: translate(0, 0) rotate(0deg);
    -o-transform: translate(0, 0) rotate(0deg);
  }
  21% {
    transform: translate(4px, -20px) rotate(38deg);
    -webkit-transform: translate(4px, -20px) rotate(38deg);
    -moz-transform: translate(4px, -20px) rotate(38deg);
    -ms-transform: translate(4px, -20px) rotate(38deg);
    -o-transform: translate(4px, -20px) rotate(38deg);
  }
  41% {
    transform: translate(-50px, -60px) rotate(74deg);
    -webkit-transform: translate(-50px, -60px) rotate(74deg);
    -moz-transform: translate(-50px, -60px) rotate(74deg);
    -ms-transform: translate(-50px, -60px) rotate(74deg);
    -o-transform: translate(-50px, -60px) rotate(74deg);
  }
  60% {
    transform: translate(-20px, -30px) rotate(108deg);
    -webkit-transform: translate(-20px, -30px) rotate(108deg);
    -moz-transform: translate(-20px, -30px) rotate(108deg);
    -ms-transform: translate(-20px, -30px) rotate(108deg);
    -o-transform: translate(-20px, -30px) rotate(108deg);
  }
  80% {
    transform: translate(-195px, -49px) rotate(144deg);
    -webkit-transform: translate(-195px, -49px) rotate(144deg);
    -moz-transform: translate(-195px, -49px) rotate(144deg);
    -ms-transform: translate(-195px, -49px) rotate(144deg);
    -o-transform: translate(-195px, -49px) rotate(144deg);
  }
  100% {
    transform: translate(-1px, 0px) rotate(180deg);
    -webkit-transform: translate(-1px, 0px) rotate(180deg);
    -moz-transform: translate(-1px, 0px) rotate(180deg);
    -ms-transform: translate(-1px, 0px) rotate(180deg);
    -o-transform: translate(-1px, 0px) rotate(180deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes zoominup {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@-webkit-keyframes updown {
  0% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }
  50% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
  }
  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }
}
@-webkit-keyframes ltr {
  0% {
    width: 0;
  }
  15% {
    width: 95%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 95%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}
@keyframes ltr {
  0% {
    width: 0;
  }
  15% {
    width: 95%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 95%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}
/*circleAnimation*/
@-webkit-keyframes circleAnimation {
  0%,
  100% {
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
@-webkit-keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@-webkit-keyframes lr-animation {
  0% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes lr-animation {
  0% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes tb-animation {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes tb-animation {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes tx_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes tx_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.slide-up-down {
  -webkit-animation: tx_up_down 1s ease infinite alternate;
  animation: tx_up_down 1s ease infinite alternate;
}

@-webkit-keyframes tx_ltr {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes tx_ltr {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
.slide-ltr {
  -webkit-animation: tx_ltr 1s ease infinite alternate;
  animation: tx_ltr 1s ease infinite alternate;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes zoom {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}
@-webkit-keyframes down {
  0% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}
@keyframes down {
  0% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}
@keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-filter: alpha(opacity=50);
  }
  80% {
    transform: scale(1.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
  }
  100% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }
}
@-webkit-keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  80% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }
  100% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
  }
}
@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.67;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.67;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wow.skewIn.animated {
  -webkit-animation-name: txSkewIn;
  animation-name: txSkewIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@-webkit-keyframes txSkewIn {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes txSkewIn {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*-- Overlay Color --*/
[data-overlay="light"]::before {
  background-color: var(--color-white);
}

[data-overlay="dark"]::before {
  background-color: var(--color-black);
}

[data-overlay="dark-2"]::before {
  background-color: #110a1e;
}

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1;
}

[data-opacity="2"]::before {
  opacity: 0.2;
}

[data-opacity="3"]::before {
  opacity: 0.3;
}

[data-opacity="4"]::before {
  opacity: 0.4;
}

[data-opacity="5"]::before {
  opacity: 0.5;
}

[data-opacity="6"]::before {
  opacity: 0.6;
}

[data-opacity="7"]::before {
  opacity: 0.7;
}

[data-opacity="8"]::before {
  opacity: 0.8;
}

[data-opacity="9"]::before {
  opacity: 0.9;
}

/*----------------------------------------*/
/*  02. header
/*----------------------------------------*/
.site-header {
  z-index: 3;
  position: relative;
}

@media (max-width: 767px) {
  .header__top {
    display: none;
  }
}
.header__top-info {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--color-white);
  padding: 7px 0;
}
.header__info {
  font-size: 15px;
}
.header__info i {
  font-size: 25px;
  margin-right: 10px;
}
.header__contact-info li {
  position: relative;
  font-size: 15px;
}
.header__contact-info li:not(:last-child) {
  margin-right: 8px;
  padding-right: 12px;
}
.header__contact-info li:not(:last-child)::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "/";
}
.header__contact-info li a {
  color: var(--color-white);
}
.header__contact-info li a:hover {
  text-decoration: underline;
}
.header__contact-info .live-chat a {
  position: relative;
}
.header__contact-info .live-chat a::before {
  position: absolute;
  left: -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #00ff84;
  content: "";
  -webkit-animation: 1.5s blink ease infinite;
  animation: 1.5s blink ease infinite;
}
.header__social a {
  color: var(--color-white);
  font-size: 15px;
}
.header__social a:not(:last-child) {
  margin-right: 17px;
}
.header__middle {
  background-color: #2c1a4e;
  padding: 18px 0;
}
@media (max-width: 767px) {
  .header__middle {
    display: none;
  }
}
.header__cta .icon {
  font-size: 32px;
  color: var(--color-white);
  margin-right: 13px;
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
}
.header__cta .content span {
  color: var(--color-white);
  font-size: 15px;
}
.header__cta .content h3 {
  color: var(--color-white);
  font-size: 22px;
  line-height: 20px;
}
.header__bar a {
  position: relative;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  line-height: 53px;
  text-align: center;
  z-index: 1;
  color: var(--color-white);
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}
.header__bar a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 3px;
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #fff)
      )
      content-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: add, add;
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
}
.header__bar a i {
  font-size: 30px;
  line-height: 0;
}
.header__main {
  background-color: #0e0027;
}
@media (max-width: 767px) {
  .header__logo {
    max-width: 130px;
  }
}
.header__top-wrap {
  background-color: #0e0f11;
}
.header__top-two {
  padding: 7px 0;
}
@media (max-width: 991px) {
  .header__top-two {
    display: none;
  }
}
.header__top-two p {
  font-size: 15px;
  color: #5e5f61;
}
.header__top-info-two li {
  color: #5e5f61;
}
.header__top-info-two li:not(:last-child) {
  margin-right: 50px;
  font-size: 15px;
}
@media (max-width: 1199px) {
  .header__top-info-two li:not(:last-child) {
    margin-right: 20px;
  }
}
.header__top-info-two li i {
  margin-right: 10px;
}
.header__top-info-two li a {
  color: currentColor;
}
.header__action li {
  position: relative;
  line-height: 1;
}
.header__action li:not(:first-child) {
  margin-left: 16px;
  padding-left: 16px;
}
.header__action li:not(:first-child)::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 19px;
  background-color: #323136;
  content: "";
}
.header__action li a {
  color: var(--color-white);
  font-size: 19px;
}
.header__action li.hamburger_menu {
  display: none;
}
@media (max-width: 991px) {
  .header__action li.hamburger_menu {
    display: block;
  }
}
.header__button {
  margin-left: 54px;
}
@media (max-width: 1199px) {
  .header__button {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .header__button {
    display: none;
  }
}
.header__button .thm-btn__three {
  padding: 11px 25px 14px;
}
.header__mobile {
  padding: 15px 0;
}
.header__logo-mobile {
  max-width: 120px;
}

.hamburger_menu {
  line-height: 1;
}
.hamburger_menu a {
  font-size: 25px;
  color: var(--color-white);
}

#tx-header-area .tx-header-area-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  -webkit-transform: translateY(-100%);
  -khtml-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
  visibility: hidden;
}

#tx-header-area .tx-header-area-sticky.tx-header-fixed {
  -webkit-transform: translateY(0%);
  -khtml-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  -webkit-box-shadow: 0 3px 18px rgba(2, 21, 78, 0.09);
  -ms-box-shadow: 0 3px 18px rgba(2, 21, 78, 0.09);
  -o-box-shadow: 0 3px 18px rgba(2, 21, 78, 0.09);
  box-shadow: 0 3px 18px rgba(2, 21, 78, 0.09);
}

.tx-sticky-stt .main-menu__wrap {
  padding: 0;
}
.tx-sticky-stt .main-menu__wrap .main-menu > ul > li > a {
  padding: 32px 0;
}

.header-style-one .header__logo {
  margin: auto !important;
}
.header-style-one .header__logo a {
  padding: 0 !important;
}
.header-style-one .main-menu ul {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.header-style-one .main-menu ul li a {
  padding: 40px 0;
}
.header-style-one .main-menu ul li:nth-child(3) {
  margin-right: 0;
}

.header-style-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
}
@media (max-width: 991px) {
  .header-style-two {
    position: unset;
  }
}
.header-style-two .tx-header.tx-sticky-stt {
  background-color: #0a183f;
}
@media (max-width: 991px) {
  .header-style-two .tx-header.tx-sticky-stt {
    padding: 10px 0;
  }
}
.header-style-two .tx-header.tx-sticky-stt .header__logo {
  position: unset;
  margin-right: 60px;
}
@media (max-width: 1199px) {
  .header-style-two .tx-header.tx-sticky-stt .header__logo {
    margin-right: 30px;
  }
}
.header-style-two .tx-header.tx-sticky-stt .headeer__right {
  margin-left: auto;
}
.header-style-two .header__logo {
  position: absolute;
  left: 42px;
  top: 32px;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-style-two .header__logo {
    position: unset;
  }
}
@media (max-width: 1199px) {
  .header-style-two .header__logo {
    position: unset;
  }
}
@media (max-width: 1199px) {
  .header-style-two .header__cta {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .header-style-two .header__cta {
    display: none;
  }
}
.header-style-two .header__bar a span {
  background-color: #00071a;
}
.header-style-two .main-menu ul li .submenu {
  background-color: #0a183f;
}
.header-style-two .main-menu ul li .submenu::before {
  background-color: inherit;
}
.header-style-two .main-menu ul li .submenu li a {
  color: var(--color-white);
}
.header-style-two .main-menu ul li .submenu li:hover > a,
.header-style-two .main-menu ul li .submenu li.active > a {
  color: var(--thm-color-2);
}
.header-style-two .main-menu > ul > li > a span::before,
.header-style-two .main-menu > ul > li > a span::after {
  background: var(--thm-color-2);
}

.header-style-three .tx-header {
  background-color: #090909;
}
@media (max-width: 991px) {
  .header-style-three .tx-header {
    padding: 15px 0;
  }
}
.header-style-three .main-menu ul li .submenu {
  background-color: #090909;
}
.header-style-three .main-menu ul li .submenu::before {
  background-color: inherit;
}
.header-style-three .main-menu ul li .submenu li a {
  color: var(--color-white);
}
.header-style-three .main-menu ul li:hover > a,
.header-style-three .main-menu ul li.active > a,
.header-style-three .main-menu ul li .submenu li:hover > a,
.header-style-three .main-menu ul li .submenu li.active > a {
  color: var(--thm-color-3);
}
.header-style-three .main-menu > ul > li > a span::before,
.header-style-three .main-menu > ul > li > a span::after {
  display: none;
}

/* Navigation css */
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.main-menu__wrap {
  padding: 0 100px;
}
.main-menu ul {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.main-menu ul li {
  position: relative;
}
.main-menu ul li:not(:last-child) {
  margin-right: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .main-menu ul li:not(:last-child) {
    margin-right: 45px;
  }
}
@media (max-width: 1199px) {
  .main-menu ul li:not(:last-child) {
    margin-right: 30px;
  }
}
.main-menu ul li .submenu li {
  margin-right: 0;
}
.main-menu ul li a {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
  padding: 35px 0;
  position: relative;
  line-height: 22px;
}
.main-menu ul li a span i {
  font-size: 11px;
  margin-left: 5px;
}
.main-menu ul li.menu-item-has-children:hover > .submenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none !important;
  transform: none !important;
  pointer-events: all;
}
.main-menu ul li .submenu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  min-width: 240px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  background: var(--color-white);
  left: 0;
  padding: 20px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
  text-align: left;
  -webkit-transform: translate3d(0, 18px, 0);
  transform: translate3d(0, 18px, 0);
  pointer-events: none;
  -webkit-box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.main-menu ul li .submenu::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 15px;
  z-index: 2;
  display: block;
  width: 16px;
  height: 16px;
  -webkit-transform: rotate(-45deg) translateY(1rem);
  transform: rotate(-45deg) translateY(1rem);
  border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.main-menu ul li .submenu li a {
  color: var(--color-black);
  padding: 8px 30px;
  display: block;
  margin: 0;
  font-size: 16px;
}
.main-menu ul li .submenu li:hover > a,
.main-menu ul li .submenu li.active > a {
  color: var(--color-primary);
}
.main-menu ul li .submenu ul {
  left: 100%;
  top: 0px;
}
.main-menu ul li .submenu ul::before {
  display: none;
}
.main-menu > ul > li > a span {
  position: relative;
  padding: 0 2px;
}
.main-menu > ul > li > a span::before,
.main-menu > ul > li > a span::after {
  left: auto;
  right: 0;
  top: -12px;
  content: "";
  width: 0%;
  height: 3px;
  position: absolute;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-menu > ul > li > a span::after {
  left: 0;
  top: 29px;
  top: auto;
  top: 29px;
  right: auto;
}
.main-menu > ul > li:hover a span::before,
.main-menu > ul > li:hover a span::after,
.main-menu > ul > li.active a span::before,
.main-menu > ul > li.active a span::after {
  width: 100%;
}
.main-menu > ul > li:hover a span::before,
.main-menu > ul > li.active a span::before {
  left: 0;
  right: auto;
}
.main-menu > ul > li:hover a span::after,
.main-menu > ul > li.active a span::after {
  right: 0;
  left: auto;
}

.main-menu ul li.menu-last ul.submenu {
  right: 0;
  left: auto;
}

.main-menu ul li.menu-last ul.submenu ul {
  right: auto;
  left: -100%;
}

.main-menu ul li ul.submenu .menu-item-has-children > a::after {
  position: absolute;
  top: 9px;
  right: 15px;
  content: "\f105";
  font-size: 13px;
  font-family: "Font Awesome 5 Pro";
}

.thm-btn {
  -webkit-transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75);
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75);
  font-size: 15px;
  font-weight: 700;
  text-transform: None;
  color: var(--color-white);
  border-style: none;
  padding: 15px 25px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  display: inline-block;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  line-height: 1.1;
  background-color: var(--color-primary);
}
.thm-btn .btn-anim-wrap {
  -webkit-transition: opacity 0.6s, -webkit-transform 0.8s;
  transition: opacity 0.6s, -webkit-transform 0.8s;
  transition: opacity 0.6s, transform 0.8s;
  transition: opacity 0.6s, transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: ease, ease;
  transition-timing-function: ease, ease;
  -webkit-transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
  display: inline-block;
  color: var(--color-white);
}
.thm-btn .btn-anim-wrap .button-text {
  -webkit-transition: opacity 0.6s, -webkit-transform 0.8s;
  transition: opacity 0.6s, -webkit-transform 0.8s;
  transition: opacity 0.6s, transform 0.8s;
  transition: opacity 0.6s, transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: ease, ease;
  transition-timing-function: ease, ease;
  -webkit-transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
  display: inline-block;
  color: currentColor;
}
.thm-btn .btn-anim-wrap .button-text:nth-child(2) {
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 80%);
  transform: translate(-50%, 80%);
  opacity: 0;
  color: currentColor;
}
.thm-btn i {
  font-size: 11px;
  display: inline-block;
  margin-left: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--color-white);
}
.thm-btn:hover .button-text:nth-child(1) {
  opacity: 0;
}
.thm-btn:hover .button-text:nth-child(2) {
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
  opacity: 1;
}
.thm-btn:hover .btn-anim-wrap {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}
.thm-btn:hover i {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.thm-btn__white {
  background: var(--color-white) !important;
  color: var(--color-deep);
}
.thm-btn__white .btn-anim-wrap {
  color: var(--color-deep);
}
.thm-btn__white .btn-anim-wrap .button-text {
  color: currentColor;
}
.thm-btn__white .btn-anim-wrap .button-text:nth-child(2) {
  color: currentColor;
}
.thm-btn__white i {
  color: var(--color-deep);
}
.thm-btn__two {
  background: var(--thm-color-2);
}
.thm-btn__two .btn-anim-wrap,
.thm-btn__two i {
  color: #010308;
}
.thm-btn__three {
  font-size: 15px;
  font-weight: 700;
  padding: 15px 25px 17px;
  color: #090909;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#dc860d),
    color-stop(51%, #fdd305),
    to(#dc860d)
  );
  background-image: linear-gradient(
    to right,
    #dc860d 0%,
    #fdd305 51%,
    #dc860d 100%
  );
  -webkit-transition: all 180ms linear 0ms;
  transition: all 180ms linear 0ms;
  background-size: 200%, auto;
}
.thm-btn__three:hover {
  color: #090909;
  background-position: 100%;
}
.thm-btn__three:hover i {
  -webkit-transform: translateY(3px) rotate(0);
  transform: translateY(3px) rotate(0);
}
.thm-btn__three i {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  font-size: 18px;
  margin-left: 8px;
  color: currentColor;
}
.thm-btn__p2 {
  padding: 20px 35px;
}

/*----------------------------------------*/
/*  04. globel
/*----------------------------------------*/
@media (min-width: 1024px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
.bg-gradient,
.header__top,
.header__bar a:before,
.invite-navigation .tp-bullet.rs-touchhover,
.invite-navigation .tp-bullet.selected,
.tx-slider-text span,
.cta__content .cta-number,
.thm-btn__gradient,
.btn-video__gradient,
.invite-text-gr-color,
.btn-video__gradient::before,
.review-box__link i,
.feature__info-item .icon,
.tx-skill-progress-bar .skill-set-percent .progress-bar,
.testimonial__inner::after,
.testimonial__author-info .avatar::before,
.service__nav-icon::before,
.counter__item .icon::before,
.blog__meta .avatar::before,
.blog__tags li a::before,
.blog__inner::after,
.footer__newslater button,
.footer__widget .widget-title::before,
.social-links a i:nth-child(2),
.service-button-prev i:nth-child(2),
.service-button-next i:nth-child(2),
.breadcrumb .bread-crumb .breadcrumb-item:not(:first-child)::before,
.about__banner-content--2,
.about__tab .nav-item .nav-link.active span,
.about__tab .nav-item .nav-link::before,
.service-item-two .item--icon,
.service-item-two .item--readmore,
.team__carousel-2 .team__single::after,
.team__carousel-2 .team-carousel-button .team-button-next::after,
.team__carousel-2 .team-carousel-button .team-button-prev::after,
.widget__search button,
.widget__category li a::before,
.pricing__wrap .pricing__table2::after,
.pricing__wrap .pricing__table2 .pricing__price::before,
.faq__social-links a::before,
.tagcloud a::after,
.pagination_wrap ul li a::before,
.post-tags-share .tags ul li a::before,
.post-tags-share .social-share ul li a::after,
.tx-team .item--social,
.tx-team .item--details,
.team-details .item--social,
.team-details__skill
  .tx-skill-progress-bar
  .skill-set-percent
  .progress
  .progress-bar,
.portfolio__link,
.portfolio__menu button::before,
.contact__widget-info li .icon::before,
.products .product--img::before,
.woocommerce-toolbar-top .nav-item .nav-link::before,
.shop-sidebar .widget_price_filter #slider-range .ui-slider-range,
.shop-single-section .single-product-info .nav-tabs button::before,
.main-menu > ul > li > a span::before,
.main-menu > ul > li > a span::after {
  background-color: var(--color-primary);
  background-image: linear-gradient(
    1deg,
    var(--gradient-color-from) 0%,
    var(--gradient-color-to) 100%
  );
  background-image: linear-gradient(
    1deg,
    var(--gradient-color-from) 0%,
    var(--gradient-color-to) 100%
  );
  background-image: -webkit-linear-gradient(
    1deg,
    var(--gradient-color-from) 0%,
    var(--gradient-color-to) 100%
  );
}

.blog__meta .avatar::after {
  background-image: linear-gradient(
    -35deg,
    rgb(0, 212, 251) 1%,
    rgb(39, 54, 247) 100%
  );
  background-image: -ms-linear-gradient(
    -35deg,
    rgb(0, 212, 251) 1%,
    rgb(39, 54, 247) 100%
  );
}

.header-style-two .header__bar a::before,
.feature__info-item--two .icon,
.service__item .service__item-dot::before,
.blog__content .blog-link i,
.blog__single::before,
.testimonial__single-inner::after,
.testimonial__nav-thumb::before {
  background-image: linear-gradient(
    -76deg,
    rgb(59, 123, 247) 1%,
    rgb(71, 255, 0) 36%,
    rgb(255, 222, 0) 71%,
    rgb(235, 14, 247) 100%
  );
  background-image: -ms-linear-gradient(
    -76deg,
    rgb(59, 123, 247) 1%,
    rgb(71, 255, 0) 36%,
    rgb(255, 222, 0) 71%,
    rgb(235, 14, 247) 100%
  );
}

.service__item-icon::before,
.service__content-two::before,
.service__slider .service-button-prev i:nth-child(2),
.service__slider .service-button-next i:nth-child(2),
.feature__list li .icon i:nth-child(2),
.testimonial__nav-thumb.swiper-slide-active::after,
.feature__item h3::after,
.case-study__cotnent-inner:before,
.case-study__image-item::before,
.footer-style-two .footer__widget .widget-title::before,
.footer-style-two .footer__newslater button,
.footer-style-two .footer__info li a i {
  background-image: linear-gradient(
    -9deg,
    rgb(249, 0, 88) 0%,
    rgb(62, 28, 243) 100%
  );
  background-image: -ms-linear-gradient(
    -9deg,
    rgb(249, 0, 88) 0%,
    rgb(62, 28, 243) 100%
  );
}

.header__top-info-two li i,
.about__skill .tx-skill-progress-bar .skill-set-percent .progress-bar,
.counter__single .icon,
.tx-service-slider .item--icon,
.tx-service-slider .item--holder::before,
.tx-service-slider .item--holder-bottom::before,
.tx-service-slider .item--readmore,
.tx-pagination.swiper-pagination .swiper-pagination-bullet,
.tx-pagination.swiper-pagination .swiper-pagination-bullet::before,
.testimonial__active.style2 .testimonial__single-inner::after,
.testimonial__nav.style2 .testimonial__nav-thumb::before,
.testimonial__nav.style2 .testimonial__nav-thumb.swiper-slide-active::after,
.testimonial__active.style2 .testimonial__author .info span,
.testimonial__active.style2 .testimonial__author .rating,
.project-carousel .item--category::before,
.pricing__table::after,
.pricing__price::before,
.contact-info-box-item .item--icon i,
.contact-form__btn::after,
.contact-form__btn:not(:hover) i,
.blog__active-style2 .blog__single .blog-meta,
.blog__active-style2 .blog__single::before,
.blog__active-style2 .blog__content .blog-link i,
.team__single::after,
.team__carousel .team-button-next::after,
.team__carousel .team-button-prev::after,
.footer-style-three .footer__widget .widget-title::before,
.footer-style-three .social-links a i:nth-child(2),
.footer-style-three .footer__newslater button,
.footer-style-three .footer__info li a i,
.slider_line::before,
.slider-live-chat i {
  background-image: linear-gradient(
    -179deg,
    rgb(253, 211, 5) 1%,
    rgb(220, 134, 13) 100%
  );
  background-image: -ms-linear-gradient(
    -179deg,
    rgb(253, 211, 5) 1%,
    rgb(220, 134, 13) 100%
  );
}

.tx-swiper-arrow {
  font-size: 24px;
  color: #000;
  width: 38px;
  height: 38px;
  line-height: 56px;
  display: block;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 56px;
  border-radius: 56px;
  -webkit-box-shadow: 0px 0px 13.92px 2.08px rgba(0, 2, 32, 0.05);
  box-shadow: 0px 0px 13.92px 2.08px rgba(0, 2, 32, 0.05);
  -ms-box-shadow: 0px 0px 13.92px 2.08px rgba(0, 2, 32, 0.05);
  -o-box-shadow: 0px 0px 13.92px 2.08px rgba(0, 2, 32, 0.05);
  box-shadow: 0px 0px 13.92px 2.08px rgba(0, 2, 32, 0.05);
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.tx-swiper-arrow.tx-swiper-arrow-prev {
  left: -55px;
}
@media (max-width: 1199px) {
  .tx-swiper-arrow.tx-swiper-arrow-prev {
    left: -10px;
  }
}
.tx-swiper-arrow.tx-swiper-arrow-next {
  right: -55px;
}
@media (max-width: 1199px) {
  .tx-swiper-arrow.tx-swiper-arrow-next {
    right: -10px;
  }
}
.tx-swiper-arrow.tx-swiper-arrow-next i {
  right: auto;
  left: -6px;
}
.tx-swiper-arrow:hover i {
  color: var(--color-primary);
  right: 0;
}
.tx-swiper-arrow:hover.tx-swiper-arrow-next i {
  right: auto;
  left: 0;
}
.tx-swiper-arrow i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -6px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close {
  padding: 0;
  right: 0;
  text-align: center;
  top: -36px;
  width: 36px;
  height: 36px;
  border-radius: 0;
  border-radius: 0;
  background: var(--color-white);
  cursor: pointer;
  opacity: 1;
  font-size: 0;
  border: 9px solid transparent;
  position: absolute;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-figure .mfp-close {
  top: 4px;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close::before,
body .mfp-wrap .mfp-container .mfp-content .mfp-close::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #222;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close::before {
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  transform: rotate(45deg);
  transform: rotate(45deg);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close::after {
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::before,
body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::after {
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  transform: rotate(0);
  transform: rotate(0);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::before,
body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::after {
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  transform: rotate(0);
  transform: rotate(0);
}

.mfp-iframe-holder .mfp-content {
  max-width: 1170px;
}

.tx-split-text .split-line {
  overflow: hidden;
}

.review-box__link i,
.feature__info-item .icon,
.invite-text-gr-color,
.social-links a i:nth-child(2),
.service-button-prev i:nth-child(2),
.service-button-next i:nth-child(2),
.feature__list li .icon i:nth-child(2),
.blog__content .blog-link i,
.header__top-info-two li i,
.service-item-inner .item--icon,
.service-item-inner .item--readmore,
.testimonial__active.style2 .testimonial__author .info span,
.testimonial__active.style2 .testimonial__author .rating,
.contact-info-box-item .item--icon i,
.contact-form__btn:not(:hover) i,
.slider-live-chat i,
.breadcrumb .bread-crumb .breadcrumb-item:not(:first-child)::before,
.about__tab .nav-item .nav-link.active span {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tx-item-bg,
.service__item .service__item-dot::before,
.case-study__image-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.social-links a {
  width: 25px;
  height: 25px;
  display: inline-block;
  color: #494949;
  text-decoration: none;
  position: relative;
  z-index: 1;
  font-size: 18px;
}
.social-links a:not(:last-child) {
  margin-right: 10px;
}
.social-links a i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.social-links a i:nth-child(2) {
  opacity: 0;
}
.social-links a:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.social-links a:hover i:nth-child(1) {
  opacity: 0;
}
.social-links a:hover i:nth-child(2) {
  opacity: 1;
}

.tx-border-text {
  position: absolute;
  top: 0;
  right: 100%;
  font-size: 200px;
  line-height: 1;
  font-family: var(--font-heading);
  color: transparent;
  letter-spacing: -1px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(192, 192, 192, 0.15);
  -ms-text-stroke-color: rgba(192, 192, 192, 0.15);
  -o-text-stroke-color: rgba(192, 192, 192, 0.15);
  margin-right: -100px;
  white-space: nowrap;
  font-weight: 700;
}
@media (max-width: 1199px) {
  .tx-border-text {
    font-size: 150px;
  }
}
@media (max-width: 991px) {
  .tx-border-text {
    font-size: 120px;
  }
}
@media (max-width: 767px) {
  .tx-border-text {
    font-size: 70px;
  }
}
.tx-border-text span {
  display: inline-block;
}

.border-text-left.tx-border-text {
  -webkit-transform: translateX(366px);
  transform: translateX(366px);
  margin-top: -41px;
}
@media (max-width: 767px) {
  .border-text-left.tx-border-text {
    -webkit-transform: translateX(176px);
    transform: translateX(176px);
  }
}

.border-text-left.style2.tx-border-text {
  -webkit-transform: translateX(240px);
  transform: translateX(240px);
}

.border-text-left.style3.tx-border-text {
  -webkit-transform: translateX(360px);
  transform: translateX(360px);
  margin-top: -40px;
}

.slider_line {
  position: relative;
}

.slider_line::before {
  position: relative;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 46%;
  left: -2px;
  content: "";
  opacity: 0.4;
  -webkit-animation: dotAnimatedBottomToTop1 30s infinite linear;
  animation: dotAnimatedBottomToTop1 30s infinite linear;
  animation: dotAnimatedBottomToTop1 30s infinite linear;
  background-color: #fdd305;
  background-image: linear-gradient(
    -179deg,
    rgb(253, 211, 5) 1%,
    rgb(220, 134, 13) 100%
  );
  background-image: -ms-linear-gradient(
    -179deg,
    rgb(253, 211, 5) 1%,
    rgb(220, 134, 13) 100%
  );
}

.slider_line.slider_line_2::before {
  bottom: 27%;
  -webkit-animation: dotAnimatedBottomToTop2 30s infinite linear;
  animation: dotAnimatedBottomToTop2 30s infinite linear;
  animation: dotAnimatedBottomToTop2 30s infinite linear;
}

@-webkit-keyframes dotAnimatedBottomToTop1 {
  0% {
    bottom: 46%;
  }
  50% {
    bottom: 0%;
  }
  100% {
    bottom: 46%;
  }
}

@keyframes dotAnimatedBottomToTop1 {
  0% {
    bottom: 46%;
  }
  50% {
    bottom: 0%;
  }
  100% {
    bottom: 46%;
  }
}
@-webkit-keyframes dotAnimatedBottomToTop2 {
  0% {
    bottom: 27%;
  }
  50% {
    bottom: 100%;
  }
  100% {
    bottom: 27%;
  }
}
@keyframes dotAnimatedBottomToTop2 {
  0% {
    bottom: 27%;
  }
  50% {
    bottom: 100%;
  }
  100% {
    bottom: 27%;
  }
}
@media (max-width: 991px) {
  .pb-md-120 {
    padding-bottom: 120px;
  }
}

.sidebar-area {
  padding-left: 30px;
}
@media (max-width: 991px) {
  .sidebar-area {
    padding-left: 0;
  }
}

.widget__title {
  font-size: 20px;
  color: var(--color-black);
  font-weight: 600;
  margin-bottom: 15px;
}
.widget__inner {
  padding: 20px;
  border: 1px solid rgba(44, 26, 78, 0.15);
}
.widget__search {
  position: relative;
}
.widget__search input {
  height: 60px;
  background-color: rgba(44, 26, 78, 0.05);
  padding: 20px;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.widget__search input::-webkit-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.widget__search input::-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.widget__search input:-ms-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.widget__search input:-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.widget__search button {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-white);
}
.widget__category li:not(:last-child) {
  margin-bottom: 10px;
}
.widget__category li a {
  padding: 15px 20px;
  background-color: rgba(44, 26, 78, 0.05);
  position: relative;
  display: block;
  z-index: 1;
  color: var(--color-black);
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.widget__category li a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.widget__category li a:hover,
.widget__category li a.active {
  color: var(--color-white);
}
.widget__category li a:hover::before,
.widget__category li a.active::before {
  opacity: 1;
}
.widget__category li a i {
  font-size: 13px;
}
.widget__add-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 300px;
  text-align: center;
}
.widget__add-banner h3 {
  font-size: 30px;
  font-weight: 700;
  color: var(--color-white);
  margin-bottom: 18px;
}
.widget__add-banner span {
  color: var(--color-white);
}
.widget__post-item:not(:last-child) {
  margin-bottom: 12px;
}
.widget__post-item .post-thumb {
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 20px;
}
.widget__post-item .post-thumb img {
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.widget__post-item:hover .post-thumb img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.widget__post-item:hover .post-title a {
  background-size: 100% 100%;
}
.widget__post-item .post-meta {
  margin-bottom: 5px;
}
.widget__post-item .post-meta a {
  font-size: 12px;
  color: #4a4c51;
  position: relative;
}
.widget__post-item .post-meta a:not(:last-child) {
  margin-right: 9px;
  padding-right: 12px;
}
.widget__post-item .post-meta a:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 1px;
  height: 15px;
  background-color: #e6e6e6;
}
.widget__post-item .post-meta a i {
  margin-right: 10px;
}
.widget__post-item .post-content {
  width: calc(100% - 110px);
}
.widget__post-item .post-content .post-title {
  font-size: 16px;
  color: var(--color-black);
  font-weight: 600;
}
.widget__post-item .post-content .post-title a {
  color: inherit;
}

.faq__social-links a {
  font-size: 17px;
  margin-right: 20px;
  color: #9087a2;
  width: 45px;
  height: 45px;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #9087a2;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
  z-index: 1;
  margin-top: 10px;
}
.faq__social-links a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.faq__social-links a:hover {
  border-color: transparent;
  color: var(--color-white);
}
.faq__social-links a:hover::before {
  opacity: 1;
}

.tagcloud {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -10px;
}
.tagcloud a {
  display: block;
  color: #4a4c51;
  min-height: 36px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
  padding: 0px 17px;
  margin: 7px;
  position: relative;
}
.tagcloud a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -2;
  background-color: rgba(44, 26, 78, 0.05);
}
.tagcloud a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.tagcloud a:hover {
  color: var(--color-white);
  border-color: transparent;
}
.tagcloud a:hover::after {
  opacity: 1;
}

.tx-custom-container {
  padding: 0 15px;
}

.tx-custom-row {
  margin: 0 -15px;
}
.tx-custom-row > * {
  padding-left: 15px;
  padding-right: 15px;
}

.mr-container {
  padding-right: calc((100% - 1170px) / 2) !important;
}
@media (max-width: 1199px) {
  .mr-container {
    padding-right: 15px !important;
  }
}
@media (max-width: 991px) {
  .mr-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*----------------------------------------*/
/*  05. hero
/*----------------------------------------*/
.hero {
  position: relative;
  z-index: 1;
}
.hero__fitness {
  min-height: 980px;
}
@media (max-width: 1199px) {
  .hero__fitness {
    min-height: 900px;
  }
}
@media (max-width: 991px) {
  .hero__fitness {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
@media (max-width: 767px) {
  .hero__fitness {
    padding-top: 150px;
  }
}
.hero__fitness-shape .shape {
  position: absolute;
  z-index: -1;
}
.hero__fitness-shape .shape--1 {
  left: 0;
  bottom: -200px;
}
.hero__fitness-shape .shape--2 {
  bottom: 200px;
  right: 0;
}
.hero__big-title {
  position: absolute;
  top: 53%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 400px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  background: linear-gradient(
    91.22deg,
    rgba(113, 47, 254, 0.6) 6.88%,
    rgba(188, 42, 141, 0.6) 52.57%,
    rgba(251, 172, 0, 0.6) 69.82%,
    rgba(255, 0, 107, 0.6) 87.36%,
    rgba(255, 24, 135, 0.6) 93.2%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  opacity: 0.1;
  z-index: -1;
}
.hero__big-title span {
  display: inline-block;
}
.hero__fitness-icon {
  position: absolute;
  bottom: 100px;
  left: 0;
}
.hero__content .tx-item--title {
  font-size: 52px;
  line-height: 62px;
}
@media (max-width: 767px) {
  .hero__content .tx-item--title {
    font-size: 26px;
    line-height: 42px;
  }
}
.hero__content .t-text {
  font-size: 16px;
  margin-top: 30px;
  padding-left: 25px;
  position: relative;
}
.hero__content .t-text::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 17px;
  height: 17px;
  background-color: #53e472;
  content: "\f00c";
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  color: var(--color-white);
  border-radius: 50%;
  font-size: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.hero__img {
  margin-right: -150px;
  margin-bottom: -65px;
}
@media (max-width: 991px) {
  .hero__img {
    margin: 0;
  }
}
.hero__followers-box {
  position: absolute;
  bottom: 40px;
  left: -20px;
  width: 100%;
  -webkit-box-shadow: 0px 3.79155px 117.54px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3.79155px 117.54px rgba(0, 0, 0, 0.12);
}
@media (max-width: 767px) {
  .hero__followers-box {
    bottom: -60px;
  }
}

.tmah__hero {
  min-height: 850px;
  background-color: rgba(239, 236, 235, 0.5);
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  .tmah__hero {
    min-height: 750px;
    padding-top: 100px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .tmah__hero {
    padding-bottom: 85px;
  }
}
.tmah__hero .promo__followers {
  left: 0;
  bottom: 100px;
  -webkit-animation: lr-animation 3s linear infinite alternate;
  animation: lr-animation 3s linear infinite alternate;
}
.tmah__content {
  -webkit-transform: translateY(85px);
  transform: translateY(85px);
}
.tmah__content .tx-heading h3 {
  font-size: 61px;
  line-height: 71px;
}
@media (max-width: 1199px) {
  .tmah__content .tx-heading h3 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 1199px) {
  .tmah__content .tx-heading h3 {
    font-size: 30px;
    line-height: 37px;
  }
}
.tmah__content .tx-heading .tx-text--slide .highlight img {
  position: absolute;
  left: 6px;
  top: 54%;
}
.tmah__form {
  position: relative;
  max-width: 534px;
}
.tmah__form input {
  width: 100%;
  height: 79px;
  background-color: var(--color-white);
  border: 1px solid #000000;
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  font-size: 22px;
}
.tmah__form button {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #5956f0;
  height: 70px;
  color: var(--color-white);
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
@media (max-width: 767px) {
  .tmah__form button {
    position: unset;
    width: 100%;
    margin-top: 10px;
  }
}
.tmah__form button:hover {
  opacity: 0.9;
}
.tmah__form button img {
  margin-left: 10px;
}
.tmah__video a {
  color: var(--color-black);
}
.tmah__video .icon {
  width: 29px;
  height: 29px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 8px;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 14px;
}
.tmah__video .text {
  text-decoration: underline;
}
.tmah__hero-img {
  margin-right: -100px;
  -webkit-transform: translate(25px, 100px);
  transform: translate(25px, 100px);
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .tmah__hero-img {
    margin-right: 0;
  }
}
@media (max-width: 1199px) {
  .tmah__hero-img {
    margin-right: 0;
  }
}
.tmah__shape-icon .shape {
  position: absolute;
}
.tmah__shape-icon .shape--1 {
  top: 221px;
  left: -10px;
  animation: zoominup 2s infinite linear;
  -webkit-animation: zoominup 2s infinite linear;
}
.tmah__shape-icon .shape--2 {
  top: 40px;
  right: 30px;
  animation: zoominup 3s infinite linear;
  -webkit-animation: zoominup 3s infinite linear;
}
.tmah__shape-icon .shape--3 {
  right: 30px;
  bottom: 130px;
  -webkit-animation: tb-animation 4s linear infinite alternate;
  animation: tb-animation 4s linear infinite alternate;
}
.tmah__shape-icon .shape--4 {
  right: -90px;
  bottom: 260px;
}
.tmah__shape .shape {
  position: absolute;
  z-index: -1;
}
.tmah__shape .shape--1 {
  bottom: -350px;
  left: 0;
}
.tmah__shape .shape--2 {
  top: 258px;
  left: 82px;
  animation: zoominup 2s infinite linear;
  -webkit-animation: zoominup 2s infinite;
}
@media (max-width: 1199px) {
  .tmah__shape .shape--2 {
    display: none;
  }
}
.tmah__shape .shape--3 {
  bottom: 0;
  left: -166px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero__content .tx-item--title {
    font-size: 41px;
    line-height: 45px;
  }
  .hero__fitness-icon {
    display: none;
  }
  .fit-cta__s a:not(:first-child) {
    margin-left: 20px;
  }
  .client-benifit__slide .slick-dots {
    bottom: -58px;
  }
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.heading {
  background: var(--color-gradient);
}

@keyframes ct_dual_ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ct_dual_ring {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes BgAnimated1 {
  0% {
    background-position: bottom 10px left;
  }
  50% {
    background-position: bottom 10px right;
  }
  100% {
    background-position: bottom 10px left;
  }
}
@keyframes BgAnimated1 {
  0% {
    background-position: bottom 10px left;
  }
  50% {
    background-position: bottom 10px right;
  }
  100% {
    background-position: bottom 10px left;
  }
}
@-webkit-keyframes tx_prixClipFix {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      100% 0,
      100% 100%,
      100% 100%,
      100% 100%
    );
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
@keyframes tx_prixClipFix {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      100% 0,
      100% 100%,
      100% 100%,
      100% 100%
    );
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
#ct-loadding {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  background-color: var(--color-white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -webkit-transform: scaleX(1);
  -khtml-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: -webkit-transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87),
    -webkit-transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  z-index: -1;
  z-index: 999998;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  pointer-events: none;
}

#ct-loadding.style2 {
  background-color: #00061a;
}

#ct-loadding.style3 {
  background-color: var(--color-black);
}

#ct-loadding .loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#ct-loadding .loading-image img {
  max-height: 80px;
}

#ct-loadding .ct-dual-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
}

#ct-loadding .ct-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-right-color: transparent;
  border-left-color: transparent;
  -webkit-animation: ct_dual_ring 1.2s linear infinite;
  animation: ct_dual_ring 1.2s linear infinite;
  border-bottom-color: var(--color-primary);
  border-top-color: var(--color-primary);
}

#ct-loadding .ct-dot-square {
  display: block;
  font-size: 13px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: initial;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: stevloader 3s infinite linear;
  -webkit-animation: stevloader 3s infinite linear;
}

#ct-loadding .loading-spin {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  -webkit-animation: ct_dual_ring 1s linear infinite;
  animation: ct_dual_ring 1s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

#ct-loadding.style1 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

#ct-loadding .loading-ring {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

#ct-loadding .loading-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: var(--color-primary) transparent var(--color-primary)
    transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite;
}

#ct-loadding.style2 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--thm-color-2);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

#ct-loadding.style3 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--thm-color-3);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

#ct-loadding.style4 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--thm-color-4);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

#ct-loadding.style5 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--thm-color-5);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

/* backtoup */
.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 40px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 0 2px rgba(255, 170, 0, 0.08);
  box-shadow: inset 0 0 0 2px rgba(255, 170, 0, 0.08);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
}
.progress-wrap::after {
  position: absolute;
  content: "\f176";
  font-family: "Font Awesome 5 Pro";
  text-align: center;
  line-height: 35px;
  font-size: 15px;
  color: var(--color-primary);
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  font-weight: 700;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 13px;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--color-primary);
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.progress-wrap.style2::after {
  color: var(--thm-color-2);
}
.progress-wrap.style2 svg.progress-circle path {
  stroke: var(--thm-color-2);
}
.progress-wrap.style3::after {
  color: var(--thm-color-3);
}
.progress-wrap.style3 svg.progress-circle path {
  stroke: var(--thm-color-3);
}
.progress-wrap.style4::after {
  color: var(--thm-color-4);
}
.progress-wrap.style4 svg.progress-circle path {
  stroke: var(--thm-color-4);
}
.progress-wrap.style5::after {
  color: var(--thm-color-5);
}
.progress-wrap.style5 svg.progress-circle path {
  stroke: var(--thm-color-5);
}

.tx-cursor,
.tx-cursor-section {
  visibility: hidden;
  position: fixed;
  pointer-events: none;
  z-index: 999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  top: 0;
  left: 0;
}

.tx-cursor .tx-cursor-wrapper,
.tx-cursor-section .tx-cursor-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tx-cursor .tx-cursor--follower,
.tx-cursor-section .tx-cursor--follower {
  position: absolute;
  background-color: var(--color-primary);
  opacity: 0.25;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tx-cursor.style2 .tx-cursor--follower {
  background-color: var(--thm-color-2);
}

.tx-cursor.style3 .tx-cursor--follower {
  background-color: var(--thm-color-3);
}

.tx-cursor.style4 .tx-cursor--follower {
  background-color: var(--thm-color-4);
}

.tx-cursor.style5 .tx-cursor--follower {
  background-color: var(--thm-color-5);
}

.tx-cursor .tx-cursor--label,
.tx-cursor-section .tx-cursor--label {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  -webkit-transform: scale(0);
  transform: scale(0);
  will-change: transform, opacity;
  -webkit-transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tx-cursor .tx-cursor--drap,
.tx-cursor-section .tx-cursor--drap {
  line-height: 87px;
  text-align: center;
  width: 87px;
  height: 87px;
  background-color: #000;
  font-size: 0;
  border-radius: 87px;
  -webkit-transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scale(0);
  transform: scale(0);
  will-change: transform, opacity;
  color: #fff;
}

.tx-cursor .tx-cursor--drap:before,
.tx-cursor-section .tx-cursor--drap:before,
.tx-cursor .tx-cursor--drap:after,
.tx-cursor-section .tx-cursor--drap:after {
  content: "\f10b";
  font-family: caseicon;
  font-size: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.tx-cursor .tx-cursor--drap:before,
.tx-cursor-section .tx-cursor--drap:before {
  -webkit-transform: scaleX(-1);
  -khtml-transform: scaleX(-1);
  transform: scaleX(-1);
}

.tx-cursor .tx-cursor--drap:after,
.tx-cursor.is-enabled,
.tx-cursor-section.is-enabled {
  visibility: visible;
}

.tx-cursor.is-hidden .tx-cursor-wrapper,
.tx-cursor-section.is-hidden .tx-cursor-wrapper {
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
}

.tx-cursor.is-mouse-down .tx-cursor-wrapper,
.tx-cursor-section.is-mouse-down .tx-cursor-wrapper {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.tx-cursor.is-active .tx-cursor--follower,
.tx-cursor-section.is-active .tx-cursor--follower {
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  -webkit-transform: scale(4);
  -khtml-transform: scale(4);
  transform: scale(4);
  opacity: 0;
}

.slider-how-work {
  background: #212738;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  height: 132px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider-how-work .image {
  height: 100%;
  width: 150px;
  position: relative;
}

.slider-how-work .image img {
  height: 100%;
  border-top-right-radius: 34px;
  border-bottom-right-radius: 34ppx;
}

.slider-how-work .image i {
  font-size: 27px;
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -8px;
}

.slider-how-work .content {
  margin-left: 30px;
}

.slider-how-work .content h4 {
  color: #fff;
  font-size: 24px;
}

.slider-how-work .content span {
  font-size: 16px;
  color: #acacac;
  text-decoration: underline;
}

.slider-video a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slider-video i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 140px;
}
.slider-video i::before {
  font-weight: 700 !important;
}
@media (max-width: 767px) {
  .slider-video i {
    left: 103px;
  }
}

.slider__arrow-updown {
  width: 110px;
  height: 51px;
  border: 3px solid #bfef2d;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transform: rotate(90deg) translate(0px);
  -webkit-transform: rotate(90deg) translate(0);
  transform: rotate(90deg) translate(0);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
  text-decoration: none;
}
@media (max-width: 1199px) {
  .slider__arrow-updown {
    -webkit-transform: rotate(90deg) translate(95px);
    transform: rotate(90deg) translate(95px);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .slider__arrow-updown {
    -webkit-transform: rotate(90deg) translate(40px);
    transform: rotate(90deg) translate(40px);
  }
}
@media (max-width: 991px) {
  .slider__arrow-updown {
    -webkit-transform: rotate(90deg) translate(23px);
    transform: rotate(90deg) translate(23px);
  }
}

.slider__arrow-updown:hover {
  transform: rotate(90deg) translate(15px) scale(0.95);
  -webkit-transform: rotate(90deg) translate(15px) scale(0.95);
  transform: rotate(90deg) translate(15px) scale(0.95);
}
@media (max-width: 1199px) {
  .slider__arrow-updown:hover {
    -webkit-transform: rotate(90deg) translate(105px) scale(0.95);
    transform: rotate(90deg) translate(105px) scale(0.95);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .slider__arrow-updown:hover {
    -webkit-transform: rotate(90deg) translate(55px) scale(0.95);
    transform: rotate(90deg) translate(55px) scale(0.95);
  }
}
@media (max-width: 991px) {
  .slider__arrow-updown:hover {
    -webkit-transform: rotate(90deg) translate(35px) scale(0.95);
    transform: rotate(90deg) translate(35px) scale(0.95);
  }
}

.slider__arrow-updown span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.slider__arrow-updown i {
  margin-left: 6px;
  font-size: 18px;
  -webkit-animation: jumpInfinite 1.5s infinite;
  animation: jumpInfinite 1.5s infinite;
  margin-top: 3px;
}

@-webkit-keyframes jumpInfinite {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes jumpInfinite {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slider-btn-two {
  padding: 20px 45px;
  font-size: 16px;
}

@media screen and (max-width: 1260px) {
  .tp-bullets {
    display: none;
  }
}
.slider-btn {
  line-height: 1.5;
  font-size: 16px;
}
.slider-btn i:before {
  font: normal normal normal 11px/1 "Flaticon";
}

.tx-slider-text span {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.tx-slider-text span .rs_splitted_words {
  line-height: 100px;
}

@media (max-width: 991px) {
  .tx-slider-text span .rs_splitted_words {
    line-height: 70px;
  }
}
@media (max-width: 767px) {
  .tx-slider-text span .rs_splitted_words {
    line-height: 50px;
  }
}
.invite-navigation .tp-bullet,
.invite-navigation_two .tp-bullet {
  width: 18px;
  height: 18px;
  background-color: #5c585f !important;
  border-radius: 18px;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.invite-navigation .tp-bullet .invite-navigation_two .tp-bullet {
  border-radius: 50%;
  background: #5c585f;
}

.invite-navigation .tp-bullet::before,
.invite-navigation_two .tp-bullet::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  -webkit-box-shadow: 0 0 0 6px #fff;
  -ms-box-shadow: 0 0 0 6px #fff;
  -o-box-shadow: 0 0 0 6px #fff;
  box-shadow: 0 0 0 6px #fff;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.invite-navigation .tp-bullet.selected::before,
.invite-navigation_two .tp-bullet.selected::before {
  -webkit-box-shadow: 0 0 0 6px #5c585f;
  -ms-box-shadow: 0 0 0 6px #5c585f;
  -o-box-shadow: 0 0 0 6px #5c585f;
  box-shadow: 0 0 0 6px #5c585f;
}

.invite-navigation .tp-bullet:not(.selected),
.invite-navigation_two .tp-bullet:not(.selected) {
  -webkit-transform: scale(0.8);
  -khtml-transform: scale(0.8);
  transform: scale(0.8);
}

.invite-navigation .tp-bullet:not(.selected)::before,
.invite-navigation_two .tp-bullet:not(.selected)::before {
  opacity: 0;
}

.invite-navigation_two .tp-bullet.rs-touchhover,
.invite-navigation_two .tp-bullet.selected {
  background: var(--thm-color-2) !important;
}

rs-module [class*=" fa-"]::before {
  font-family: "Font Awesome 5 Pro";
  font: normal normal normal 16px/1 "Font Awesome 5 Pro";
}

.slider-btn-three .thm-btn__three {
  padding: 16px 45px;
}

.tx-text-outline {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(192, 192, 192, 0.2);
  -ms-text-stroke-color: rgba(192, 192, 192, 0.2);
  -o-text-stroke-color: rgba(192, 192, 192, 0.2);
}

.invite_navigation_three .tp-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  border-radius: 50%;
  background: #f8c806;
}

.invite_navigation_three .tp-bullet:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 2px;
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #000)
      )
      content-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000));
  -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
  box-shadow: 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
  background-image: -ms-linear-gradient(-179deg, #fdd305 1%, #dc860d 100%);
}

.invite_navigation_three .tp-bullet.selected {
  background: transparent;
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}

.invite_navigation_three .tp-bullet.selected::before {
  opacity: 1;
}

.slider-live-chat {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.slider-live-chat i {
  font-size: 30px;
  margin-right: 14px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .invite-slider-one .slider-shape-one {
    width: 600px !important;
    height: auto !important;
  }
  .invite-slider-one .slider-shape-two {
    max-width: 700px !important;
    height: auto !important;
  }
}
@media (max-width: 1199px) {
  .invite-slider-one .slider-shape-one {
    width: 500px !important;
    height: auto !important;
  }
  .invite-slider-one .slider-shape-two {
    max-width: 600px !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .invite-slider-two .slider-image {
    max-width: 800px !important;
    height: auto !important;
  }
}
@media (max-width: 1199px) {
  .invite-slider-two .slider-bg-shape {
    max-width: 800px !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  #rev_slider_1_1_wrapper .tx-slider-image {
    max-width: 800px !important;
    height: auto !important;
  }
}
@media (max-width: 1199px) {
  #rev_slider_1_1_wrapper .tx-slider-image {
    max-width: 700px !important;
    height: auto !important;
  }
}

@media (max-width: 991px) {
  .invite-cta {
    padding-top: 80px;
  }
}

.cta {
  overflow: hidden;
}
.cta__shape-bg {
  background: url(../img/shape/cta_shape.png) no-repeat;
  background-size: auto;
  background-position: center center;
  height: 72px;
  margin-top: -72px;
  z-index: 2;
  position: relative;
}
@media (max-width: 991px) {
  .cta__shape-bg {
    display: none;
  }
}
.cta__bg {
  background-color: var(--color-violet);
}
.cta__bg--two {
  background-color: var(--color-deep-2);
  z-index: 1;
}
.cta__bg--three {
  background-color: #191919;
  z-index: 1;
}
.cta__content-wrap {
  position: relative;
}
.cta__content-wrap::before {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: url(../img/shape/cta_line.png) no-repeat;
  content: "";
  opacity: 0.42;
  width: 1px;
  height: 100%;
  z-index: 2;
}
@media (max-width: 991px) {
  .cta__content-wrap::before {
    display: none;
  }
}
.cta__overlay-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}
.cta__content-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-violet);
  z-index: -1;
}
.cta__content {
  padding: 0 40px 60px 70px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .cta__content {
    padding: 0;
  }
}
.cta__content::before {
  position: absolute;
  bottom: -92px;
  width: 95%;
  height: 100%;
  content: "";
  background: url(../img/shape/cta_overlay_shape1.png) no-repeat;
  z-index: -2;
  left: 20px;
  background-position: left bottom;
}
@media (max-width: 991px) {
  .cta__content::before {
    display: none;
  }
}
.cta__content h3 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 18px;
}
.cta__content p {
  color: #d9d9d9;
  font-size: 16px;
}
.cta__content .cta-number {
  font-size: 24px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}
.cta__content--2::before {
  background: url(../img/shape/cta_overlay_shape2.png) no-repeat;
  left: auto;
  right: 20px;
  width: 95%;
  height: 115%;
  bottom: -92px;
  background-position: right bottom;
}
.cta__wrap {
  padding: 40px 0;
  padding-top: 20px;
}
.cta__title {
  color: var(--color-white);
  font-size: 30px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .cta__title {
    font-size: 25px;
  }
}
.cta__btn {
  margin-top: 20px;
}
.cta__btn .thm-btn {
  padding: 18px 64px;
}
@media (max-width: 767px) {
  .cta__btn .thm-btn {
    padding: 15px 40px;
  }
}
.cta__shape .shape {
  position: absolute;
  top: 7%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.cta__shape .shape--1 {
  left: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .cta__shape .shape--1 {
    left: -10%;
  }
}
@media (max-width: 1199px) {
  .cta__shape .shape--1 {
    left: -10%;
  }
}
@media (max-width: 991px) {
  .cta__shape .shape--1 {
    left: -20%;
  }
}
@media (max-width: 767px) {
  .cta__shape .shape--1 {
    max-width: 200px;
  }
}
.cta__shape .shape--2 {
  right: 0;
  bottom: 7%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .cta__shape .shape--2 {
    max-width: 200px;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.brand__wrap {
  padding: 50px 0;
  z-index: 3;
  position: relative;
}
@media (max-width: 767px) {
  .brand__wrap {
    padding: 20px 0;
  }
}
.brand__slide {
  padding: 0 20px;
  z-index: 3;
  position: relative;
}
@media (max-width: 991px) {
  .brand__slide {
    padding: 0 20px;
  }
}
.brand__slide .swiper-wrapper {
  margin: 20px 0;
}
.brand__item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
}
.brand__item a {
  -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0s;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0s;
}
.brand__item a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.brand__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 50px;
}
.brand__single a {
  display: inline-block;
  -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0s;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1) 0s;
  position: relative;
  padding: 5px 0;
}
.brand__single a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.about__img-area {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.about__icon-box {
  position: absolute;
  top: 50px;
  left: 30px;
}
@media (max-width: 767px) {
  .about__icon-box {
    left: 0;
  }
}
.about__icon-box .shape {
  position: absolute;
}
.about__icon-box .shape.shape1 {
  top: 18px;
  left: 25%;
}
@media (max-width: 767px) {
  .about__icon-box .shape.shape1 {
    top: 10px;
    left: 20%;
  }
}
.about__icon-box .shape.shape2 {
  bottom: 29px;
  right: 31%;
}
.about__img-shape .shape {
  position: absolute;
}
.about__img-shape .shape--1 {
  top: 100px;
  right: 40px;
}
.about__img-shape .shape--2 {
  bottom: -62px;
  left: 10px;
}
.about__bg {
  background-color: #0a183f;
  border-top-left-radius: 50px;
}
.about__btn .thm-btn {
  padding: 16px 40px;
}
.about__content {
  margin-top: -55px;
  z-index: 1;
  position: relative;
}
.about__content .section-heading p {
  color: #d4d4d4;
}
@media (max-width: 991px) {
  .about__content .about__info {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .about__content .feature__info-item {
    width: auto;
  }
}
.about__line-shape {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateY(-136px);
  transform: translateY(-136px);
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .about__line-shape {
    max-width: 350px;
  }
}
@media (max-width: 1199px) {
  .about__line-shape {
    display: none;
  }
}
.about__img-wrap {
  margin-left: -12px;
  z-index: 1;
  position: relative;
}
.about__shape .shape {
  position: absolute;
}
.about__shape .shape--1 {
  left: 0;
  bottom: -25px;
}
@media (max-width: 991px) {
  .about__shape .shape--1 {
    max-width: 250px;
  }
}
.about__shape .shape--2 {
  bottom: -169px;
  right: 0;
}
.about__banner {
  position: relative;
  padding: 0 25px 75px 85px;
  z-index: 1;
}
@media (max-width: 767px) {
  .about__banner {
    padding: 0 25px 30px 47px;
  }
}
.about__banner-img img {
  width: 100%;
}
.about__banner-content {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 353px;
  max-height: 344px;
  z-index: -1;
  border-left: 4px solid #3d3d3d;
  background-color: #121212;
}
@media (max-width: 767px) {
  .about__banner-content {
    max-width: 234px;
  }
}
.about__banner-content::before {
  content: "";
  height: 4px;
  width: 230px;
  background-color: #3d3d3d;
  position: absolute;
  bottom: 0;
  left: 0;
}
.about__banner-content--2 {
  border-color: #e6e6e6;
}
.about__banner-content--2::before {
  background-color: #e6e6e6;
}
.about__banner-title {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 37px;
  position: absolute;
  left: 60px;
  bottom: 30px;
  color: #fff;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 700;
  font-family: var(--font-heading);
  -webkit-transform: rotate(-90deg);
  -khtml-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transform-origin: bottom left;
  -webkit-transform-origin: bottom left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 767px) {
  .about__banner-title {
    left: 43px;
    bottom: 17px;
    font-size: 18px;
  }
}
.about__banner-logo {
  width: 37px;
  max-height: 37px;
  margin-right: 18px;
}
.about__content-area {
  z-index: 1;
  margin-top: 30px;
}
.about__content-area .tx-border-text {
  -webkit-transform: translateX(796px);
  transform: translateX(796px);
  margin-top: -80px;
}
@media (max-width: 1199px) {
  .about__content-area .tx-border-text {
    -webkit-transform: translateX(546px);
    transform: translateX(546px);
  }
}
@media (max-width: 767px) {
  .about__content-area .tx-border-text {
    -webkit-transform: translateX(188px);
    transform: translateX(188px);
    margin-top: -35px;
  }
}
.about__circle-shape {
  position: absolute;
  bottom: 17%;
  right: 0;
}
@media (max-width: 767px) {
  .about__circle-shape {
    display: none;
  }
}
.about__skill .tx-skill-progress-bar {
  opacity: 1;
  position: unset;
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0;
}
.about__skill .tx-skill-progress-bar .skill-set-percent .progress-bar {
  overflow: visible;
  height: 4px;
  top: -4px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.about__skill .tx-skill-progress-bar .skill-set-percent .progress-bar::before {
  content: "";
  position: absolute;
  top: -7px;
  right: 0;
  width: 5px;
  height: 18px;
  background-color: #ffdd00;
}
.about__skill .tx-skill-progress-bar .skill-set-percent .progress {
  border-top: 4px solid #252525;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.about__skill .tx-skill-progress-bar .skill-set-percent .progress span {
  top: -40px;
}
.about__skill .tx-skill-progress-bar .skill-set-percent h4 {
  font-size: 16px;
  font-family: var(--font-body);
  padding-bottom: 12px;
}
.about__action a:not(:last-child) {
  margin-right: 40px;
}
.about__shape-bg {
  position: absolute;
  top: -60px;
  left: 0;
}
@media (max-width: 1199px) {
  .about__shape-bg {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .about__shape-bg {
    max-width: 200px;
  }
}
.about__shape-lt {
  position: absolute;
  top: -85px;
  left: 0;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .about__shape-lt {
    top: -106px;
    left: -40px;
  }
}
@media (max-width: 1199px) {
  .about__shape-lt {
    display: none;
  }
}
@media (max-width: 1199px) {
  .about__info {
    margin-right: -100px;
  }
}
.about__tab {
  border-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 27px;
}
.about__tab .nav-item:not(:last-child) {
  margin-right: 10px;
}
.about__tab .nav-item .nav-link {
  font-size: 16px;
  color: var(--color-black);
  border: none;
  padding: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 8px 20px;
  position: relative;
  z-index: 2;
}
.about__tab .nav-item .nav-link::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.about__tab .nav-item .nav-link.active::before {
  opacity: 0.2;
}
.about__tab-content {
  border-left: 1px solid var(--color-primary);
  padding-left: 20px;
  font-size: 16px;
  line-height: 30px;
  color: #1c1d20;
}

.review-box__content {
  color: #1c1d20;
  font-size: 18px;
  line-height: 30px;
  margin-right: 30px;
}
.review-box__content a {
  color: currentColor;
  font-weight: 500;
  text-decoration: underline;
}
.review-box__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 150px;
}
.review-box__img img {
  position: relative;
}
.review-box__img img:nth-child(1) {
  z-index: 3;
}
.review-box__img img:nth-child(2) {
  z-index: 2;
  -webkit-transform: translateX(-45px);
  transform: translateX(-45px);
}
.review-box__img img:nth-child(3) {
  -webkit-transform: translateX(-90px);
  transform: translateX(-90px);
  z-index: 1;
}
.review-box__link {
  -webkit-transform: translateX(-2px);
  transform: translateX(-2px);
  font-size: 15px;
  font-weight: 700;
  color: #0e0d0f;
  position: relative;
  text-decoration: underline;
}
.review-box__link:hover {
  color: #0e0d0f;
}
.review-box__link:hover i {
  -webkit-transform: translateX(2px);
  transform: translateX(2px);
}
.review-box__link i {
  margin-left: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.gr-icon-box {
  width: 145px;
  height: 145px;
  background-image: linear-gradient(
    -35deg,
    rgb(0, 212, 251) 1%,
    rgb(39, 54, 247) 100%
  );
  -webkit-box-shadow: 0px 0px 30.45px 4.55px rgba(0, 2, 32, 0.1);
  box-shadow: 0px 0px 30.45px 4.55px rgba(0, 2, 32, 0.1);
  font-size: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-white);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
@media (max-width: 767px) {
  .gr-icon-box {
    width: 100px;
    height: 100px;
    font-size: 30px;
  }
}

.video-box__text {
  margin-left: 12px;
  font-size: 15px;
  font-weight: 700;
}

.about-area {
  padding-left: 90px;
}
@media (max-width: 1199px) {
  .about-area {
    padding-left: 0;
  }
}

.info-box {
  position: relative;
  width: 243px;
  height: 403px;
  background-color: #00071a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  -ms-border-radius: 150px;
  -o-border-radius: 150px;
  z-index: 1;
  padding: 10px;
  text-align: center;
  z-index: 2;
  -webkit-transform: translate(-55px, -130px);
  transform: translate(-55px, -130px);
}
@media (max-width: 991px) {
  .info-box {
    -webkit-transform: translate(0, 70px);
    transform: translate(0, 70px);
  }
}
.info-box__link {
  position: absolute;
  top: 79px;
  right: -14px;
  width: 64px;
  height: 64px;
  color: var(--color-black);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--thm-color-2);
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
}
.info-box__link i {
  color: var(--color-black);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.info-box__content {
  z-index: 33;
  position: relative;
}
.info-box__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 88%;
  height: 94%;
  background-image: -ms-linear-gradient(
    -76deg,
    #3b7bf7 1%,
    #47ff00 36%,
    #ffde00 71%,
    #eb0ef7 100%
  );
  border-radius: 150px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.info-box__bg span {
  position: absolute;
  background: #00071a;
  color: var(--color-white);
  width: calc(100% - 8px);
  height: calc(100% - 9px);
  border-radius: 150px;
  padding: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.info-box__content h4 {
  color: var(--color-white);
  font-size: 60px;
  margin-bottom: 20px;
}
.info-box__content span {
  color: var(--color-white);
  line-height: 24px;
  font-size: 24px;
  color: #afb6c8;
  text-decoration: underline;
}
.info-box__icon {
  position: absolute;
  top: -92px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 22;
  width: 100%;
}
@media (max-width: 1199px) {
  .info-box__icon {
    top: -59px;
    max-width: 157px;
  }
}
.info-box__icon img {
  animation: spin 10s infinite linear;
  -webkit-animation: spin 10s infinite linear;
}

.feature__img1::before {
  position: absolute;
  top: 50%;
  right: -33px;
  -webkit-transform: translateY(-50%) scaleY(0);
  transform: translateY(-50%) scaleY(0);
  content: "";
  background-image: linear-gradient(
    -61deg,
    rgb(252, 118, 50) 0%,
    rgb(247, 39, 80) 100%
  );
  position: absolute;
  width: 82px;
  height: 182px;
  z-index: 1;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}
@media (max-width: 767px) {
  .feature__img1::before {
    width: 74px;
    height: 100px;
  }
}
.feature__img2 {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  z-index: -1;
  position: relative;
}
@media (max-width: 991px) {
  .feature__img2 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.feature__img-text {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--color-white);
  font-size: 48px;
  z-index: 2;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 767px) {
  .feature__img-text {
    font-size: 25px;
  }
}
.feature__content {
  padding-left: 40px;
  padding-top: 40px;
}
@media (max-width: 767px) {
  .feature__content {
    padding-left: 0;
  }
}
.feature__info {
  padding-left: 40px;
}
@media (max-width: 991px) {
  .feature__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .feature__info {
    padding-left: 0;
  }
}
.feature__info-item {
  padding-left: 15px;
}
@media (max-width: 991px) {
  .feature__info-item {
    width: 50%;
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  .feature__info-item {
    width: 100%;
  }
}
.feature__info-item:not(:last-child) {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}
@media (max-width: 991px) {
  .feature__info-item:not(:last-child) {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
}
.feature__info-item .top-bar {
  margin-bottom: 12px;
}
.feature__info-item .icon {
  font-size: 50px;
  margin-right: 20px;
}
.feature__info-item h3 {
  font-size: 22px;
  line-height: 26px;
  font-family: var(--font-body);
}
.feature__info-item p {
  font-size: 15px;
  color: var(--color-black);
  line-height: 24px;
}
.feature__info-item--two {
  margin-top: 15px;
}
.feature__info-item--two:not(:last-child) {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #404b69;
}
.feature__info-item--two h3 {
  color: var(--color-white);
  font-size: 20px;
  line-height: 26px;
}
.feature__img-area {
  padding-top: 87px;
  margin-bottom: -40px;
}
@media (max-width: 991px) {
  .feature__img-area {
    margin-bottom: 0;
  }
}
.feature__icon-box {
  position: absolute;
  top: 65px;
  right: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .feature__icon-box {
    right: 17%;
  }
}
@media (max-width: 767px) {
  .feature__icon-box {
    right: 0px;
    max-width: 150px;
  }
}
.feature__icon-content {
  position: absolute;
  top: 43%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.feature__icon-content i {
  font-size: 50px;
  color: var(--color-white);
}
@media (max-width: 767px) {
  .feature__icon-content i {
    font-size: 30px;
  }
}
.feature__icon-content h3 {
  font-size: 16px;
  color: var(--color-white);
}
@media (max-width: 767px) {
  .feature__icon-content h3 {
    font-size: 12px;
  }
}
.feature__shape-bg {
  position: absolute;
  top: -38px;
  left: -226px;
  z-index: -1;
  width: 86;
  right: -200px;
}
.feature__list li {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 22px;
  font-weight: 500;
  color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 53px;
  position: relative;
}
.feature__list li:not(:last-child) {
  margin-bottom: 20px;
}
.feature__list li .icon i {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 30px;
  color: #2e224e;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feature__list li .icon i:nth-child(2) {
  opacity: 0;
}
.feature__list li:hover .icon i:nth-child(1) {
  opacity: 0;
}
.feature__list li:hover .icon i:nth-child(2) {
  opacity: 1;
}
.feature__list li:hover span:before {
  opacity: 1;
}
.feature__list li span {
  position: relative;
}
.feature__list li span::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  content: "";
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feature__wrap {
  background-color: #00000a;
  margin-left: -39px;
  padding-right: 40px;
  position: relative;
}
@media (max-width: 767px) {
  .feature__wrap {
    padding-right: 0;
  }
}
.feature__wrap::before {
  position: absolute;
  top: 0;
  right: -50%;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00000a;
  z-index: -1;
}
.feature__item {
  padding-left: 40px;
  max-width: 360px;
}
.feature__item h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-white);
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 22px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feature__item h3::before {
  position: absolute;
  bottom: 0;
  width: 123%;
  height: 1px;
  background-color: #1a1a22;
  content: "";
}
@media (max-width: 767px) {
  .feature__item h3::before {
    width: 100%;
  }
}
.feature__item h3::after {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  content: "";
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feature__item:hover h3 {
  color: var(--thm-color-2);
}
.feature__item:hover h3::after {
  opacity: 1;
}
@media (max-width: 991px) {
  .feature__shape {
    display: none;
  }
}
.feature__shape .shape {
  position: absolute;
  z-index: -2;
}
.feature__shape .shape--1 {
  left: 8%;
  bottom: -85px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .feature__shape .shape--1 {
    left: 11%;
    bottom: -99px;
    max-width: 187px;
  }
}
.feature__shape .shape--2 {
  right: 0;
  bottom: -70%;
}
@media (max-width: 1199px) {
  .feature__shape .shape--2 {
    max-width: 300px;
  }
}
.feature__video-img {
  position: absolute;
  top: 0;
  max-width: 580px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .feature__video-img {
    position: relative;
  }
}
.feature__content2 {
  padding-left: 40px;
}
@media (max-width: 1199px) {
  .feature__content2 {
    padding-left: 10px;
  }
}
.feature__content3 {
  padding-right: 40px;
}
@media (max-width: 1199px) {
  .feature__content3 {
    padding-right: 10px;
  }
}
.feature__img-top {
  position: relative;
  margin-top: -60px;
}
.feature__content-right {
  padding-left: 30px;
}
@media (max-width: 1199px) {
  .feature__content-right {
    padding-left: 0;
  }
}
.feature__right-shape {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .feature__right-shape {
    max-width: 240px;
  }
}
@media (max-width: 1199px) {
  .feature__right-shape {
    max-width: 200px;
  }
}
@media (max-width: 767px) {
  .feature__right-shape {
    display: none;
  }
}

.feature__wrap [class^="col-"]:last-child .feature__item h3::before {
  width: 100%;
}

.feature-img-animation .image,
.feature-img-animation.feature__img2 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  -webkit-transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
  transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
  -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}
.feature-img-animation .image img,
.feature-img-animation.feature__img2 img {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
  transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
  max-width: 100%;
  height: auto;
  -webkit-transform: scale(1.5) translate(100px, 0px);
  transform: scale(1.5) translate(100px, 0px);
}
.feature-img-animation.active .image,
.feature-img-animation.active.feature__img2 {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.feature-img-animation.active .image img,
.feature-img-animation.active.feature__img2 img {
  -webkit-transform: scale(1) translate(0px, 0px);
  transform: scale(1) translate(0px, 0px);
}
.feature-img-animation.active.feature__img::before {
  -webkit-transform: translateY(-50%) scaleY(1);
  transform: translateY(-50%) scaleY(1);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.feature-img-animation.active .feature__img-text {
  opacity: 1;
  visibility: visible;
  right: -17px;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.contact__img {
  -webkit-transform: translateX(18%);
  transform: translateX(18%);
  margin-top: -24%;
}
@media (max-width: 991px) {
  .contact__img {
    margin-top: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.contact__img-icon {
  position: absolute;
  top: 24%;
  left: 100px;
  width: 106px;
  height: 106px;
  font-size: 48px;
}
@media (max-width: 767px) {
  .contact__img-icon {
    top: 0;
    left: 0;
  }
}
.contact__shape .shape {
  position: absolute;
  top: -11px;
  right: 26px;
}
.contact__line-shape {
  position: absolute;
  top: 51%;
  right: -24px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
@media (max-width: 1199px) {
  .contact__line-shape {
    display: none;
  }
}
.contact__shape-left {
  position: absolute;
  top: -26%;
  left: -12px;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .contact__shape-left {
    top: -42%;
    left: -140px;
  }
}
@media (max-width: 1199px) {
  .contact__shape-left {
    top: -65%;
    left: -100px;
  }
}
.contact__form input,
.contact__form select,
.contact__form textarea {
  height: 50px;
  border-radius: 0;
  padding: 0;
  border: 0;
  border-bottom: 2px solid #e9e9e9;
  margin-bottom: 20px;
  color: var(--color-black);
  font-weight: 500;
  background-color: transparent;
}
.contact__form input::-webkit-input-placeholder,
.contact__form select::-webkit-input-placeholder,
.contact__form textarea::-webkit-input-placeholder {
  color: var(--color-black);
  opacity: 1;
}
.contact__form input::-moz-placeholder,
.contact__form select::-moz-placeholder,
.contact__form textarea::-moz-placeholder {
  color: var(--color-black);
  opacity: 1;
}
.contact__form input:-ms-input-placeholder,
.contact__form select:-ms-input-placeholder,
.contact__form textarea:-ms-input-placeholder {
  color: var(--color-black);
  opacity: 1;
}
.contact__form input:-moz-placeholder,
.contact__form select:-moz-placeholder,
.contact__form textarea:-moz-placeholder {
  color: var(--color-black);
  opacity: 1;
}
.contact__form input:focus,
.contact__form select:focus,
.contact__form textarea:focus {
  border-color: var(--color-primary);
}
.contact__form textarea {
  min-height: 100px;
}
.contact__widget-inner {
  padding: 30px;
  border: 1px solid #e0dde5;
}
.contact__widget-inner h3 {
  font-size: 28px;
  margin-bottom: 12px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .contact__widget-inner h3 {
    font-size: 22px;
  }
}
.contact__form-inner {
  padding: 30px;
  border: 1px solid #e0dde5;
}
@media (max-width: 767px) {
  .contact__form-inner {
    padding: 20px 15px;
  }
}
.contact__form-inner h3 {
  font-size: 28px;
  margin-bottom: 12px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .contact__form-inner h3 {
    font-size: 22px;
  }
}
.contact__form-form {
  margin-top: 30px;
}
.contact__form-form input,
.contact__form-form select,
.contact__form-form textarea {
  border: 1px solid transparent;
}
.contact__form-form input:focus,
.contact__form-form select:focus,
.contact__form-form textarea:focus {
  border-color: var(--color-primary);
}
.contact__form-form select {
  color: #4a4c51 !important;
}
.contact__form-form label {
  color: var(--color-black);
  margin-bottom: 10px;
}
.contact__widget-info {
  margin-top: 30px;
}
.contact__widget-info li {
  position: relative;
  padding-left: 80px;
}
.contact__widget-info li:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0dde5;
}
.contact__widget-info li .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  font-size: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.contact__widget-info li .icon::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0.1;
}
.contact__widget-info li .content h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact__widget-info li .content p {
  font-size: 14px;
  line-height: 26px;
}

.contact-info-box {
  z-index: 2;
  position: relative;
}

.contact-info-box-item:not(:last-child) {
  margin-bottom: 35px;
}
.contact-info-box-item .item--inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.contact-info-box-item .item--inner:hover .item--icon::before {
  -webkit-transform: scale(1.8);
  -khtml-transform: scale(1.8);
  transform: scale(1.8);
  opacity: 0;
}
.contact-info-box-item .item--icon {
  width: 54px;
  height: 54px;
  min-width: 54px;
  position: relative;
  background-color: #191919;
  margin-right: 18px;
  line-height: 54px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}
.contact-info-box-item .item--icon i {
  font-size: 25px;
}
.contact-info-box-item .item--icon::before {
  content: "";
  opacity: 0.5;
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  z-index: -1;
}
.contact-info-box-item .item--title {
  font-size: 30px;
  font-weight: 700;
  color: var(--color-white);
  margin-bottom: 6px;
  margin-top: -6px;
}
@media (max-width: 767px) {
  .contact-info-box-item .item--title {
    font-size: 22px;
  }
}
.contact-info-box-item .item--description {
  font-size: 16px;
  color: #545454;
  line-height: 24px;
}

.tx-contact-image {
  margin: -30px -83px 0px -192px;
  z-index: 1;
  position: relative;
}
@media (max-width: 991px) {
  .tx-contact-image {
    display: none;
  }
}

.contact-form__inner {
  padding: 53px 70px 70px 75px;
  background-color: #191919;
  -webkit-box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: -15px;
}
@media (max-width: 991px) {
  .contact-form__inner {
    padding: 43px 30px 48px;
    margin-top: 0;
  }
}
.contact-form__inner::before {
  position: absolute;
  bottom: -23px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 70px);
  height: 100%;
  background-color: #0f0f0f;
  content: "";
  z-index: -1;
  -webkit-box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
}
.contact-form__content {
  margin-bottom: 20px;
  text-align: center;
}
.contact-form__content h3 {
  color: var(--color-white);
  font-size: 36px;
  margin-bottom: 5px;
}
.contact-form__content p {
  color: #676666;
  font-size: 16px;
  line-height: 24px;
}
.contact-form__field {
  position: relative;
  margin-bottom: 10px;
}
.contact-form__field label {
  position: absolute;
  top: 5px;
  left: 15px;
  font-size: 14px;
  color: #ffffff;
}
.contact-form__field input {
  width: 100%;
  height: 68px;
  padding-bottom: 0;
  font-size: 16px;
  color: var(--color-white);
  background-color: #292929;
  border: transparent;
  padding: 6px 22px;
  padding-left: 15px;
  padding-top: 26px;
  margin: 0;
}
.contact-form__field input::-webkit-input-placeholder {
  color: #666666;
  opacity: 1;
}
.contact-form__field input::-moz-placeholder {
  color: #666666;
  opacity: 1;
}
.contact-form__field input:-ms-input-placeholder {
  color: #666666;
  opacity: 1;
}
.contact-form__field input:-moz-placeholder {
  color: #666666;
  opacity: 1;
}
.contact-form__field input:focus {
  border: 0;
}
.contact-form__field i {
  position: absolute;
  top: 50%;
  right: 22px;
  font-size: 15px;
  color: #c5c5c5;
  -webkit-transform: translate(0, -50%);
  -khtml-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.contact-form__btn {
  margin-top: 15px;
  padding: 12px 35px;
  font-size: 15px;
  font-family: inherit;
  font-weight: 700;
  letter-spacing: 0;
  background-color: var(--color-black);
  color: var(--color-white);
  position: relative;
}
.contact-form__btn::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  background: inherit;
  z-index: -1;
  opacity: 0.4;
  -webkit-transform: scale3d(0.6, 0.5, 1);
  transform: scale3d(0.6, 0.5, 1);
  border-radius: inherit;
}
.contact-form__btn:focus {
  -webkit-animation: btn-animate1 0.3s forwards;
  animation: btn-animate1 0.3s forwards;
  overflow: visible !important;
}
.contact-form__btn:hover {
  -webkit-animation: btn-animate1 0.3s forwards;
  animation: btn-animate1 0.3s forwards;
  overflow: visible !important;
}
.contact-form__btn:hover,
.contact-form__btn:focus {
  background: var(--thm-color-3);
  color: var(--color-black);
}
.contact-form__btn:hover::before,
.contact-form__btn:focus::before {
  -webkit-animation: btn-animate2 0.3s 0.3s forwards;
  animation: btn-animate2 0.3s 0.3s forwards;
}
.contact-form__btn i {
  font-size: 16px;
  margin-left: 14px;
  display: inline-block;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}
.contact-form__btn::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.contact-form__btn:focus i {
  color: var(--color-black);
  background-clip: text;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: unset;
}
.contact-form__shape {
  position: absolute;
  top: 23px;
  right: -110px;
  z-index: -1;
}

.google-map__inner {
  height: 497px;
}
@media (max-width: 1199px) {
  .google-map__inner {
    height: 350px;
  }
}
@media (max-width: 991px) {
  .google-map__inner {
    height: 300px;
  }
}
@media (max-width: 767px) {
  .google-map__inner {
    height: 200px;
  }
}
.google-map__inner iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

@-webkit-keyframes btn-animate1 {
  60% {
    -webkit-transform: scale3d(0.9, 0.9, 1);
    transform: scale3d(0.9, 0.9, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes btn-animate1 {
  60% {
    -webkit-transform: scale3d(0.9, 0.9, 1);
    transform: scale3d(0.9, 0.9, 1);
  }
  85% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes btn-animate2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes btn-animate2 {
  to {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.service {
  overflow: hidden;
}
.service__nav {
  max-width: 585px;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.service__content {
  margin-top: 40px;
  padding-top: 85px;
  position: relative;
}
.service__content::before {
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(4.78%, rgba(55, 43, 75, 0.4)),
    color-stop(49.21%, rgba(255, 255, 255, 0.1411764706)),
    color-stop(93.92%, rgba(55, 43, 75, 0.4))
  );
  background: linear-gradient(
    90deg,
    rgba(55, 43, 75, 0.4) 4.78%,
    rgba(255, 255, 255, 0.1411764706) 49.21%,
    rgba(55, 43, 75, 0.4) 93.92%
  );
  width: 85%;
  height: 1px;
  z-index: 2;
  right: 0;
  width: 85%;
  margin: auto;
}
.service__nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
}
.service__nav-icon {
  font-size: 45px;
  color: var(--color-white);
  width: 82px;
  height: 82px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #3e3352;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-bottom: 22px;
}
.service__nav-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
.service__nav-item:hover .service__nav-icon::before,
.swiper-slide.swiper-slide-active .service__nav-icon::before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.service__nav-title {
  color: var(--color-white);
  font-size: 18px;
  position: relative;
  text-align: center;
}
.service__nav-title::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #c3c0ca;
  content: "";
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.service__nav-item:hover .service__nav-title::before,
.swiper-slide.swiper-slide-active .service__nav-title::before {
  opacity: 1;
}
.service__img-area {
  margin-top: -30px;
}
.service__slide-content .section-heading p {
  font-size: 15px;
  line-height: 24px;
  opacity: 0.53;
}
.service__list li {
  font-size: 22px;
  color: #fefefe;
  font-weight: 500;
  font-family: var(--font-heading);
}
.service__list li:not(:last-child) {
  margin-bottom: 22px;
}
.service__list li i {
  color: var(--color-violet-2);
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  display: inline-block;
  margin-right: 22px;
}
.service__icon-box {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -10px;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
@media (max-width: 767px) {
  .service__icon-box {
    top: 13%;
  }
}
.service__shape {
  position: absolute;
  top: 10px;
  right: 66px;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
@media (max-width: 767px) {
  .service__shape {
    right: 0;
  }
}
.service__slide-img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.service__shape-bg {
  position: absolute;
  bottom: -32%;
  right: -20%;
}
.service__bg {
  background-color: var(--color-deep-3);
  z-index: 2;
}
.service__bg-img {
  position: absolute;
  top: -5px;
  right: 0;
  width: 50%;
  height: 100%;
  background-position: right;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .service__bg-img {
    background-position: -400%;
  }
}
@media (max-width: 1199px) {
  .service__bg-img {
    background-position: -40%;
  }
}
@media (max-width: 991px) {
  .service__bg-img {
    background-position: 60px;
  }
}
.service__slider {
  padding-left: 35px;
  margin-right: 160px;
  padding-bottom: 66px;
  padding-right: 30px;
}
@media (max-width: 991px) {
  .service__slider {
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .service__slider {
    margin-right: 0;
  }
}
.service__slider::before {
  position: absolute;
  top: 216px;
  content: "";
  width: 84%;
  height: 1px;
  background-color: var(--color-white);
  left: 51%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.11;
}
@media (max-width: 767px) {
  .service__slider::before {
    width: 80%;
    left: 53%;
  }
}
.service__slider .service-button-prev,
.service__slider .service-button-next {
  -webkit-transform: translate(0);
  transform: translate(0);
  top: 198px;
}
.service__slider .service-button-prev {
  left: 18px;
}
.service__slider .service-button-next {
  right: 0;
}
.service .service__slider .service-button-prev::before,
.service .service__slider .service-button-next::before {
  background-color: var(--color-deep-3);
}
.service__item:hover .service__item-icon::before,
.service__item.swiper-slide-active .service__item-icon::before {
  opacity: 1;
}
.service__item:hover .service__item-icon,
.service__item.swiper-slide-active .service__item-icon {
  opacity: 1;
  background: rgb(5, 17, 50);
}
.service__item-icon {
  color: var(--color-white);
  font-size: 60px;
  width: 168px;
  height: 190px;
  background: rgba(5, 17, 50, 0.32);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 99% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 99% 75%, 50% 100%, 0 75%);
  position: relative;
  margin: 0 auto 65px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.service__item-icon::before,
.service__item-icon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 126px;
  height: 143px;
  background-color: #fff;
  content: "";
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 99% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 99% 75%, 50% 100%, 0 75%);
  z-index: -1;
  opacity: 0.12;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.service__content-two {
  padding: 0 20px;
  position: relative;
}
.service__content-two::before,
.service__content-two::after {
  width: 266px;
  height: 200px;
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -66px;
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.service__content-two::before {
  bottom: -57px;
  overflow: 0;
  opacity: 0;
}
.service__content-two::after {
  background-color: var(--color-deep-3);
  bottom: -59px;
  width: 264px;
}
.service__content-two h3 {
  font-size: 18px;
  color: var(--color-white);
  margin-bottom: 18px;
}
.service__content-two h3 a {
  color: currentColor;
}
.service__content-two p {
  font-size: 15px;
  line-height: 24px;
  color: var(--color-white);
  opacity: 0.53;
  max-width: 255px;
  margin: auto;
}
.service__content-two .service-btn {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-white);
  padding: 2px 14px;
  background-color: #3c3958;
  display: inline-block;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  margin-top: 21px;
}
.service__content-two .service-btn i {
  display: inline-block;
  margin-left: 10px;
  font-size: 9px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.service__item:hover .service__content-two::before,
.service__item.swiper-slide-active .service__content-two::before {
  bottom: -66px;
  opacity: 1;
}
.service__item:hover .service-btn,
.service__item.swiper-slide-active .service-btn {
  background-color: var(--thm-color-2);
  color: var(--color-deep-3);
}
.service__item:hover .service-btn i,
.service__item.swiper-slide-active .service-btn i {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.service__item-dot {
  position: absolute;
  top: 208px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 17px;
  height: 17px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #142042;
  overflow: hidden;
  display: inline-block;
}
.service__item-dot::before {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.service__item:hover .service__item-dot::before,
.service__item.swiper-slide-active .service__item-dot::before {
  opacity: 1;
}
.service__circle-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.service__line-shape {
  position: absolute;
  bottom: 0;
  right: 0;
}

.service-button-prev,
.service-button-next {
  position: absolute;
  left: 104px;
  width: 10px;
  height: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--color-white);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 2px solid #302445;
  z-index: 1;
}
@media (max-width: 991px) {
  .service-button-prev,
  .service-button-next {
    left: 0;
  }
}
.service-button-prev::before,
.service-button-next::before {
  position: absolute;
  content: "";
  background-color: var(--color-deep);
  width: 25px;
  height: 21px;
  z-index: -1;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.service-button-prev i,
.service-button-next i {
  position: absolute;
  top: 50%;
  left: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--color-white);
}
.service-button-prev i:nth-child(2),
.service-button-next i:nth-child(2) {
  opacity: 0;
}
.service-button-prev:hover i,
.service-button-next:hover i {
  left: 15px;
}
.service-button-prev:hover i:nth-child(1),
.service-button-next:hover i:nth-child(1) {
  opacity: 0;
}
.service-button-prev:hover i:nth-child(2),
.service-button-next:hover i:nth-child(2) {
  opacity: 1;
}

.service-button-next {
  left: auto;
  right: 104px;
}
@media (max-width: 991px) {
  .service-button-next {
    right: 0;
  }
}
.service-button-next::before {
  left: auto;
  right: 15px;
}
.service-button-next i {
  left: auto;
  right: 18px;
}
.service-button-next:hover i {
  left: auto;
  right: 15px;
}

.tx-skill-progress-bar {
  padding: 30px;
  width: 250px;
  max-width: 100%;
  background-color: var(--color-violet-2);
  -webkit-box-shadow: -10.798px -2.099px 63.51px 9.49px rgba(0, 2, 32, 0.3);
  box-shadow: -10.798px -2.099px 63.51px 9.49px rgba(0, 2, 32, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: absolute;
  bottom: -64px;
  right: 20px;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.tx-skill-progress-bar .skill-set-percent:not(:last-child) {
  margin-bottom: 20px;
}
.tx-skill-progress-bar .skill-set-percent h4 {
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 6px;
  color: var(--color-white);
}
.tx-skill-progress-bar .skill-set-percent .progress {
  height: 6px;
  border-radius: 0;
  max-width: 100%;
  position: relative;
  overflow: visible;
  border-top: 7px solid #695689;
  background-color: transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.tx-skill-progress-bar .skill-set-percent .progress span {
  right: 0;
  top: -35px;
  font-weight: 700;
  position: absolute;
  font-size: 15px;
  color: var(--color-white);
}
.tx-skill-progress-bar .skill-set-percent .progress-bar {
  width: 0;
  top: -7px;
  float: left;
  height: 6px;
  position: relative;
  -webkit-transition: 1s all ease-in-out;
  transition: 1s all ease-in-out;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.service__content .swiper-slide .service__content-wrap {
  -webkit-transform: translateY(10px) !important;
  transform: translateY(10px) !important;
  opacity: 0;
}
.service__content .swiper-slide.swiper-slide-active .service__content-wrap {
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  opacity: 1;
}
.service__content
  .swiper-slide.swiper-slide-active
  .service__content-wrap
  .service__slide-img {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.service__content
  .swiper-slide.swiper-slide-active
  .service__content-wrap
  .service__icon-box {
  left: 20px;
  opacity: 1;
}
@media (max-width: 767px) {
  .service__content
    .swiper-slide.swiper-slide-active
    .service__content-wrap
    .service__icon-box {
    left: 0;
  }
}
.service__content
  .swiper-slide.swiper-slide-active
  .service__content-wrap
  .tx-skill-progress-bar {
  bottom: -34px;
  opacity: 1;
}
.service__content
  .swiper-slide.swiper-slide-active
  .service__content-wrap
  .service__shape {
  top: 40px;
  opacity: 1;
}

.service-item-inner {
  position: relative;
  margin-top: 20px;
  margin-bottom: 75px;
}
.service-item-inner:hover .item--holder-hover {
  opacity: 1;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  transform: scaleY(1);
}
.service-item-inner .item--image {
  position: relative;
}
.service-item-inner .item--image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #0c0c0c;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0c0c0c),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(to right, #0c0c0c, rgba(255, 255, 255, 0));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#fff",endColorStr="rgba(255, 255, 255, 0)",gradientType="1");
  background-color: initial;
  z-index: 1;
}
.service-item-inner .item--image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 95%;
  background-color: #0c0c0c 42%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(42%, #0c0c0c),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to right,
    #0c0c0c 42%,
    rgba(255, 255, 255, 0)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#0c0c0c 42%",endColorStr="rgba(255, 255, 255, 0)",gradientType="1");
  background-color: initial;
  z-index: 2;
}
.service-item-inner .item--holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 23px 27px;
  z-index: 99;
  -webkit-box-shadow: 0 0 27px rgba(12, 12, 12, 0.07);
  -ms-box-shadow: 0 0 27px rgba(12, 12, 12, 0.07);
  -o-box-shadow: 0 0 27px rgba(12, 12, 12, 0.07);
  box-shadow: 0 0 27px rgba(12, 12, 12, 0.07);
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}
.service-item-inner .item--holder::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
}
.service-item-inner .item--icon {
  font-size: 68px;
  display: inline-block;
}
@media (max-width: 1199px) {
  .service-item-inner .item--icon {
    font-size: 40px;
  }
}
.service-item-inner .item--title {
  font-size: 22px;
  color: #fefefe;
  max-width: 80%;
  margin-bottom: 9px;
  line-height: 1.27;
}
@media (max-width: 1199px) {
  .service-item-inner .item--title {
    font-size: 20px;
    max-width: 100%;
  }
}
.service-item-inner .item--title a {
  color: inherit;
}
.service-item-inner .item--content {
  font-size: 16px;
  color: #919191;
  max-width: 80%;
  line-height: 24px;
}
.service-item-inner .item--holder-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 1199px) {
  .service-item-inner .item--holder-top {
    display: none;
  }
}
.service-item-inner .item--holder-top .item--icon {
  font-size: 37px;
  margin-bottom: 0;
  margin-right: 15px;
}
.service-item-inner .item--meta {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 56%;
}
.service-item-inner .item--image-small {
  max-width: 170px;
}
.service-item-inner .item--holder-bottom {
  background-color: #191919;
  padding: 15px 30px 15px 40px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 20px 30px rgba(12, 12, 12, 0.25);
  -ms-box-shadow: 0 20px 30px rgba(12, 12, 12, 0.25);
  -o-box-shadow: 0 20px 30px rgba(12, 12, 12, 0.25);
  box-shadow: 0 20px 30px rgba(12, 12, 12, 0.25);
  margin-bottom: -15px;
  position: relative;
}
.service-item-inner .item--holder-bottom::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
}
.service-item-inner .item--holder-bottom .item--content {
  max-width: 100%;
  color: #fff;
  margin-bottom: 8px;
}
.service-item-inner .item--holder-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  opacity: 0;
  -webkit-transform: scaleY(0);
  -khtml-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
}
.service-item-inner .item--holder-hover .item--title {
  max-width: 60%;
}
.service-item-inner .item--readmore {
  font-weight: 700;
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding-right: 15px;
}
.service-item-inner .item--readmore i {
  -webkit-transform: translate(9px, 1px);
  transform: translate(9px, 1px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.service-item-inner .item--readmore:hover i {
  -webkit-transform: translate(13px, 1px);
  transform: translate(13px, 1px);
}

.service-item-two {
  margin: 0;
}
.service-item-two .item--image::before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(18.75%, #2e2341),
    to(rgba(0, 0, 0, 0.17))
  );
  background: linear-gradient(90deg, #2e2341 18.75%, rgba(0, 0, 0, 0.17) 100%);
}
.service-item-two .item--image::after {
  display: none;
}
.service-item-two .item--holder,
.service-item-two .item--holder-bottom {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.service-item-two .item--holder {
  padding: 45px 27px;
}
.service-item-two .item--holder-bottom {
  z-index: 1;
  background: transparent;
}
.service-item-two .item--holder-bottom::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(28.96%, #fc7632),
    color-stop(64.12%, #f72750)
  );
  background: linear-gradient(90deg, #fc7632 28.96%, #f72750 64.12%);
  opacity: 0.15;
}
.service-item-two .item--holder-bottom .item--content {
  color: var(--color-black);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.service-item-two .item--content {
  color: var(--color-white);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.service-item-two .item--holder-hover {
  background-color: var(--color-white);
}
.service-item-two .item--holder-hover .item--title a {
  color: var(--color-black);
}

.tx-pagination.swiper-pagination {
  margin-bottom: 0px;
}
.tx-pagination.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin: 0 5px;
  position: relative;
}
.tx-pagination.swiper-pagination .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 2px;
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #fff)
      )
      content-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
  box-shadow: 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
}
.tx-pagination.swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: transparent;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
.tx-pagination.swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  opacity: 1;
}

.service-single__content {
  margin-right: -25px;
}
@media (max-width: 991px) {
  .service-single__content {
    margin-right: 0;
  }
}
.service-single__content h2 {
  font-size: 34px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .service-single__content h2 {
    font-size: 24px;
  }
}
.service-single__content h3 {
  font-size: 30px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .service-single__content h3 {
    font-size: 22px;
  }
}
.service-single__content p {
  margin-bottom: 30px;
}
.service-single__info {
  position: relative;
  margin: -30px -20px;
}
.service-single__info::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 95%;
  height: 1px;
  background: #2c1a4e;
  opacity: 0.1;
  content: "";
}
@media (max-width: 767px) {
  .service-single__info::before {
    display: none;
  }
}
.service-single__info::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1px;
  height: 85%;
  background: #2c1a4e;
  opacity: 0.1;
  content: "";
}
@media (max-width: 767px) {
  .service-single__info::after {
    display: none;
  }
}
.service-single__info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 20px;
}
@media (max-width: 767px) {
  .service-single__info-item {
    padding: 15px 20px;
  }
}
.service-single__info-item .icon {
  font-size: 40px;
  margin-right: 18px;
}
.service-single__info-item h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.service-single__info-item p {
  line-height: 28px;
  margin-bottom: 0;
}

.testimonial {
  overflow: hidden;
}
.testimonial__slider {
  margin: -18px;
}
.testimonial__item {
  padding: 18px;
  padding-top: 30px;
}
.testimonial__inner {
  padding: 90px 30px 30px 0;
  position: relative;
  z-index: 3;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.testimonial__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--color-white);
  z-index: -1;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  -webkit-box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
}
.testimonial__inner::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -2;
  border-radius: 31px;
  -webkit-border-radius: 31px;
  -moz-border-radius: 31px;
  -ms-border-radius: 31px;
  -o-border-radius: 31px;
  -webkit-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
}
.testimonial__side-bg::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 74px;
  height: 100%;
  content: "";
  background-color: var(--color-violet);
  z-index: -1;
  border-radius: 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  -ms-border-radius: 30px 0 0 30px;
  -o-border-radius: 30px 0 0 30px;
}
.testimonial__inner:hover {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}
.testimonial__inner:hover::after {
  top: 8px;
}
.testimonial__content {
  padding-left: 110px;
}
.testimonial__quote {
  font-size: 45px;
  color: #d0cbda;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}
.testimonial__quote i {
  position: absolute;
  top: -64px;
}
.testimonial__quote i:nth-child(1) {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.testimonial__quote i:nth-child(2) {
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-image: linear-gradient(
    1deg,
    var(--gradient-color-from) 0%,
    var(--gradient-color-to) 100%
  );
  background-image: -webkit-linear-gradient(
    1deg,
    var(--gradient-color-from) 0%,
    var(--gradient-color-to) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.testimonial__inner:hover .testimonial__quote i:nth-child(1) {
  opacity: 0;
}
.testimonial__inner:hover .testimonial__quote i:nth-child(2) {
  opacity: 1;
}
.testimonial__author-info {
  padding-left: 22px;
  margin-top: 52px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.testimonial__author-info .avatar {
  width: 104px;
  height: 104px;
  margin-right: 20px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .testimonial__author-info .avatar {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 767px) {
  .testimonial__author-info .avatar {
    width: 70px;
    height: 70px;
  }
}
.testimonial__author-info .avatar::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 97%;
  height: 97%;
  content: "";
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.testimonial__author-info .avatar img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.testimonial__author-info .content h4 {
  font-size: 18px;
  font-family: var(--font-body);
  text-transform: uppercase;
  margin-bottom: 5px;
}
.testimonial__author-info .content span {
  font-size: 15px;
  color: #4a4c51;
  font-style: italic;
}
.testimonial__inner:hover .testimonial__author-info .avatar::before {
  -webkit-transform: scale(1.09);
  transform: scale(1.09);
}
.testimonial__rating {
  position: absolute;
  top: 33px;
  left: 28px;
}
.testimonial__rating li {
  font-size: 17px;
  color: #ffc200;
}
.testimonial__active::before {
  position: absolute;
  right: 90px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(../img/shape/tm_line_shape.png) no-repeat;
  content: "";
  width: 51px;
  max-height: 321px;
  height: 100%;
}
@media (max-width: 1199px) {
  .testimonial__active::before {
    display: none;
  }
}
.testimonial__active.style2 .testimonial__single-inner::before,
.testimonial__active.style2 .testimonial__single-inner::after {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.testimonial__active.style2 .testimonial__single-inner::before {
  background-color: #131313;
}
.testimonial__single {
  margin: 20px;
  opacity: 1 !important;
}
.testimonial__slider-wrap {
  margin-left: -70px;
}
@media (max-width: 1199px) {
  .testimonial__slider-wrap {
    margin-left: 0;
  }
}
.testimonial__single-inner {
  max-width: 430px;
  padding: 37px 60px 67px 78px;
  background-color: #0a183f;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  position: relative;
  z-index: 4;
  margin: 20px;
}
@media (max-width: 1199px) {
  .testimonial__single-inner {
    max-width: 360px;
    padding: 31px 35px 67px 44px;
  }
}
@media (max-width: 991px) {
  .testimonial__single-inner {
    margin-left: 120px;
  }
}
@media (max-width: 767px) {
  .testimonial__single-inner {
    margin-left: 15px;
    padding: 31px 30px 40px 40px;
  }
}
.testimonial__single-inner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #0a183f;
  z-index: -1;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.testimonial__single-inner::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -2;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.testimonial__nav {
  height: 211px;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 10px 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .testimonial__nav {
    padding: 10px 0;
    right: auto;
    left: 0;
  }
}
@media (max-width: 767px) {
  .testimonial__nav {
    position: relative;
    padding: 10px;
    height: auto;
    width: 211px;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.testimonial__nav.style2 .testimonial__nav-thumb::after {
  background-color: #222222;
}
.testimonial__nav-thumb {
  width: 50px;
  height: 50px !important;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-left: 90px;
  margin-right: 7px;
}
@media (max-width: 991px) {
  .testimonial__nav-thumb {
    margin-left: 0;
    margin-right: 90px;
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .testimonial__nav-thumb {
    margin: 0;
    height: auto !important;
  }
}
.testimonial__nav-thumb img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.testimonial__nav-thumb::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 98%;
  height: 98%;
  content: "";
  z-index: -1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.testimonial__nav-thumb::after {
  position: absolute;
  left: -73px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  background-color: #20264c;
  content: "";
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
@media (max-width: 1199px) {
  .testimonial__nav-thumb::after {
    display: none;
  }
}
.testimonial__nav-thumb.swiper-slide-prev::after {
  left: -51px;
  opacity: 1;
}
.testimonial__nav-thumb.swiper-slide-active::after {
  opacity: 1;
  left: -43px;
  background-color: White;
}
.testimonial__nav-thumb.swiper-slide-next::after {
  left: -51px;
  opacity: 1;
}
.testimonial__cta h3 {
  font-size: 30px;
  color: var(--color-white);
  margin-right: 20px;
  margin-top: 20px;
}
.testimonial__cta .icon {
  margin-right: 65px;
}
.testimonial__single-inner .thumb {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
}
.testimonial__single-inner p {
  font-size: 17px;
  color: #dfdfdf;
  line-height: 30px;
}
.testimonial__author {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #362947;
}
.testimonial__author .info h4 {
  color: var(--color-white);
}
.testimonial__author .info span {
  color: #6178b7;
}
.testimonial__author .rating i {
  font-size: 15px;
  color: #c5d828;
}
.testimonial__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 991px) {
  .testimonial__bg {
    height: 40%;
  }
}
@media (max-width: 767px) {
  .testimonial__bg {
    height: 100px;
  }
}
.testimonial__border-text {
  right: 0;
  bottom: -36px;
  top: auto;
  left: auto;
  margin-right: -20px;
  z-index: 0;
}
@media (max-width: 991px) {
  .testimonial__border-text {
    bottom: -10px;
    margin-right: 55px;
  }
}
@media (max-width: 767px) {
  .testimonial__border-text {
    bottom: 0;
    margin-right: -67px;
  }
}
.testimonial__shape .shape {
  position: absolute;
}
@media (max-width: 767px) {
  .testimonial__shape .shape {
    display: none;
  }
}
.testimonial__shape .shape--1 {
  bottom: 0;
  left: 0;
}
@media (max-width: 1199px) {
  .testimonial__shape .shape--1 {
    bottom: -5%;
    left: 0;
    max-width: 200px;
  }
}
.testimonial__shape .shape--2 {
  top: -22%;
  right: 0;
}
@media (max-width: 991px) {
  .testimonial__shape .shape--2 {
    top: -30%;
    right: -14%;
  }
}
.testimonial__item2 {
  padding: 0;
}

.testimonial__active
  .swiper-slide.swiper-slide-active
  .testimonial__single-inner {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 1;
}

.testimonial__active
  .swiper-slide.swiper-slide-active
  .testimonial__single-inner::after {
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  opacity: 1;
}

.testimonial__nav-thumb.swiper-slide-active::before,
.testimonial__nav-thumb:hover::before {
  -webkit-transform: scale(1.1);
  transform: scale(1.2);
}

.counter__item {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}
.counter__item .icon {
  width: 76px;
  height: 76px;
  background-color: #2e2341;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-white);
  font-size: 40px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}
.counter__item .icon::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
.counter__item:hover .icon::before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.counter__item h2 {
  color: var(--color-white);
  font-size: 36px;
}
.counter__item h2 span {
  color: var(--color-white);
}
.counter__item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(54, 31, 97);
  background-image: -ms-linear-gradient(
    -123deg,
    rgb(14, 0, 39) 1%,
    rgb(85, 46, 157) 100%
  );
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  opacity: 0.22;
  content: "";
}
.counter__item::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/shape/counter_shape.png) no-repeat;
  background-size: auto;
  background-position: center center;
  content: "";
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.counter__item .title {
  font-size: 15px;
  color: #b7c1d1;
}
.counter__item:hover::after {
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.counter__number {
  color: var(--color-white);
}
.counter__single {
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 0 15px 20px;
  z-index: 1;
}
.counter__single::before,
.counter__single::after {
  position: absolute;
  left: 0;
  bottom: 0;
  border-width: 2px;
  border-style: solid;
  border-color: #282723;
  content: "";
  width: 100%;
  height: calc(100% - 38px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
}
.counter__single::after {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
  box-shadow: 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
  -o-border-image: linear-gradient(
      to left,
      rgb(253, 211, 5) 1%,
      rgb(220, 134, 13) 100%
    )
    1;
  border-image: -webkit-gradient(
      linear,
      right top,
      left top,
      color-stop(1%, rgb(253, 211, 5)),
      to(rgb(220, 134, 13))
    )
    1;
  border-image: linear-gradient(
      to left,
      rgb(253, 211, 5) 1%,
      rgb(220, 134, 13) 100%
    )
    1;
  opacity: 0;
}
.counter__single .icon {
  font-size: 48px;
  color: var(--color-black);
  width: 84px;
  height: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.counter__single .counter__number {
  color: #fefefe;
  font-size: 48px;
}
.counter__single:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.counter__single:hover::before {
  -webkit-box-shadow: inset 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
  box-shadow: inset 0px 0px 6px 0px rgba(254, 214, 20, 0.85);
}
.counter__single:hover::after {
  opacity: 1;
}
.counter__single .title {
  font-size: 18px;
  color: #fefefe;
  opacity: 0.6;
}

.blog__slider {
  margin: 0 -12px;
}
.blog__item {
  padding: 0 13px;
}
.blog__inner {
  padding: 30px;
  padding-bottom: 38px;
  margin: 30px 0;
  position: relative;
  z-index: 3;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
}
.blog__inner:before,
.blog__inner::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: calc(100% - 8px);
  content: "";
  z-index: -1;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.blog__inner::before {
  transform-origin: right center;
  -webkit-transform-origin: right center;
  -webkit-transform: scale(0, 1);
  -khtml-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: transform 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: -webkit-transform 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: transform 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: transform 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85),
    -webkit-transform 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  z-index: -1;
  background: #0c0122;
}
.blog__inner::after {
  width: calc(100% - 20px);
  z-index: -3;
  margin: 0 auto;
}
.blog__inner:hover {
  color: var(--color-white);
}
.blog__inner:hover::before {
  transform-origin: left center;
  -webkit-transform-origin: left center;
  -webkit-transform: scale(1, 1);
  -khtml-transform: scale(1, 1);
  transform: scale(1, 1);
}
.blog__inner:hover::after {
  top: 8px;
  -webkit-transition-delay: 0.16s;
  transition-delay: 0.16s;
}
.blog__inner:hover .blog__title a,
.blog__inner:hover .blog__link {
  color: var(--color-white);
}
.blog__inner .tx-item-bg {
  background-color: var(--color-white);
  z-index: -2;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(13, 0, 34, 0.04);
  box-shadow: 0px 0px 18px 0px rgba(13, 0, 34, 0.04);
  height: calc(100% - 8px);
}
.blog__meta .avatar {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
  font-weight: 700;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 14px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.blog__meta .avatar::before,
.blog__meta .avatar::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
}
.blog__meta .avatar::after {
  opacity: 0;
}
.blog__meta .info {
  font-size: 16px;
  color: #67687a;
  line-height: 22px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.blog__meta .info a {
  color: #0f1652;
  font-weight: 700;
}
.blog__inner:hover .blog__meta .avatar::before {
  opacity: 0;
}
.blog__inner:hover .blog__meta .avatar::after {
  opacity: 1;
}
.blog__inner:hover .blog__meta .info {
  color: #ebebeb;
}
.blog__inner:hover .blog__meta .info a {
  color: #ebebeb;
}
@media (max-width: 1199px) {
  .blog__slider-inner {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .blog__slider-inner {
    padding: 0;
  }
}
.blog__tags li:not(:last-child) {
  margin-right: 12px;
}
.blog__tags li a {
  font-size: 12px;
  color: var(--color-white);
  display: inline-block;
  padding: 6px 13px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  line-height: 1;
  font-weight: 700;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.blog__tags li a::before,
.blog__tags li a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
}
.blog__tags li a::after {
  background: var(--color-white);
  opacity: 0;
}
.blog__inner:hover .blog__tags a {
  color: #0f1652;
}
.blog__inner:hover .blog__tags a::before {
  opacity: 0;
}
.blog__inner:hover .blog__tags a::after {
  opacity: 1;
}
.blog__title {
  font-size: 22px;
  line-height: 30px;
}
@media (max-width: 991px) {
  .blog__title {
    font-size: 18px;
    line-height: 28px;
  }
}
.blog__title a {
  color: #0e0d0f;
}
.blog__link {
  font-size: 16px;
  font-weight: 700;
  color: #0e0d0f;
  margin-top: 30px;
  display: inline-block;
}
.blog__link:hover {
  color: #0e0d0f;
  text-decoration: underline;
}
.blog__link:hover i {
  -webkit-transform: translateX(12px);
  transform: translateX(12px);
}
.blog__link i {
  font-size: 13px;
  -webkit-transform: translateX(6px);
  transform: translateX(6px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.blog__single {
  background-color: var(--color-deep-2);
  position: relative;
  margin-bottom: 6px;
  margin-top: 6px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.blog__single::before {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.blog__single:hover {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}
.blog__single:hover::before {
  opacity: 1;
  bottom: 0px;
}
.blog__single .blog-meta {
  background-color: var(--thm-color-2);
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 50px);
  padding: 10px 10px;
}
.blog__single .blog-meta a {
  font-size: 15px;
  font-weight: 700;
  color: #010101;
  position: relative;
}
.blog__single .blog-meta a:not(:last-child) {
  margin-right: 20px;
  padding-right: 20px;
}
.blog__single .blog-meta a:not(:last-child)::before {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 21px;
  content: "";
  background-color: #53622a;
}
.blog__single .blog-meta a i {
  font-size: 14px;
  margin-right: 10px;
}
.blog__thumb {
  overflow: hidden;
  position: relative;
}
.blog__thumb .image img {
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  width: 100%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.blog__content {
  padding-top: 45px;
  padding: 45px 23px 25px;
}
.blog__content .blog-title {
  font-size: 22px;
  line-height: 34px;
  color: var(--color-white);
  border-bottom: 1px solid #362a48;
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.blog__content .blog-title a {
  color: currentColor;
}
.blog__content .blog-link {
  font-size: 15px;
  font-weight: 700;
  color: var(--color-white);
}
.blog__content .blog-link i {
  font-size: 13px;
  margin-left: 6px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.blog__content .blog-link:hover i {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}
.blog__single:hover .image img {
  -webkit-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog__shape {
  position: absolute;
  bottom: -200px;
  left: 50%;
  width: 580px;
  right: 0;
  margin: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.blog__single:hover .blog__shape {
  bottom: -160px;
  opacity: 1;
}
.blog__active {
  margin: 0 -20px;
  padding: 0 20px;
}
.blog__active-style2 .blog__single {
  background-color: #191919;
}
.blog__active-style2 .blog__content .blog-title {
  border-color: #2e2e2e;
}
.blog__bg-shape {
  position: absolute;
  bottom: -90px;
  left: 0;
}
@media (max-width: 767px) {
  .blog__bg-shape {
    bottom: -50px;
    max-width: 200px;
  }
}
.blog__shape-left {
  position: absolute;
  top: -26%;
  left: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .blog__shape-left {
    top: -34%;
    left: -6%;
  }
}
@media (max-width: 1199px) {
  .blog__shape-left {
    top: -29%;
    max-width: 300px;
  }
}
@media (max-width: 991px) {
  .blog__shape-left {
    top: -31%;
    left: -50px;
  }
}
.blog__post-wrap {
  margin-right: -25px;
}
@media (max-width: 991px) {
  .blog__post-wrap {
    margin-right: 0;
  }
}
.blog__post-item {
  padding: 30px;
  border: 1px solid #ddd;
}
@media (max-width: 767px) {
  .blog__post-item {
    padding: 25px 15px;
  }
}
.blog__post-item:hover .post-thumb img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog__post-item:hover .post-title a {
  background-size: 100% 100%;
}
.blog__post-item .post-thumb {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.blog__post-item .post-thumb img {
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.blog__post-item .post-meta a {
  font-size: 16px;
  color: var(--color-black);
  position: relative;
}
.blog__post-item .post-meta a:not(:last-child) {
  margin-right: 16px;
  padding-right: 20px;
}
.blog__post-item .post-meta a:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 1px;
  height: 20px;
  background-color: #e6e6e6;
}
.blog__post-item .post-meta a i {
  margin-right: 10px;
}
.blog__post-item .post-title {
  font-size: 30px;
  color: var(--color-black);
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .blog__post-item .post-title {
    font-size: 24px;
  }
}
.blog__post-item .post-title a {
  color: inherit;
}
.blog__post-item .post-btn {
  font-size: 16px;
  margin-top: 15px;
  display: inline-block;
}
.blog__post-item .post-btn:hover i {
  margin-left: 12px;
}
.blog__post-item .post-btn i {
  font-size: 15px;
  margin-left: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: inline-block;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.blog-content-right {
  z-index: 1;
}
.blog-content-right .tx-border-text {
  -webkit-transform: translateX(285px);
  transform: translateX(285px);
  margin-top: -90px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .blog-content-right .tx-border-text {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    margin-top: -26px;
  }
}
@media (max-width: 991px) {
  .blog-content-right .tx-border-text {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    margin-top: -103px;
  }
}
@media (max-width: 767px) {
  .blog-content-right .tx-border-text {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@media (max-width: 767px) {
  .blog-swiper-arrow .tx-swiper-arrow {
    position: unset;
    margin: 0 15px;
  }
}
@media (max-width: 767px) {
  .blog-swiper-arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

blockquote {
  background-color: rgba(44, 26, 78, 0.05);
  padding: 40px;
  padding-right: 130px;
  border-left: 3px solid var(--color-primary);
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  color: var(--color-black);
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  blockquote {
    font-size: 18px;
    line-height: 30px;
    padding-right: 120px;
  }
}
@media (max-width: 767px) {
  blockquote {
    font-size: 17px;
    padding: 30px;
  }
}
blockquote .quote {
  position: absolute;
  right: 40px;
  bottom: 34px;
  z-index: -1;
  max-width: 100px;
}
@media (max-width: 1199px) {
  blockquote .quote {
    width: 60px;
  }
}
@media (max-width: 767px) {
  blockquote .quote {
    width: 50px;
  }
}
blockquote span {
  font-size: 16px;
  color: #332d2d;
  padding-left: 30px;
  position: relative;
  font-family: var(--font-body);
  display: block;
  margin-top: 6px;
}
@media (max-width: 767px) {
  blockquote span {
    font-size: 15px;
    margin-top: 10px;
  }
}
blockquote span::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 2px;
  background-color: currentColor;
  content: "";
}

.post-details h2 {
  font-size: 32px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .post-details h2 {
    font-size: 24px;
  }
}
.post-details h3 {
  font-size: 28px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .post-details h3 {
    font-size: 22px;
  }
}
.post-details p:not(:last-child) {
  margin-bottom: 25px;
}

.video .caption {
  text-align: center;
  font-style: italic;
  font-size: 16px;
  color: #807a7a;
  margin-top: 15px;
  display: block;
  font-weight: 500;
}

.post-tags-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.post-tags-share .title {
  font-size: 20px;
  font-weight: 500;
  margin-right: 20px;
  font-family: var(--font-body);
  margin-top: 20px;
}
.post-tags-share .tags ul {
  margin-top: 10px;
}
.post-tags-share .tags ul li {
  margin-top: 10px;
}
.post-tags-share .tags ul li:not(:last-child) {
  margin-right: 16px;
}
.post-tags-share .tags ul li a {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  padding: 3px 18px;
  display: inline-block;
  border: 1px solid rgba(44, 26, 78, 0.05);
  color: var(--color-default);
  font-size: 12px;
  font-weight: 500;
  color: #949392;
  line-height: 26px;
  position: relative;
}
.post-tags-share .tags ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.post-tags-share .tags ul li a:hover {
  color: #fff;
  border-color: transparent;
}
.post-tags-share .tags ul li a:hover::before {
  opacity: 1;
}
.post-tags-share .social-share ul {
  margin-top: 10px;
}
.post-tags-share .social-share ul li {
  margin-top: 10px;
}
.post-tags-share .social-share ul li:not(:last-child) {
  margin-right: 12px;
}
.post-tags-share .social-share ul li a {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  width: 40px;
  height: 38px;
  color: #4a4c51;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
}
.post-tags-share .social-share ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 26, 78, 0.05);
  content: "";
  z-index: -2;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.post-tags-share .social-share ul li a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  content: "";
  z-index: -1;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.post-tags-share .social-share ul li a:hover {
  color: #fff;
}
.post-tags-share .social-share ul li a:hover::after {
  opacity: 1;
}

/* comment css */
.post-comments {
  padding-bottom: 55px;
}
.post-comments .title {
  font-size: 26px;
  margin-bottom: 25;
  font-weight: 500;
  font-family: var(--font-body);
}

.latest__comments .comments-box {
  border-top: 1px solid var(--color-border-2);
  padding: 30px 0;
}
.latest__comments .comments-text {
  overflow: hidden;
}
.latest__comments .comments-avatar {
  border-radius: 50%;
  float: left;
}
@media (max-width: 767px) {
  .latest__comments .comments-avatar {
    float: none;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest__comments .comments-avatar {
    float: left;
    margin-bottom: 0px;
  }
}
.latest__comments .comments-avatar img {
  border-radius: 50%;
  width: 100px !important;
  margin-right: 30px;
}
.latest__comments li:last-child .comments-box {
  padding-bottom: 0px;
}
.latest__comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}
.latest__comments li.children {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .latest__comments li.children {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest__comments li.children {
    margin-left: 50px;
  }
}
.latest__comments .avatar-name {
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
}
.latest__comments .avatar-name h5 {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
  font-family: var(--font-body);
}
.latest__comments .avatar-name span {
  font-size: 15px;
  color: var(--color-default);
}
.latest__comments .reply {
  color: var(--color-black);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  line-height: 1;
  position: absolute;
  right: 0;
  margin-top: 0;
  top: 0;
  text-decoration: none;
}
.latest__comments .reply i {
  margin-right: 5px;
}
.latest__comments .reply:hover {
  color: var(--color-primary);
}
.latest__comments--2 p {
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black);
}

.comments-form .title {
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: 500;
  font-family: var(--font-body);
}
.comments-form .form input,
.comments-form .form textarea {
  height: 55px;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  font-size: 15px;
  color: var(--color-black);
  border-radius: 0px;
  background-color: #f8f8fb;
  border: 1px solid transparent;
  font-weight: 400;
}
.comments-form .form input::-webkit-input-placeholder,
.comments-form .form textarea::-webkit-input-placeholder {
  color: #6e6d79;
  opacity: 1;
}
.comments-form .form input::-moz-placeholder,
.comments-form .form textarea::-moz-placeholder {
  color: #6e6d79;
  opacity: 1;
}
.comments-form .form input:-ms-input-placeholder,
.comments-form .form textarea:-ms-input-placeholder {
  color: #6e6d79;
  opacity: 1;
}
.comments-form .form input:-moz-placeholder,
.comments-form .form textarea:-moz-placeholder {
  color: #6e6d79;
  opacity: 1;
}
.comments-form .form input:focus,
.comments-form .form textarea:focus {
  border-color: var(--color-primary);
}
.comments-form .form textarea {
  padding: 20px 30px;
  height: 150px;
}
.comments-form__btn button {
  padding: 16px 35px;
}

.team__item {
  max-width: 276px;
  margin: auto;
}
.team__img {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: auto;
  padding: 3px;
  padding-top: 13px;
}
.team__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 3;
  position: relative;
}
.team__img::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00001c;
  border: 3px solid #361f61;
  background-image: -ms-linear-gradient(-90deg, #00001c 1%, #00071a 100%);
  content: "";
  z-index: 2;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  opacity: 0.39;
}
.team__img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/shape/team_shape.png) no-repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-size: auto;
  background-position: center center;
  content: "";
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
}
.team__item:hover .team__img::after {
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.team__link {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  top: 22px;
  right: 33px;
  background-color: #1a1a47;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #190219;
  font-size: 12px;
  z-index: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__item:hover .team__link {
  background-color: var(--thm-color-2);
}
.team__shape {
  position: absolute;
  bottom: -78px;
  width: 342px;
  left: 50%;
  -webkit-transform: translate(-50%, 30px);
  transform: translate(-50%, 30px);
  z-index: 4;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__item:hover .team__shape {
  opacity: 0.7;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}
.team__social {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  z-index: 4;
}
.team__social li {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.team__social li:not(:last-child) {
  margin-right: 24px;
}
.team__social li a {
  font-size: 16px;
  color: var(--color-white);
}
.team__item:hover .team__social li {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.team__item:hover .team__social li:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.team__item:hover .team__social li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.team__item:hover .team__social li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.team__item:hover .team__social li:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.team__item:hover .team__social li:nth-child(5) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.team__item:hover .team__social li:nth-child(5) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.team__info h3 {
  font-size: 22px;
  color: var(--color-white);
}
.team__info span {
  color: #868686;
  font-size: 14px;
}
.team__carousel {
  margin-right: -50px;
  padding-left: 20px;
}
@media (max-width: 991px) {
  .team__carousel {
    margin-right: 0;
    padding-right: 20px;
  }
}
.team__single {
  padding: 55px 12px 0 47px;
  position: relative;
  margin-top: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 70px;
}
.team__single::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  content: "";
  z-index: -1;
}
.team__single::after {
  position: absolute;
  bottom: -73px;
  right: -13px;
  width: 100%;
  width: calc(100% - 18px);
  height: 100%;
  background-color: #facc06;
  content: "";
  z-index: -2;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.team__single:hover,
.team__single.swiper-slide-active {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.team__single:hover::after,
.team__single.swiper-slide-active::after {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.team__single:hover .team-info h3,
.team__single:hover .team-info span,
.team__single.swiper-slide-active .team-info h3,
.team__single.swiper-slide-active .team-info span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.team__single:hover .team-info span,
.team__single.swiper-slide-active .team-info span {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.team__single:hover .team-link,
.team__single.swiper-slide-active .team-link {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.team__single:hover .team-social li,
.team__single.swiper-slide-active .team-social li {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.team__single:hover .team-social li:nth-child(6),
.team__single.swiper-slide-active .team-social li:nth-child(6) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.team__single:hover .team-social li:nth-child(5),
.team__single.swiper-slide-active .team-social li:nth-child(5) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.team__single:hover .team-social li:nth-child(4),
.team__single.swiper-slide-active .team-social li:nth-child(4) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.team__single:hover .team-social li:nth-child(3),
.team__single.swiper-slide-active .team-social li:nth-child(3) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.team__single:hover .team-social li:nth-child(2),
.team__single.swiper-slide-active .team-social li:nth-child(2) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.team__single:hover .team-social li:nth-child(1),
.team__single.swiper-slide-active .team-social li:nth-child(1) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.team__single:hover .team-image img,
.team__single.swiper-slide-active .team-image img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
.team__single:hover .name,
.team__single.swiper-slide-active .name {
  -webkit-transform: translateY(0) rotate(-90deg);
  transform: translateY(0) rotate(-90deg);
  opacity: 1;
}
.team__single .team-image {
  text-align: right;
}
.team__single .team-image img {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  opacity: 0.4;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__single .team-info {
  position: absolute;
  bottom: -59px;
  left: 54px;
  line-height: 1.2;
}
.team__single .team-info h3 {
  color: #040310;
  font-size: 22px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__single .team-info span {
  display: inline-block;
  font-size: 14px;
  color: #040310;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-top: -5px;
}
.team__single .team-link {
  width: 44px;
  height: 44px;
  background-color: var(--color-white);
  color: #040310;
  font-size: 15px;
  position: absolute;
  bottom: -57px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__single .name {
  position: absolute;
  left: -168px;
  bottom: 231px;
  font-size: 68px;
  font-weight: 700;
  font-family: var(--font-heading);
  -webkit-transform: translateY(20px) rotate(-90deg);
  transform: translateY(20px) rotate(-90deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  letter-spacing: -1px;
  color: #1f1f1f;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .team__single .name {
    left: -142px;
    bottom: 175px;
    font-size: 56px;
  }
}
@media (max-width: 1199px) {
  .team__single .name {
    left: -168px;
    bottom: 210px;
    font-size: 60px;
  }
}
.team__single .team-social {
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: -30px;
  top: 100px;
}
.team__single .team-social li {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.team__single .team-social li:not(:last-child) {
  margin-right: 18px;
}
.team__single .team-social li a {
  font-size: 17px;
  color: var(--color-white);
}
.team__content-area {
  padding-right: 30px;
  padding-left: 30px;
  z-index: 1;
  position: relative;
}
@media (max-width: 1199px) {
  .team__content-area {
    padding-right: 0;
    padding-left: 20px;
  }
}
@media (max-width: 991px) {
  .team__content-area {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.team__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: 100px;
  margin-left: calc((100% - 1170px) / 2);
}
@media (max-width: 991px) {
  .team__bg-img {
    margin-left: 0;
  }
}
.team__carousel {
  padding-top: 100px;
}
@media (max-width: 767px) {
  .team__carousel {
    padding-top: 80px;
  }
}
.team__carousel .team-carousel-button {
  position: absolute;
  top: 0;
  right: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .team__carousel .team-carousel-button {
    right: 20px;
  }
}
.team__carousel .team-button-next,
.team__carousel .team-button-prev {
  font-size: 18px;
  color: var(--color-white);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 767px) {
  .team__carousel .team-button-next,
  .team__carousel .team-button-prev {
    width: 50px;
    height: 50px;
  }
}
.team__carousel .team-button-next::before,
.team__carousel .team-button-prev::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: inherit;
  padding: 2px;
  border: 2px solid #6b6b6b;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__carousel .team-button-next::after,
.team__carousel .team-button-prev::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: inherit;
  padding: 2px;
  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #fff)
      )
      content-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: source-over, source-over;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.team__carousel .team-button-next:hover::before,
.team__carousel .team-button-prev:hover::before {
  opacity: 0;
}
.team__carousel .team-button-next:hover::after,
.team__carousel .team-button-prev:hover::after {
  opacity: 1;
}
.team__carousel .team-button-prev {
  margin-right: 20px;
}
.team__bottom-info .tx-border-text {
  position: absolute;
  top: auto;
  bottom: -22px;
  left: 0;
  left: calc((100% - 1170px) / 2);
  padding-left: 80px;
}
@media (max-width: 991px) {
  .team__bottom-info .tx-border-text {
    bottom: -12px;
    left: 0;
    padding-left: 0;
    -webkit-transform: translateX(27px);
    transform: translateX(27px);
  }
}
.team__btn {
  position: absolute;
  bottom: 0;
  right: 85px;
  padding: 30px 46px;
  padding-left: 46px;
  padding-left: 135px;
  font-size: 17px;
  z-index: 1;
  color: var(--color-black);
  font-weight: 700;
}
@media (max-width: 991px) {
  .team__btn {
    right: 20px;
    padding: 23px 40px;
    padding-left: 129px;
  }
}
@media (max-width: 767px) {
  .team__btn {
    right: 15px;
    padding: 15px 25px;
    padding-left: 80px;
  }
}
.team__btn::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 180ms linear 0ms;
  transition: all 180ms linear 0ms;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#dc860d),
    color-stop(51%, #fdd305),
    to(#dc860d)
  );
  background-image: linear-gradient(
    to right,
    #dc860d 0%,
    #fdd305 51%,
    #dc860d 100%
  );
  background-size: 200%, 1px;
  background-position: 0;
  content: "";
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: -1;
}
.team__btn:hover {
  color: var(--color-black);
}
.team__btn:hover::before {
  background-position: 100%;
}
.team__btn span {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  background-color: rgba(0, 0, 0, 0.62);
  background: #5f4f02;
  color: #fff;
}
@media (max-width: 767px) {
  .team__btn span {
    width: 55px;
  }
}
.team__btn i {
  font-size: 24px;
  line-height: 0;
}
.team__carousel-2 .team__single::after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.team__carousel-2 .team__single::before {
  background-color: var(--color-deep);
}
.team__carousel-2 .team__single .name {
  color: #fff;
  opacity: 0.1;
}
.team__carousel-2 .team__single .team-info h3,
.team__carousel-2 .team__single .team-info span {
  color: var(--color-white);
}

.team-wrap {
  padding-left: calc((100% - 1180px) / 2) !important;
}

.tx-team .item--image,
.team-details-img {
  position: relative;
  overflow: hidden;
}

.tx-team .item--image:before {
  content: "";
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: rgba(14, 0, 39, 0.7);
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  border-radius: 5px;
}

.tx-team .item--image img,
.team-details-img img {
  width: 100%;
  border-radius: 5px;
}

.tx-team .item--title {
  margin-bottom: 3px;
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .tx-team .item--title {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  .tx-team .item--title {
    font-size: 20px;
  }
}

.tx-team .item--title a {
  color: inherit;
}

.tx-team .item--title a:hover {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.tx-team .item--position {
  color: var(--primary-color);
}

.tx-team .item--social,
.team-details-img .team--social {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(-100%, -50%);
  -khtml-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  padding: 10px 0;
  border-radius: 0 10px 10px 0;
  z-index: 9;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.tx-team .item--social a,
.team-details-img .item--social a {
  display: block;
  width: 50px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: var(--color-white);
  font-size: 18px;
}

.tx-team .item--social a:hover,
.team-details-img .team--social a {
  -webkit-transform: translateX(4px);
  -khtml-transform: translateX(4px);
  transform: translateX(4px);
}

.tx-team .item--details {
  position: absolute;
  top: -18px;
  right: 18px;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  border-radius: 36px;
  color: var(--color-white);
  z-index: 10;
  opacity: 0;
  -webkit-transform: translateX(-36px);
  -khtml-transform: translateX(-36px);
  transform: translateX(-36px);
}

.team-details .item--social {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  -khtml-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  padding: 18px 0;
  border-radius: 0 10px 10px 0;
  z-index: 9;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}

.team-details .item--social a {
  display: block;
  width: 50px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.team-details .item--social a:hover {
  -webkit-transform: translateX(4px);
  -khtml-transform: translateX(4px);
  transform: translateX(4px);
}

.tx-team .item--holder {
  position: relative;
  padding-top: 26px;
}

.tx-team .item--inner:hover .item--image:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.tx-team .item--inner:hover .item--social {
  -webkit-transform: translate(0%, -50%);
  -khtml-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  opacity: 1;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
}

.tx-team .item--inner:hover .item--details {
  -webkit-transform: translateX(0);
  -khtml-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  -webkit-transition-delay: 0.24s;
  transition-delay: 0.24s;
}

.team-details__info {
  padding-left: 20px;
}
@media (max-width: 991px) {
  .team-details__info {
    padding-left: 0;
  }
}
.team-details__info .name {
  font-size: 48px;
}
@media (max-width: 1199px) {
  .team-details__info .name {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  .team-details__info .name {
    font-size: 28px;
  }
}
.team-details__info .position {
  display: inline-block;
  margin-bottom: 10px;
}
.team-details__info .team-info {
  font-size: 16px;
  line-height: 30px;
}
.team-details__skill {
  max-width: 500px;
}
.team-details__skill h3 {
  font-size: 25px;
  font-family: var(--font-body);
  margin-bottom: 15px;
  font-weight: 500;
}
.team-details__skill .tx-skill-progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.team-details__skill .tx-skill-progress-bar .skill-set-percent h4,
.team-details__skill .tx-skill-progress-bar .skill-set-percent span {
  color: var(--color-black);
}
.team-details__skill .tx-skill-progress-bar .skill-set-percent .progress {
  border-color: rgba(44, 26, 78, 0.06);
}
.team-details__skill
  .tx-skill-progress-bar
  .skill-set-percent
  .progress
  .progress-bar::before {
  background-color: var(--color-primary);
}
.team-details__skill .tx-skill-progress-bar .skill-set-percent .progress span {
  top: -33px;
}
.team-details__skill .tx-skill-progress-bar .skill-set-percent h4 {
  padding-bottom: 0;
  padding-top: 7px;
}
.team-details__content h3 {
  margin-bottom: 20px;
  font-size: 34px;
}
@media (max-width: 767px) {
  .team-details__content h3 {
    font-size: 27px;
  }
}
.team-details__content p:not(:last-child) {
  margin-bottom: 20px;
}
.team-details__form-inner {
  padding: 30px;
  border: 1px solid rgba(44, 26, 78, 0.15);
}
@media (max-width: 767px) {
  .team-details__form-inner {
    padding: 30px 20px;
  }
}
.team-details__form-inner h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.team-details__form-inner p {
  margin-bottom: 20px;
}
.team-details__form input,
.team-details__form textarea {
  background-color: rgba(44, 26, 78, 0.05);
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
}
.team-details__form input::-webkit-input-placeholder,
.team-details__form textarea::-webkit-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.team-details__form input::-moz-placeholder,
.team-details__form textarea::-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.team-details__form input:-ms-input-placeholder,
.team-details__form textarea:-ms-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.team-details__form input:-moz-placeholder,
.team-details__form textarea:-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.team-details__form input:focus,
.team-details__form textarea:focus {
  border-color: var(--color-primary);
}

@media (max-width: 991px) {
  .case-study .section-heading {
    margin-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .case-study .section-heading {
    margin-bottom: 0;
  }
}
.case-study .swiper-wrapper {
  margin-right: -100px !important;
}
@media (max-width: 1199px) {
  .case-study .swiper-wrapper {
    margin-right: 0px !important;
  }
}
.case-study__image {
  position: relative;
  left: 0;
  width: 67.2%;
  margin-left: 0;
}
@media (max-width: 1199px) {
  .case-study__image {
    width: 50%;
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .case-study__image {
    width: 100%;
    padding-right: 15px;
  }
}
.case-study__image::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 25.5%;
  height: 100%;
  background: #00000a;
  content: "";
  z-index: 2;
}
@media (max-width: 1199px) {
  .case-study__image::before {
    display: none;
  }
}
.case-study__image-item {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}
.case-study__image-item::before {
  opacity: 0.2;
}
.case-study__image-item.swiper-slide-active {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.case-study__image-item.swiper-slide-next {
  opacity: 0;
}
.case-study__image-item.swiper-slide-prev {
  -webkit-transform: scale(0.6) translate(200px, -165px);
  transform: scale(0.6) translate(200px, -165px);
}
@media (max-width: 1199px) {
  .case-study__image-item.swiper-slide-prev {
    -webkit-transform: scale(1) translate(0);
    transform: scale(1) translate(0);
  }
}
.case-study__image-item img {
  width: 100%;
}
.case-study__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  margin-left: -42px;
  position: absolute;
  bottom: 0;
  width: 50%;
  right: 0;
}
@media (max-width: 767px) {
  .case-study__content {
    position: unset;
    margin: 0;
    width: 100%;
  }
}
.case-study__content .swiper-wrapper {
  max-width: 587px;
}
.case-study__content .cs-button-prev,
.case-study__content .cs-button-next {
  position: absolute;
  z-index: 11;
  bottom: 37px;
  right: 29px;
  font-size: 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.case-study__content .cs-button-prev:hover,
.case-study__content .cs-button-next:hover {
  color: var(--thm-color-2);
}
.case-study__content .cs-button-prev {
  right: 75px;
}
.case-study__content .swiper-pagination {
  position: absolute;
  left: 43px;
  bottom: 38px;
  text-align: left;
}
.case-study__content .swiper-pagination span {
  font-size: 18px;
  color: #fefefe;
  opacity: 0.35;
}
.case-study__content .swiper-pagination .swiper-pagination-current {
  font-size: 48px;
  opacity: 1;
}
.case-study__content .swiper-pagination .swiper-pagination-current::before {
  content: "0";
}
.case-study__content .swiper-pagination .swiper-pagination-total:before {
  content: "0";
}
.case-study__cotnent-inner {
  background-color: #0a183f;
  padding: 46px 40px 120px;
  max-width: 587px;
  position: relative;
}
.case-study__cotnent-inner::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 9px;
  height: 136px;
  background-color: red;
  content: "";
}
.case-study__cotnent-inner h3 {
  font-size: 24px;
  color: var(--color-white);
  line-height: 34px;
  margin-bottom: 15px;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
}
.case-study__cotnent-inner p {
  font-size: 16px;
  color: #868996;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
}
.case-study__wrap::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000a;
  content: "";
  z-index: -1;
  -webkit-clip-path: polygon(
    100% 0px,
    100% 100%,
    0px 100%,
    0px 94%,
    53.33% -1px
  );
  clip-path: polygon(100% 0px, 100% 100%, 0px 100%, 0px 94%, 53.33% -1px);
  left: calc((100% - 1250px) / 2);
}
@media (max-width: 1199px) {
  .case-study__wrap::before {
    -webkit-clip-path: polygon(
      100% 0px,
      100% 100%,
      0px 100%,
      0px 94%,
      74.82% -2px
    );
    clip-path: polygon(100% 0px, 100% 100%, 0px 100%, 0px 94%, 74.82% -2px);
    left: 0;
  }
}
@media (max-width: 767px) {
  .case-study__wrap::before {
    display: none;
  }
}

.swiper-slide-active .case-study__cotnent-inner h3,
.swiper-slide-active .case-study__cotnent-inner p {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
}

.swiper-slide-active .case-study__cotnent-inner p {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.project {
  position: relative;
  z-index: 2;
}
.project__bg::before {
  position: absolute;
  bottom: 65px;
  left: 0;
  width: 100%;
  height: 40%;
  background-color: #090909;
  content: "";
}

.project-carousel {
  margin-left: -50px;
  margin-right: -50px;
}
@media (max-width: 991px) {
  .project-carousel {
    margin: 0;
  }
}
.project-carousel .grid-item-inner {
  position: relative;
  margin-bottom: 65px;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  overflow: hidden;
}
.project-carousel .grid-item-inner:hover .item--holder {
  opacity: 0;
  -webkit-transform: translateY(100%);
  -khtml-transform: translateY(100%);
  transform: translateY(100%);
}
.project-carousel .grid-item-inner:hover .item--featured img {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  transform: scale(1);
}
.project-carousel .grid-item-inner:hover .item--readmore a {
  opacity: 1;
  -webkit-transform: translateY(0);
  -khtml-transform: translateY(0);
  transform: translateY(0);
}
.project-carousel .item--featured {
  position: relative;
  text-align: center;
}
.project-carousel .item--featured img {
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  width: 100%;
  -webkit-transform: scale(1.12);
  -khtml-transform: scale(1.12);
  transform: scale(1.12);
}
.project-carousel .item--holder {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 23px 28px;
  background-color: rgba(0, 0, 0, 0.84);
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}
.project-carousel .item--title {
  font-size: 22px;
  margin-bottom: 2px;
  color: var(--color-white);
}
.project-carousel .item--category {
  position: relative;
  font-size: 14px;
  color: var(--color-white);
  padding-right: 69px;
  display: inline-block;
}
.project-carousel .item--category::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 53px;
  height: 2px;
  content: "";
}
.project-carousel .item--readmore {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -khtml-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.project-carousel .item--readmore a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-45px);
  -khtml-transform: translateY(-45px);
  transform: translateY(-45px);
  opacity: 0;
}
.project-carousel .swiper-pagination {
  bottom: -10px;
}

.mt-none-65 {
  margin-top: -65px;
}

.pricing {
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.pricing__wrap {
  padding: 0 50px;
}
@media (max-width: 1199px) {
  .pricing__wrap {
    padding: 0;
  }
}
.pricing__table {
  position: relative;
  z-index: 1;
  padding-top: 54px;
  padding-bottom: 40px;
}
.pricing__table::before,
.pricing__table::after {
  position: absolute;
  max-width: 230px;
  background-color: #201e1f;
  content: "";
  width: 100%;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  z-index: -2;
  -webkit-clip-path: polygon(
    0px 10%,
    50% 0px,
    100% 10%,
    100% 90%,
    50% 100%,
    0px 90%
  );
  clip-path: polygon(0px 10%, 50% 0px, 100% 10%, 100% 90%, 50% 100%, 0px 90%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing__table::after {
  opacity: 0;
}
.pricing__table:hover::after,
.pricing__table:hover .pricing__price::before,
.pricing__table.active::after,
.pricing__table.active .pricing__price::before {
  opacity: 1;
}
.pricing__table:hover .pricing__title,
.pricing__table:hover .pricing__btn,
.pricing__table:hover .pricing__price,
.pricing__table.active .pricing__title,
.pricing__table.active .pricing__btn,
.pricing__table.active .pricing__price {
  color: var(--color-black);
}
.pricing__table:hover .pricing__table-inner::before,
.pricing__table.active .pricing__table-inner::before {
  background-color: #191919;
}
.pricing__table:hover .pricing__list li,
.pricing__table.active .pricing__list li {
  border-color: #424242;
}
.pricing__table2::before,
.pricing__table2 .pricing__price {
  background: #f4f3f6;
}
.pricing__table2 .pricing__title,
.pricing__table2 .pricing__price,
.pricing__table2 .pricing__list li,
.pricing__table2 .pricing__btn {
  color: var(--color-black);
}
.pricing__table2 .pricing__table-inner::before {
  background-color: #dcd6e8;
}
.pricing__table2 .pricing__list li:not(:last-child) {
  border-bottom-color: #cfc9da;
}
.pricing__table2.active .pricing__title,
.pricing__table2.active .pricing__price,
.pricing__table2.active .pricing__list li,
.pricing__table2.active .pricing__btn,
.pricing__table2:hover .pricing__title,
.pricing__table2:hover .pricing__price,
.pricing__table2:hover .pricing__list li,
.pricing__table2:hover .pricing__btn {
  color: #fff;
}
.pricing__table2.active .pricing__list li:not(:last-child),
.pricing__table2:hover .pricing__list li:not(:last-child) {
  border-bottom-color: #382758;
}
.pricing__table2.active .pricing__table-inner::before,
.pricing__table2:hover .pricing__table-inner::before {
  background-color: var(--color-violet);
}
.pricing__table-inner {
  padding-top: 16px;
  margin-top: 12px;
  margin-bottom: 13px;
  position: relative;
}
.pricing__table-inner::before {
  position: absolute;
  max-width: 300px;
  background-color: var(--color-black);
  content: "";
  width: 100%;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  z-index: -1;
  -webkit-clip-path: polygon(
    0px 16%,
    50% 0px,
    100% 16%,
    100% 84%,
    50% 100%,
    0px 84%
  );
  clip-path: polygon(0px 16%, 50% 0px, 100% 16%, 100% 84%, 50% 100%, 0px 84%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing__title {
  font-size: 25px;
  font-weight: 700;
  color: var(--color-white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing__price {
  width: 145px;
  height: 150px;
  background-color: #201e1f;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-heading);
  color: var(--color-white);
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 99% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 99% 75%, 50% 100%, 0 75%);
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing__price::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing__price .preffix {
  font-size: 19px;
  margin-top: -10px;
}
.pricing__price .suffix {
  font-size: 16px;
  font-family: var(--font-body);
  margin-bottom: -23px;
}
.pricing__list {
  max-width: 161px;
  margin: 0 auto;
  padding-top: 31px;
  padding-bottom: 80px;
}
.pricing__list li {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing__list li:not(:last-child) {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #1b1b1b;
}
.pricing__btn {
  font-size: 17px;
  font-weight: 700;
  color: var(--color-white);
}
.pricing__btn:hover {
  text-decoration: underline;
}
.pricing__shape .shape {
  position: absolute;
  z-index: -1;
}
.pricing__shape .shape--1 {
  top: -37px;
  left: 0;
}
@media (max-width: 1199px) {
  .pricing__shape .shape--1 {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .pricing__shape .shape--1 {
    max-width: 200px;
  }
}
.pricing__shape .shape--2 {
  right: 0;
  bottom: -170px;
}
@media (max-width: 1199px) {
  .pricing__shape .shape--2 {
    max-width: 500px;
  }
}
@media (max-width: 767px) {
  .pricing__shape .shape--2 {
    max-width: 300px;
    bottom: -100px;
  }
}
.pricing__shape .shape--3 {
  left: 60px;
  bottom: 0;
  bottom: 30%;
}

.offcanvas-sidebar .sidebar-logo,
.offcanvas-sidebar .sidebar-content,
.offcanvas-sidebar .sidebar-menu,
.offcanvas-sidebar .s-content-wrap,
.offcanvas-sidebar .sidebar-socials-wrap {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
}

.offcanvas-sidebar.active .sidebar-logo,
.offcanvas-sidebar.active .sidebar-content,
.offcanvas-sidebar.active .sidebar-menu,
.offcanvas-sidebar.active .s-content-wrap,
.offcanvas-sidebar.active .sidebar-socials-wrap {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.offcanvas-sidebar.active .sidebar-logo {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.offcanvas-sidebar.active .sidebar-content {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.offcanvas-sidebar.active .sidebar-menu {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.offcanvas-sidebar.active .s-content-wrap {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.offcanvas-sidebar.active .sidebar-socials-wrap {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.sidebar-menu-close {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.offcanvas-sidebar .tx-close {
  background: #111;
  width: 54px;
  height: 54px;
  border-width: 18px;
}

.offcanvas-sidebar .tx-close::before,
.offcanvas-sidebar .tx-close::after {
  background-color: var(--color-white);
}

.offcanvas-sidebar {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -860px;
  max-width: 830px;
  width: 100%;
  padding: 50px 100px;
  height: 100%;
  display: block;
  background-color: var(--color-black);
  z-index: 1020;
  -webkit-box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.29);
  box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.29);
  padding-top: 100px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
@media (max-width: 991px) {
  .offcanvas-sidebar {
    right: -660px;
    max-width: 630px;
    padding: 50px 60px;
  }
}

.offcanvas-sidebar.active {
  right: 0;
}

.sidebar-content {
  max-width: 360px;
  margin-bottom: 65px;
}
.sidebar-content p {
  color: #9f9f9f;
  font-weight: 500;
}

.sidebar-menu li:not(:last-child) {
  margin-bottom: 30px;
}
.sidebar-menu li a {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #9f9f9f;
}
.sidebar-menu li a:hover {
  color: #fff;
}

.s-content-wrap {
  color: #9f9f9f;
}
.s-content-wrap .ct-title {
  margin-right: 30px;
}
.s-content-wrap .ct-item {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}
.s-content-wrap .ct-item + .s-content-wrap .ct-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -16px;
  height: 33px;
  width: 1px;
  background-color: #9f9f9f;
}
.s-content-wrap .ct-item a {
  color: inherit;
}
.s-content-wrap .ct-item .tel {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

@media (max-width: 1199px) {
  .s-content-wrap .qc-title {
    margin-right: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .s-content-wrap .qc-title {
    display: none;
  }
}
@media (max-width: 575px) {
  .s-content-wrap .qc-title {
    display: none;
  }
}
.s-content-wrap .ct-item-wrap {
  margin-left: -30px;
  margin-right: -30px;
}

@media (max-width: 1199px) {
  .s-content-wrap .ct-item-wrap {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media (max-width: 575px) {
  .s-content-wrap .ct-item-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.s-content-wrap .ct-item {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

@media (max-width: 1199px) {
  .s-content-wrap .ct-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.s-content-wrap .ct-item + .ct-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -16px;
  height: 33px;
  width: 1px;
  background-color: #9f9f9f;
}

@media (min-width: 768px) and (max-width: 991px) {
  .s-content-wrap .ct-item + .ct-item:before {
    display: none;
  }
}
@media (max-width: 480px) {
  .s-content-wrap .ct-item + .ct-item:before {
    display: none;
  }
}
.s-content-wrap .ct-item a {
  color: inherit;
}

.s-content-wrap .ct-item a:hover {
  color: inherit;
}

.s-content-wrap .ct-item span a + a {
  display: block;
}

.s-content-wrap .ct-item .tel {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

@media (max-width: 1199px) {
  .s-content-wrap .ct-item .tel {
    font-size: 20px;
  }
}
.s-content-wrap .ct-item {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.sidebar-socials-wrap a {
  color: var(--color-white);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
}
.sidebar-socials-wrap a:before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
  content: "";
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.sidebar-socials-wrap a:hover::before {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.sidebar-socials-wrap a:not(:last-child) {
  margin-right: 25px;
}

/* cart sidebar */
.cart_sidebar {
  padding: 30px;
  padding-bottom: 50px;
}

.cart_sidebar_top {
  background-color: #0e0f11;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 30px;
  margin-top: -30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart_sidebar .heading_title {
  line-height: 1;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
}

.cart_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 25px;
}

.cart_item:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #0e0f11;
}

.cart_item .item_image {
  display: block;
  min-width: 70px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
  width: 60px;
  padding: 5px;
}

.cart_item .item_title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.cart_item .item_price {
  line-height: 1;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  opacity: 0.6;
}

.cart_item .remove_btn {
  top: 50%;
  right: 0px;
  color: var(--thm-color-3);
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  position: absolute;
  border-radius: 100%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  background: transparent;
}

.cart_sidebar {
  top: 0;
  width: 300px;
  right: -320px;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: #000;
  -webkit-box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.29);
  box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.29);
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.cart_sidebar.active {
  right: 0px;
}

.cart_sidebar .tx-close {
  right: -11px;
}

.cart_sidebar .tx-close:before,
.cart_sidebar .tx-close:after {
  background-color: var(--color-white);
}

.cart_sidebar_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 30px 55px;
}

.cart_sidebar_button a {
  text-align: center;
  width: 100%;
  padding: 14px 25px 16px;
}
.cart_sidebar_button a:not(:last-child) {
  margin-bottom: 10px;
}
.cart_sidebar_button a:nth-child(2) {
  background: #0e0f11;
  color: #fff;
}

.cart_sidebar .total_price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  border-top: 1px solid #0e0f11;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@keyframes ct_dual_ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ct_dual_ring {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes BgAnimated1 {
  0% {
    background-position: bottom 10px left;
  }
  50% {
    background-position: bottom 10px right;
  }
  100% {
    background-position: bottom 10px left;
  }
}
@keyframes tx_prixClipFix {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    -webkit-clip-path: polygon(
      50% 50%,
      0 0,
      100% 0,
      100% 100%,
      100% 100%,
      100% 100%
    );
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
#ct-loadding {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  background-color: var(--color-white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  -webkit-transform: scaleX(1);
  -khtml-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: -webkit-transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  transition: transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87),
    -webkit-transform 0.3s cubic-bezier(0.27, 0.76, 0.38, 0.87);
  z-index: -1;
  z-index: 999998;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  pointer-events: none;
}

#ct-loadding.style2 {
  background-color: #00061a;
}

#ct-loadding.style3 {
  background-color: var(--color-black);
}

#ct-loadding .loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#ct-loadding .loading-image img {
  max-height: 80px;
}

#ct-loadding .ct-dual-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
}

#ct-loadding .ct-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-right-color: transparent;
  border-left-color: transparent;
  -webkit-animation: ct_dual_ring 1.2s linear infinite;
  animation: ct_dual_ring 1.2s linear infinite;
  border-bottom-color: var(--color-primary);
  border-top-color: var(--color-primary);
}

#ct-loadding .ct-dot-square {
  display: block;
  font-size: 13px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: initial;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: stevloader 3s infinite linear;
  -webkit-animation: stevloader 3s infinite linear;
}

#ct-loadding .loading-spin {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  -webkit-animation: ct_dual_ring 1s linear infinite;
  animation: ct_dual_ring 1s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

#ct-loadding.style1 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

#ct-loadding .loading-ring {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

#ct-loadding .loading-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: var(--color-primary) transparent var(--color-primary)
    transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite;
}

#ct-loadding.style2 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--thm-color-2);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

#ct-loadding.style3 .loading-spin:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid var(--thm-color-3);
  -webkit-animation: tx_prixClipFix 2s linear infinite;
  animation: tx_prixClipFix 2s linear infinite;
}

.header-search-form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
  z-index: 9999;
  background-color: #0e0f11;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: visibility 0.8s step-end,
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: visibility 0.8s step-end,
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: transform 0.85s cubic-bezier(0.175, 1, 0.275, 1),
    visibility 0.8s step-end;
  transition: transform 0.85s cubic-bezier(0.175, 1, 0.275, 1),
    visibility 0.8s step-end,
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
}
@media (max-width: 767px) {
  .header-search-form-wrapper {
    height: 200px;
  }
}

.header-search-form-wrapper.open {
  -webkit-transition: visibility 0.8s step-start,
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: visibility 0.8s step-start,
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: transform 0.85s cubic-bezier(0.175, 1, 0.275, 1),
    visibility 0.8s step-start;
  transition: transform 0.85s cubic-bezier(0.175, 1, 0.275, 1),
    visibility 0.8s step-start,
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.header-search-form-wrapper .search-form {
  margin: 0 auto;
  position: relative;
  text-align: center;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-search-form-wrapper .search-form input[type="search"] {
  height: 65px;
  line-height: 65px;
  font-size: 28px;
  background-color: transparent;
  text-align: left;
  color: balck;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3294117647);
  padding-left: 0;
  border-radius: 0;
  width: 100%;
  color: white;
}
@media (max-width: 767px) {
  .header-search-form-wrapper .search-form input[type="search"] {
    height: 45px;
    line-height: 50px;
    font-size: 18px;
  }
}

.header-search-form-wrapper
  .search-form
  input[type="search"]::-webkit-input-placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.header-search-form-wrapper
  .search-form
  input[type="search"]::-moz-placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.header-search-form-wrapper
  .search-form
  input[type="search"]:-ms-input-placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.header-search-form-wrapper
  .search-form
  input[type="search"]::-ms-input-placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.header-search-form-wrapper .search-form input[type="search"]::placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.header-search-form-wrapper
  .search-form
  input[type="search"]:-ms-input-placeholder {
  color: #a4a4a4;
}

.header-search-form-wrapper
  .search-form
  input[type="search"]::-ms-input-placeholder {
  color: #a4a4a4;
}

.header-search-form-wrapper .search-submit {
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: 0;
  right: 0;
  background-color: transparent;
  color: #9f9f9f;
  font-size: 24px;
}
@media (max-width: 767px) {
  .header-search-form-wrapper .search-submit {
    height: 35px;
    font-size: 20px;
  }
}

.header-search-form-wrapper .tx-search-close {
  background: #1a1919;
  width: 54px;
  height: 54px;
  border-width: 18px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.header-search-form-wrapper .tx-search-close::before,
.header-search-form-wrapper .tx-search-close::after {
  background-color: var(--color-white);
}
@media (max-width: 767px) {
  .header-search-form-wrapper .tx-search-close {
    width: 50px;
    height: 50px;
    background-color: transparent;
  }
}

.header-search-container {
  width: 100%;
  height: 100%;
  max-width: 1170px;
  border: 0;
  padding: 0;
  background: 0 0;
  -webkit-transform: translate3d(0, calc(100% - 100px), 0);
  transform: translate3d(0, calc(100% - 100px), 0);
  -webkit-transition: -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  transition: transform 0.85s cubic-bezier(0.175, 1, 0.275, 1),
    -webkit-transform 0.85s cubic-bezier(0.175, 1, 0.275, 1);
  margin: auto;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1199px) {
  .header-search-container {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .header-search-container {
    padding: 0 15px;
  }
}

.header-search-form-wrapper.open .header-search-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.breadcrumb {
  min-height: 400px;
  padding: 120px 0;
  margin: 0;
}
@media (max-width: 1199px) {
  .breadcrumb {
    min-height: 300px;
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .breadcrumb {
    min-height: 200px;
    padding: 100px 0;
  }
}
.breadcrumb__content h2 {
  font-size: 60px;
  color: var(--color-white);
  margin-bottom: 10px;
}
@media (max-width: 1199px) {
  .breadcrumb__content h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumb__content h2 {
    font-size: 34px;
  }
}
.breadcrumb .bread-crumb .breadcrumb-item {
  color: var(--color-white);
  font-size: 18px;
}
.breadcrumb .bread-crumb .breadcrumb-item:not(:first-child) {
  padding-left: 18px;
}
.breadcrumb .bread-crumb .breadcrumb-item:not(:first-child)::before {
  content: "\f101";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  left: -3px;
  position: relative;
  padding-right: 10px;
}
.breadcrumb .bread-crumb .breadcrumb-item a {
  color: var(--color-white);
}

.accordion__wrap .accordion-title {
  margin-bottom: 30px;
}

.accordion_box {
  position: relative;
}

.accordion_box .block {
  position: relative;
  background-color: rgba(44, 26, 78, 0.05);
  overflow: hidden;
  margin-bottom: 15px;
}

.accordion_box .block:last-child {
  margin-bottom: 0;
}

.accordion_box .block .acc-btn {
  color: var(--color-black);
  position: relative;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  padding: 20px 30px;
  padding-bottom: 18px;
  font-family: var(--font-heading);
}
@media (max-width: 767px) {
  .accordion_box .block .acc-btn {
    font-size: 16px;
    line-height: 26px;
  }
}

.accordion_box .block .acc-btn::before {
  top: 22px;
  right: 22px;
  line-height: 1;
  font-size: 14px;
  content: "\f324";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (max-width: 767px) {
  .accordion_box .block .acc-btn::before {
    top: 27px;
  }
}

.accordion_box .block.active-block .acc-btn::before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion_box .block.active-block .acc-btn {
  color: var(--color-primary);
}

.accordion_box .block .content {
  position: relative;
  padding: 0px 30px 25px;
}

.accordion_box .block .acc_body {
  position: relative;
  display: none;
}

.accordion_box .block .acc_body.current {
  display: block;
}

.portfolio__menu button {
  background-color: transparent;
  border: 1px solid #e0dde5;
  padding: 8px 30px;
  position: relative;
  font-size: 15px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .portfolio__menu button {
    padding: 5px 21px;
    position: relative;
    font-size: 14px;
  }
}
.portfolio__menu button:not(:last-child) {
  margin-right: 12px;
}
.portfolio__menu button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.portfolio__menu button:hover {
  border-color: transparent;
  color: var(--color-white);
}
.portfolio__menu button:hover::before {
  opacity: 1;
}
.portfolio__img {
  position: relative;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 100%;
  overflow: hidden;
}
.portfolio__img::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background-image: -webkit-gradient(
    to top,
    rgba(14, 0, 39, 0.85) 45%,
    rgba(14, 0, 39, 0)
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(45%, rgba(14, 0, 39, 0.85)),
    to(rgba(14, 0, 39, 0))
  );
  background-image: linear-gradient(
    to top,
    rgba(14, 0, 39, 0.85) 45%,
    rgba(14, 0, 39, 0)
  );
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}
.portfolio__item:hover .portfolio__img::before {
  opacity: 1;
}
.portfolio__item {
  position: relative;
}
.portfolio__item:hover .title,
.portfolio__item:hover .category {
  -webkit-transform: translateY(0);
  -khtml-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.portfolio__item:hover .title {
  -webkit-transition-delay: 0.08s;
  transition-delay: 0.08s;
}
.portfolio__item:hover .category {
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}
.portfolio__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 65px 28px 28px;
}
@media (max-width: 1199px) {
  .portfolio__content {
    padding: 25px 65px 25px 25px;
  }
}
.portfolio__content .title {
  margin-bottom: 5px;
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.05em;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  -webkit-transform: translateY(40px);
  -khtml-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
}
@media (max-width: 1199px) {
  .portfolio__content .title {
    font-size: 22px;
  }
}
.portfolio__content .title a {
  color: inherit;
}
.portfolio__content .category {
  font-size: 14px;
  color: #cdcccc;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  -webkit-transform: translateY(40px);
  -khtml-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  display: inline-block;
}
.portfolio__content .category a {
  color: inherit;
}
.portfolio__link {
  font-size: 30px;
  text-align: center;
  line-height: 38px;
  width: 41px;
  height: 41px;
  border-radius: 38px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  bottom: 31px;
  right: 24px;
  color: var(--color-white);
  font-weight: 400;
  -webkit-transform: scale(0) rotate(0);
  -khtml-transform: scale(0) rotate(0);
  transform: scale(0) rotate(0);
  opacity: 0;
}
.portfolio__link:hover {
  color: var(--color-white);
}
.portfolio__item:hover .portfolio__link {
  -webkit-transform: scale(1) rotate(360deg);
  -khtml-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  opacity: 1;
  -webkit-transition-delay: 0.26s;
  transition-delay: 0.26s;
}
.portfolio__button a {
  padding: 15px 35px;
}

.portfolio-single__content {
  margin-right: -25px;
}
@media (max-width: 991px) {
  .portfolio-single__content {
    margin-right: 0;
  }
}
.portfolio-single__content h2 {
  font-size: 34px;
  margin-bottom: 23px;
}
@media (max-width: 991px) {
  .portfolio-single__content h2 {
    font-size: 25px;
  }
}
.portfolio-single__content h3 {
  font-size: 30px;
  margin-bottom: 23px;
}
@media (max-width: 991px) {
  .portfolio-single__content h3 {
    font-size: 24px;
  }
}
.portfolio-single__content p:not(:last-child) {
  margin-bottom: 25px;
}
.portfolio-single__content ul {
  margin-bottom: 25px;
}
.portfolio-single__content ul li {
  list-style: none;
  padding-left: 28px;
  position: relative;
}
.portfolio-single__content ul li:not(:last-child) {
  margin-bottom: 15px;
}
.portfolio-single__content ul li i {
  position: absolute;
  left: 0;
  top: 6px;
}
.portfolio-single__inner {
  margin-left: 30px;
  padding: 0 30px;
  border: 1px solid #e0dde5;
}
@media (max-width: 991px) {
  .portfolio-single__inner {
    margin-left: 0;
  }
}
.portfolio-single__inner .title {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #e0dde5;
  padding: 29px 30px;
  margin: 0 -30px;
}
.portfolio-single__widget {
  list-style: none;
  padding: 20px 0;
}
.portfolio-single__widget li {
  font-size: 16px;
  line-height: 30px;
  color: var(--color-black);
}
.portfolio-single__widget li:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0dde5;
}
.portfolio-single__social {
  padding: 29px 30px;
  padding-top: 19px;
  margin: 0 -30px;
  border-top: 1px solid #e0dde5;
}
.portfolio-single__social li:not(:last-child) {
  margin-right: 15px;
}
.portfolio-single__social li a {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #968da7;
  color: #968da7;
  font-size: 15px;
  margin-top: 10px;
}

.career-item-inner {
  padding: 30px;
  background-color: #f4f3f6;
  position: relative;
}
@media (max-width: 767px) {
  .career-item-inner {
    padding: 30px 20px;
  }
}
.career-item-inner .item--icon {
  font-size: 52px;
  margin-bottom: 25px;
}
.career-item-inner .item--title {
  font-size: 24px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .career-item-inner .item--title {
    font-size: 22px;
  }
}
.career-item-inner .item--title a {
  color: inherit;
}
.career-item-inner .item--meta li {
  font-size: 16px;
  color: var(--color-black);
  margin-bottom: 10px;
}
.career-item-inner .item--meta li:not(:last-child) {
  margin-right: 30px;
}
.career-item-inner .item--meta li i {
  margin-right: 10px;
}
.career-item-inner .item--content {
  font-size: 16px;
  line-height: 26px;
  color: #4a4c51;
}
.career-item-inner .item--time {
  position: absolute;
  top: 40px;
  right: 30px;
  background-color: #e3e3e6;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 2px 25px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c51;
}

.career-single__content {
  margin-right: -25px;
  padding: 30px;
  border: 1px solid rgba(44, 26, 78, 0.15);
}
@media (max-width: 991px) {
  .career-single__content {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .career-single__content {
    padding: 30px 15px;
  }
}
.career-single__content h3 {
  margin-bottom: 23px;
  font-size: 34px;
}
@media (max-width: 767px) {
  .career-single__content h3 {
    font-size: 24px;
  }
}
.career-single__content h4 {
  margin-bottom: 23px;
  font-size: 24px;
}
@media (max-width: 767px) {
  .career-single__content h4 {
    font-size: 22px;
  }
}
.career-single__content p:not(:last-child) {
  margin-bottom: 20px;
}
.career-single__content ul {
  list-style: none;
  margin-bottom: 30px;
}
.career-single__content ul li {
  padding-left: 28px;
  position: relative;
}
.career-single__content ul li:not(:last-child) {
  margin-bottom: 15px;
}
.career-single__content ul li i {
  position: absolute;
  left: 0;
  top: 6px;
}
.career-single__img {
  margin-bottom: 40px;
}
.career-single__widget {
  padding: 30px;
  border: 1px solid rgba(44, 26, 78, 0.15);
  margin-left: 30px;
}
@media (max-width: 991px) {
  .career-single__widget {
    margin-left: 0;
  }
}
.career-single__widget h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 27px;
}
.career-single__details {
  list-style: none;
}
.career-single__details li {
  font-size: 16px;
  line-height: 30px;
  padding-left: 33px;
  position: relative;
  color: #4a4c51;
}
.career-single__details li:not(:last-child) {
  margin-bottom: 17px;
}
.career-single__details li strong {
  color: var(--color-black);
}
.career-single__details li i {
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 20px;
}

.job-apply__img {
  max-width: 816px;
  padding-right: 80px;
  margin-right: 40px;
}
@media (max-width: 991px) {
  .job-apply__img {
    margin-right: 0;
  }
}
.job-apply__img-bottom {
  position: absolute;
  bottom: -65px;
  right: 0;
  max-width: 492px;
  border: 20px solid var(--color-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .job-apply__img-bottom {
    max-width: 300px;
    border-width: 10px;
  }
}
@media (max-width: 1199px) {
  .job-apply__img-bottom {
    max-width: 300px;
    border-width: 10px;
  }
}
@media (max-width: 767px) {
  .job-apply__img-bottom {
    max-width: 200px;
    border-width: 5px;
    bottom: -20px;
  }
}
.job-apply__inner {
  margin-left: -40px;
}
@media (max-width: 991px) {
  .job-apply__inner {
    margin-left: 0;
  }
}
.job-apply__form input,
.job-apply__form select,
.job-apply__form textarea {
  height: 62px;
  border: 1px solid #e0dde5;
  padding: 20px;
  line-height: 1;
  font-size: 16px;
  color: var(--color-black);
  font-weight: 400;
  margin-bottom: 30px;
  background-color: transparent;
}
.job-apply__form input::-webkit-input-placeholder,
.job-apply__form select::-webkit-input-placeholder,
.job-apply__form textarea::-webkit-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.job-apply__form input::-moz-placeholder,
.job-apply__form select::-moz-placeholder,
.job-apply__form textarea::-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.job-apply__form input:-ms-input-placeholder,
.job-apply__form select:-ms-input-placeholder,
.job-apply__form textarea:-ms-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.job-apply__form input:-moz-placeholder,
.job-apply__form select:-moz-placeholder,
.job-apply__form textarea:-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.job-apply__form input:focus,
.job-apply__form select:focus,
.job-apply__form textarea:focus {
  border-color: var(--color-primary);
}
.job-apply__form label {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.job-apply__form input[type="file"] {
  border: 0;
  padding: 0;
  height: auto;
  cursor: pointer;
}
.job-apply__form textarea {
  min-height: 174px;
}

.woocommerce-content-wrap {
  margin-right: -25px;
}
@media (max-width: 767px) {
  .woocommerce-content-wrap {
    margin-right: 0;
  }
}

.woocommerce-toolbar-top {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .woocommerce-toolbar-top {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.woocommerce-toolbar-top .nav-tabs {
  border: 0;
}
.woocommerce-toolbar-top .nav-item {
  line-height: 1;
}
.woocommerce-toolbar-top .nav-item:not(:first-child) {
  margin-left: 20px;
}
.woocommerce-toolbar-top .nav-item .nav-link {
  border: 0;
  font-size: 22px;
  padding: 0;
  border: 1px solid #e0dde5;
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  position: relative;
  z-index: 1;
  color: var(--color-black);
}
.woocommerce-toolbar-top .nav-item .nav-link::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.woocommerce-toolbar-top .nav-item .nav-link.active {
  border-color: transparent;
  color: var(--color-white);
}
.woocommerce-toolbar-top .nav-item .nav-link.active::before {
  opacity: 1;
}
.woocommerce-toolbar-top .nav-item .nav-link.active .grid-3 .grid-draw span {
  border-color: var(--color-white);
}
.woocommerce-toolbar-top .grid-3 .grid-draw {
  overflow: hidden;
  text-align: center;
  display: block;
}
.woocommerce-toolbar-top .grid-3 .grid-draw span {
  width: 9px;
  height: 9px;
  float: left;
  margin: 0 0 2px 2px;
  margin-left: 2px;
  border: 1.4px solid black;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.woocommerce-toolbar-top .woocommerce-ordering {
  margin-left: 20px;
}
.woocommerce-toolbar-top .woocommerce-ordering select {
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 10px 20px;
  height: 54px;
  border: 1px solid #e0dde5;
  cursor: pointer;
}
.woocommerce-toolbar-top .woocommerce-ordering select:focus {
  border-color: #e0dde5;
}

@media (max-width: 767px) {
  .woocommerce-toolbar-top-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.products .product {
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: var(--color-white);
  border: 1px solid #eae9ee;
  margin-bottom: 30px;
}
.products .product--img {
  position: relative;
}
.products .product--img img {
  width: 100%;
}
.products .product--img::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
}
.products .product--btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.products .product--btn a {
  font-size: 15px;
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  padding: 12px 21px;
}
.products .product--btn a:hover {
  color: var(--color-white);
}
.products .product--btn a i {
  color: var(--color-white);
  margin-right: 5px;
  font-size: 14px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.products .product:hover {
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.products .product:hover .product--img::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
}
.products .product:hover .product--btn a {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.products .product--holder {
  padding: 10px 15px;
}
.products .product--title {
  font-size: 20px;
  color: var(--color-black);
  font-weight: 600;
  margin-bottom: 7px;
}
@media (max-width: 1199px) {
  .products .product--title {
    font-size: 18px;
    margin-bottom: 0px;
  }
}
.products .product--title a {
  color: inherit;
}
.products .product--price {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-primary);
  display: inline-block;
}
@media (max-width: 1199px) {
  .products .product--price {
    font-size: 16px;
  }
}
.products .product--list-view {
  text-align: left;
  position: relative;
}
.products .product--list-view:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.products .product--list-view .product--img {
  width: 250px;
  height: 250px;
  margin-right: 30px;
  text-align: center;
}
.products .product--list-view .product--img::before {
  display: none;
}
.products .product--list-view .product--img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.products .product--list-view .product--btn {
  position: unset;
  -webkit-transform: translate(0);
  transform: translate(0);
}
.products .product--list-view .product--btn a {
  -webkit-transform: translate(0);
  transform: translate(0);
  opacity: 1;
}
.products .product--list-view .product--holder {
  width: calc(100% - 280px);
}
@media (max-width: 767px) {
  .products .product--list-view .product--holder {
    width: 100%;
  }
}
.products .product--list-view .product--title {
  font-size: 22px;
}
.products .product--list-view .product--price {
  font-size: 18px;
  margin-bottom: 10px;
}
.products .product--list-view .product--content {
  margin-bottom: 20px;
  line-height: 28px;
}
.products .product--list-view .product--rating {
  position: absolute;
  bottom: 32px;
  right: 30px;
}
.products .product--list-view .product--rating li {
  color: #fc7632;
  font-size: 15px;
}
.products .product--list-view .product--rating li:not(:last-child) {
  margin-right: 3px;
}

.widget-product {
  list-style: none;
}
.widget-product__item {
  border: 1px solid rgba(44, 26, 78, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.widget-product__item:not(:last-child) {
  margin-bottom: 15px;
}
.widget-product__item .thumb {
  width: 92px;
  height: 92px;
  margin-right: 15px;
}
.widget-product__item .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 20px;
}
.widget-product__item .content {
  width: calc(100% - 107px);
}
.widget-product__item .content h3 {
  font-size: 16px;
  color: var(--color-black);
  font-weight: 500;
}
.widget-product__item .content h3 a {
  color: inherit;
}
.widget-product__item .content .price {
  font-size: 14px;
}
.widget-product__item .content .rating {
  position: absolute;
  right: 15px;
  bottom: 20px;
}
.widget-product__item .content .rating li {
  font-size: 11px;
  color: #fc7632;
}
.widget-product__item .content .rating li:not(:last-child) {
  margin-right: 2px;
}

.shop-sidebar .widget_price_filter .filter-price {
  position: relative;
  font-size: 15px;
  font-size: 1rem;
}

.shop-sidebar .widget_price_filter .filter-price input {
  border: 0;
  padding: 0;
  display: inline-block;
  width: auto;
  position: absolute;
}

.shop-sidebar .widget_price_filter .filter-price input:focus {
  border: 0;
  outline: none;
}

.shop-sidebar .widget_price_filter .filter-price button {
  background-color: transparent;
  color: var(--color-black);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  display: inline-block;
  border: 0;
  outline: none;
  position: absolute;
  right: 0;
  bottom: 0;
}

.shop-sidebar .widget_price_filter .filter-price #amount {
  color: var(--color-default);
  font-weight: 500;
  height: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  margin: 0;
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
  padding-left: 3px;
  color: #6e6d79;
}

.shop-sidebar .widget_price_filter #slider-range {
  height: 6px;
  background: #eeeeed;
  border: 0;
  border-radius: 50px;
  margin-bottom: 12px;
}

.shop-sidebar .widget_price_filter .ui-state-default,
.shop-sidebar .widget_price_filter .ui-widget-content .ui-state-default,
.shop-sidebar .widget_price_filter .ui-widget-header .ui-state-default {
  width: 5px;
  height: 14px;
  margin: 0;
  border: none;
  background: var(--color-primary);
  border-radius: 0;
  top: -4px;
}

.shop-sidebar .widget_price_filter .ui-state-default:focus,
.shop-sidebar .widget_price_filter .ui-widget-content .ui-state-default:focus,
.shop-sidebar .widget_price_filter .ui-widget-header .ui-state-default:focus {
  outline: none;
}

.shop-sidebar .widget_price_filter #slider-range .ui-slider-range {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.product-single-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product_details_img {
  border: 1px solid var(--color-border);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.shop_thumb_tab {
  margin-top: 5px;
}

.shop_thumb_tab ul li {
  margin-bottom: 20px;
}
.shop_thumb_tab ul li:not(:last-child) {
  margin-right: 20px;
}

.shop_thumb_tab ul li .nav-link {
  width: 100px;
  height: 100px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.shop_thumb_tab ul li:last-child {
  margin-bottom: 0;
}

.shop_thumb_tab ul li button {
  padding: 0;
  border: transparent;
  overflow: hidden;
}

.shop_thumb_tab ul li button {
  max-width: 104px;
  height: auto;
  background: transparent;
}

.shop_thumb_tab ul li button.active {
  border-color: var(--color-primary);
}

.product_details_img .pl_thumb img {
  width: 100%;
}

.shop_details .details_content {
  padding-left: 40px;
}

.details_content .title {
  font-size: 45px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.details_content .pl_list {
  margin-top: 25px;
  margin-bottom: 30px;
}

.details_content .price {
  font-size: 25px;
  margin-bottom: 6px;
  font-weight: 700;
}

.details_content .review_wrap {
  margin-bottom: 22px;
}

.details_content .review_wrap .review li {
  color: #ffab50;
  font-size: 13px;
  margin-right: 6px;
}

.product-details p {
  font-size: 17px;
  color: #4a4c51;
  line-height: 30px;
}

.product-details h2 {
  font-size: 36px;
  margin-bottom: 14px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .product-details h2 {
    font-size: 24px;
  }
}

.shop-single-section .product-details .price {
  font-size: 18px;
  margin: 0.4em 0 0.7em;
  font-weight: 500;
  color: var(--color-black);
}
.shop-single-section .product-details .price span {
  color: currentColor;
}

.shop-single-section .product-details .price .old {
  font-size: 18px;
  font-weight: normal;
  color: var(--color-default);
  text-decoration: line-through;
  display: inline-block;
  margin-left: 5px;
}

.shop-single-section .product-details .rating {
  margin-bottom: 20px;
}

.product-details .rating i::before {
  font-size: 12px;
  color: #ffab50;
}

.shop-single-section .product-details .color-size span {
  font-size: 14px;
  font-size: 0.9333333333rem;
  font-weight: bold;
  color: var(--color-default);
  text-transform: uppercase;
  display: inline-block;
  padding-right: 15px;
  display: block;
  margin-bottom: 5px;
}

.shop-single-section .product-details .color-size select {
  min-width: 80px;
  height: 40px;
  border: 1px solid var(--color-border);
  color: var(--color-black);
  outline: 0;
  padding: 6px 15px;
  background-position: calc(100% - 15px) 17px;
}

.shop-single-section .product-details .color-size > div + div {
  margin-top: 15px;
}

.product-details .product-option .product-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details .product-option .product-row input {
  margin-bottom: 0;
}

.product-details .product-option .product-row .add-to-cart-btn button {
  margin-left: 20px;
  padding: 15px 30px;
  font-weight: 600;
}

.product-details .product-option .product-row .add-to-cart-btn button i {
  -webkit-transform: translate(0);
  transform: translate(0);
  font-size: 13px;
  margin-left: 0;
  margin-right: 10px;
}

.shop-single-section
  .product-details
  .product-option
  .product-row
  > div:first-child {
  width: 120px;
  height: 48px;
}

.shop-single-section .product-details .color-size {
  margin-bottom: 30px;
}

.shop-single-section .product-details .product-option .bootstrap-touchspin {
  width: 120px;
  border: 1px solid var(--color-border);
  background-color: #f4f3f6;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.shop-single-section
  .product-details
  .product-option
  .bootstrap-touchspin
  button {
  text-decoration: none;
  border: 0;
  height: 48px;
  line-height: 50px;
  padding: 0 15px;
  font-size: 16px;
  font-size: 1.0666666667rem;
  font-weight: 500;
  color: var(--color-default);
}

.shop-single-section
  .product-details
  .product-option
  .bootstrap-touchspin-down {
  left: 10px;
}

.shop-single-section .product-details .product-option .bootstrap-touchspin-up {
  right: 10px;
}

.shop-single-section
  .product-details
  .product-option
  .bootstrap-touchspin
  input {
  border: 0;
  height: 48px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  font-size: 1.0666666667rem;
  font-weight: 500;
  color: var(--color-default);
  padding: 0;
}

.shop-single-section .product-details .product-count:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.shop-single-section .thb-product-meta-after .product_meta > span {
  font-size: 16px;
  font-weight: 500;
  color: #232323;
  display: block;
  margin-bottom: 15px;
}

.shop-single-section .thb-product-meta-after .product_meta > span a {
  font-weight: 400;
  font-size: 15px;
  color: #4a4c51;
}

.shop-single-section .product-details .product-option {
  margin-top: 40px;
}

.shop-single-section .single-product-info {
  margin-top: 20px;
}

.product-share-wrap a {
  margin-left: 15px;
}

.product-share-wrap a:hover {
  color: var(--color-primary);
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .tablist {
    margin-bottom: 25px;
  }
}
.shop-single-section .single-product-info h4 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.7em;
  font-weight: 500;
}

.shop-single-section .single-product-info p {
  margin-bottom: 1.3em;
}

.shop-single-section .single-product-info .tab-pane p:last-child {
  margin-bottom: 0;
}

.shop-single-section .single-product-info .nav-tabs {
  border: 0;
  display: inline-block;
  margin-top: 5px;
}

.shop-single-section .single-product-info .nav-tabs {
  margin-top: -10px;
}

.shop-single-section .single-product-info .nav-tabs li {
  float: left;
  margin-right: 30px;
  margin-top: 10px;
}

.shop-single-section .single-product-info .nav-tabs li:last-child {
  margin-right: 0;
}

.shop-single-section .single-product-info .nav-tabs button {
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  display: block;
  border: 1px solid #e0dde5;
  padding: 9px 30px;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
}
.shop-single-section .single-product-info .nav-tabs button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.shop-single-section .single-product-info .nav-tabs button.active {
  color: var(--color-white);
  border-color: transparent;
}

.shop-single-section .single-product-info .nav-tabs button.active::before {
  opacity: 1;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .nav-tabs button {
    font-size: 12px;
    padding: 8px 25px;
  }
}
.shop-single-section .single-product-info .nav-tabs a:hover,
.shop-single-section .single-product-info .nav-tabs .active a {
  background-color: transparent;
  color: #00394f;
}

.shop-single-section .single-product-info .tab-content {
  padding: 35px 0;
  margin-top: -1px;
  text-align: left;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .tab-content {
    border: 0;
    padding: 0;
    margin: 0;
  }
}
.shop-single-section .single-product-info .client-rv {
  overflow: hidden;
  margin-bottom: 30px;
}

.shop-single-section .single-product-info .client-rv:last-child {
  margin-bottom: 0;
}

.shop-single-section .single-product-info .client-rv .client-pic {
  width: 60px;
  float: left;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .client-rv .client-pic {
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }
}
.shop-single-section .single-product-info .client-rv .details {
  width: calc(100% - 80px);
  float: right;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .client-rv .details {
    width: 100%;
    float: none;
  }
}
@media (max-width: 767px) {
  .shop-single-section .single-product-info .client-rv .name-rating-time {
    padding-bottom: 3px;
  }
}
.shop-single-section .single-product-info .client-rv .name-rating-time > div,
.shop-single-section .single-product-info .client-rv .name-rating > div {
  display: inline-block;
  font-size: 14px;
  font-size: 0.9333333333rem;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .client-rv .name-rating-time > div,
  .shop-single-section .single-product-info .client-rv .name-rating > div {
    font-size: 12px;
    font-size: 0.8rem;
    display: block;
  }
}
.shop-single-section .single-product-info .client-rv .rating .fi:before {
  font-size: 12px;
}

.shop-single-section .single-product-info .client-rv .rating {
  font-size: 12px;
  color: #e0a500;
  padding-left: 12px;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .client-rv .rating {
    padding-left: 0;
    margin: 4px 0 7px;
  }
}
.shop-single-section .single-product-info .client-rv .name-rating-time .time {
  font-size: 14px;
  font-size: 0.9333333333rem;
  font-weight: 500;
  margin-top: 3px;
  display: block;
  color: #b3b3b3;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .client-rv .name-rating-time .time {
    float: none;
  }
}
.shop-single-section .single-product-info .client-rv .review-body {
  padding-top: 12px;
}

.shop-single-section .single-product-info .review-form-wrapper {
  margin-top: 50px;
}

@media screen and (min-width: 1200px) {
  .shop-single-section .single-product-info .review-form-wrapper {
    padding-left: 15px;
  }
}
@media (max-width: 991px) {
  .shop-single-section .single-product-info .review-form {
    margin-top: 45px;
  }
}
.shop-single-section .single-product-info .review-form h4 {
  margin-bottom: 1.73em;
  font-weight: 500;
}

.shop-single-section .single-product-info .review-form form input,
.shop-single-section .single-product-info .review-form form textarea {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 12px 15px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 991px) {
  .shop-single-section .single-product-info .review-form form input,
  .shop-single-section .single-product-info .review-form form textarea {
    padding: 10px 15px;
  }
}
.shop-single-section .single-product-info .review-form form input:focus,
.shop-single-section .single-product-info .review-form form textarea:focus {
  border: 1px solid var(--color-primary);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.shop-single-section .single-product-info .review-form form textarea {
  height: 130px;
}

.shop-single-section .single-product-info .review-form form > div {
  margin-bottom: 27px;
}

.shop-single-section .single-product-info .review-form form > div:last-child {
  margin-bottom: 0;
}

.shop-single-section
  .single-product-info
  .review-form
  form
  .rating-wrapper
  > div {
  display: inline-block;
}

@media (max-width: 767px) {
  .shop-single-section
    .single-product-info
    .review-form
    form
    .rating-wrapper
    > div {
    display: block;
    float: none !important;
  }
}
.shop-single-section
  .single-product-info
  .review-form
  form
  .rating-wrapper
  > div:last-child {
  float: right;
}

@media (max-width: 767px) {
  .shop-single-section
    .single-product-info
    .review-form
    form
    .rating-wrapper
    > div:last-child {
    margin-top: 20px;
  }
}
.shop-single-section .single-product-info .review-form form .rating a {
  font-size: 14px;
  color: #cccccc;
  display: inline-block;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .shop-single-section .single-product-info .review-form form .rating a {
    font-size: 12px;
  }
}
.shop-single-section
  .single-product-info
  .review-form
  form
  .rating
  a:last-child {
  margin: 0;
}

.shop-single-section .single-product-info .review-form form .rating a:hover {
  color: #e0a500;
}

.shop-single-section .single-product-info .review-form .submit button {
  border: 0;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.shop-single-section .realted-porduct {
  margin-top: 20px;
}

.shop-single-section .realted-porduct .title {
  font-size: 28px;
  line-height: 0.95em;
  margin: 0 0 0.7em;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.account-form {
  border: 1px solid #e0dde5;
  padding: 30px;
}
@media (max-width: 767px) {
  .account-form {
    padding: 20px 15px;
  }
}
.account-form label {
  color: var(--color-black);
  margin-bottom: 10px;
}
.account-form .form-check-input {
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.account-form .form-check-input:focus {
  border-color: var(--color-primary);
}
.account-form .form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.account-form__inner h3 {
  font-size: 28px;
  margin-bottom: 12px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .account-form__inner h3 {
    font-size: 24px;
  }
}
.account-form__btn button {
  padding: 15px 50px;
}
.account-form__chekbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.account-form .lost-password {
  color: #4a4c51;
  margin-top: 15px;
  display: inline-block;
}
.account-form .lost-password:hover {
  color: var(--color-black);
}
.account-form__content a {
  color: var(--color-black);
}

.hamburger-menu {
  display: inline-block;
}

.hamburger-menu a {
  display: inline-block;
}

.body-overlay {
  background-color: #000000;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1010;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  transition: all 600ms ease;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
}

.body-overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.side-mobile-menu ul li a {
  padding: 25px 0;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 30px;
  color: #9f9f9f;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
@media (max-width: 767px) {
  .side-mobile-menu ul li a {
    padding: 8px 0;
    font-size: 15px;
  }
}

.side-mobile-menu ul li a[aria-expanded="true"] {
  color: #fff;
}

.side-mobile-menu ul li a:hover {
  color: #fff;
  padding-left: 5px;
}

.side-mobile-menu ul li ul li:hover > a {
  color: #fff;
  padding-left: 30px;
}

.side-mobile-menu ul li ul li:hover > a::before {
  background: #fff;
  border-color: #fff !important;
}

.side-mobile-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.slide-bar {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -660px;
  max-width: 630px;
  width: 100%;
  padding: 50px 60px;
  height: 100%;
  display: block;
  background-color: var(--color-black);
  z-index: 1020;
  -webkit-box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.29);
  box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.29);
  padding-top: 100px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
@media (max-width: 767px) {
  .slide-bar {
    top: 0;
    right: calc(-100% - 30px);
    max-width: 100%;
    width: 100%;
    padding: 40px 20px;
    padding-top: 70px;
  }
}

.slide-bar.show {
  right: 0;
}

.side-mobile-menu ul li ul li {
  padding-left: 15px;
}

.side-mobile-menu ul li ul li a {
  position: relative;
  padding-left: 25px;
  text-transform: capitalize;
  font-size: 16px;
}

.side-mobile-menu ul li.dropdown > a::after {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: initial;
  right: 16px;
  top: 50%;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  transform: rotate(-45deg) translateY(-50%);
  -moz-transform: rotate(-45deg) translateY(-50%);
  -ms-transform: rotate(-45deg) translateY(-50%);
  -o-transform: rotate(-45deg) translateY(-50%);
}
@media (max-width: 767px) {
  .side-mobile-menu ul li.dropdown > a::after {
    width: 8px;
    height: 8px;
    border-width: 0 2px 2px 0;
  }
}

.side-mobile-menu ul li.dropdown a[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
}

.side-mobile-menu ul li ul li a::before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 2px solid #101a23;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .side-mobile-menu ul li ul li a::before {
    width: 8px;
    height: 8px;
  }
}

.side-mobile-menu ul li ul li a {
  text-transform: capitalize;
  font-size: 25px;
}
@media (max-width: 767px) {
  .side-mobile-menu ul li ul li a {
    font-size: 14px;
  }
}

.close-mobile-menu a {
  color: #fff;
  position: relative;
  z-index: 2;
  font-size: 16px;
  left: 0;
  display: block;
}

.close-mobile-menu a:hover {
  background-color: #111111;
  color: #fff;
}

.close-mobile-menu {
  position: absolute;
  top: 0;
  right: 0;
  background: #111111;
  text-align: center;
  line-height: 40px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.slide-bar .tx-close {
  background: #111;
  width: 54px;
  height: 54px;
  border-width: 18px;
}
@media (max-width: 767px) {
  .slide-bar .tx-close {
    width: 50px;
    height: 50px;
  }
}

.slide-bar .tx-close::before,
.slide-bar .tx-close::after {
  background-color: var(--color-white);
}

.slide-bar .header-mobile-search,
.slide-bar .metismenu {
  opacity: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.slide-bar.show .header-mobile-search,
.slide-bar.show .metismenu {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.slide-bar.show .metismenu {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide-bar .header-mobile-search input {
  width: 100%;
  height: 65px;
  color: white;
  font-size: 25px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  margin-bottom: 10px;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.slide-bar .header-mobile-search input::-webkit-input-placeholder {
  color: #9f9f9f;
  opacity: 1;
}
.slide-bar .header-mobile-search input::-moz-placeholder {
  color: #9f9f9f;
  opacity: 1;
}
.slide-bar .header-mobile-search input:-ms-input-placeholder {
  color: #9f9f9f;
  opacity: 1;
}
.slide-bar .header-mobile-search input:-moz-placeholder {
  color: #9f9f9f;
  opacity: 1;
}
@media (max-width: 767px) {
  .slide-bar .header-mobile-search input {
    height: 50px;
    font-size: 16px;
    border-width: 1px;
  }
}

.slide-bar .header-mobile-search input:focus {
  border-color: #fff;
}

.header-mobile-search {
  margin-bottom: 15px;
  position: relative;
  display: block;
}

.header-mobile-search button {
  position: absolute;
  width: 50px;
  height: 100%;
  right: 0;
  line-height: 100%;
  padding: 0;
  border: none;
  background-color: initial;
  background-image: none;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #9f9f9f;
}
@media (max-width: 767px) {
  .header-mobile-search button {
    font-size: 20px;
  }
}

.error-page__img {
  margin-bottom: 65px;
}
@media (max-width: 991px) {
  .error-page__img {
    margin-bottom: 30px;
  }
}
.error-page__content h2 {
  font-size: 50px;
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .error-page__content h2 {
    font-size: 45px;
  }
}
@media (max-width: 991px) {
  .error-page__content h2 {
    font-size: 45px;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .error-page__content h2 {
    font-size: 25px;
    margin-bottom: 30px;
  }
}

.section-heading .heading-subtitle {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
}
.section-heading .heading-subtitle img {
  margin-right: 7px;
}
.section-heading .heading-subtitle span {
  color: var(--color-primary);
}
.section-heading .heading-title {
  font-size: 60px;
  line-height: 1.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .section-heading .heading-title {
    font-size: 55px;
  }
}
@media (max-width: 1199px) {
  .section-heading .heading-title {
    font-size: 45px;
  }
}
@media (max-width: 991px) {
  .section-heading .heading-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-heading .heading-title {
    font-size: 35px;
  }
}
.section-heading p {
  font-size: 18px;
  color: #1c1d20;
}
.section-heading--md .heading-title {
  font-size: 48px;
}
@media (max-width: 1199px) {
  .section-heading--md .heading-title {
    font-size: 40px;
  }
}
@media (max-width: 991px) {
  .section-heading--md .heading-title {
    font-size: 35px;
  }
}
.section-heading--sm .heading-title {
  font-size: 30px;
  line-height: 1.3;
}
@media (max-width: 767px) {
  .section-heading--sm .heading-title {
    font-size: 25px;
  }
}
.section-heading--white .heading-subtitle,
.section-heading--white .heading-title,
.section-heading--white .section-heading--content.style3 p {
  color: var(--color-white);
}
.section-heading--white p {
  color: var(--color-white);
}
.section-heading--two .heading-subtitle {
  margin-bottom: 15px;
  color: #c1c3c7;
}
.section-heading--two .heading-title span {
  color: var(--thm-color-2);
}
.section-heading--two p {
  color: #aaaaaa;
  font-size: 18px;
  line-height: 36px;
}
.section-heading--content p {
  font-size: 17px;
  line-height: 28px;
  color: #a5a4a4;
}
.section-heading--content.style2 p {
  color: #8f8e8e;
  font-size: 16px;
  line-height: 30px;
}
.section-heading--content.style3 p {
  font-size: 16px;
  line-height: 28px;
}
.section-heading--three .heading-title {
  font-size: 48px;
  padding-left: 12px;
  position: relative;
}
@media (max-width: 1199px) {
  .section-heading--three .heading-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-heading--three .heading-title {
    font-size: 25px;
  }
}
.section-heading--three .heading-title::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 32px;
  min-height: 110px;
  height: 100%;
  background-color: var(--thm-color-3);
  content: "";
  -webkit-transform: translateY(-50%) scaleY(0);
  transform: translateY(-50%) scaleY(0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}
@media (max-width: 767px) {
  .section-heading--three .heading-title::before {
    min-height: 80px;
  }
}
.section-heading--three.animated .heading-title::before {
  -webkit-transform: translateY(-50%) scaleY(1);
  transform: translateY(-50%) scaleY(1);
}
.section-heading--three p {
  color: #8f8e8e;
  font-size: 16px;
  line-height: 28px;
}

.coming-soon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
  padding: 80px 0;
}
.coming-soon__img {
  max-width: 600px;
  margin: 0 auto 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .coming-soon__img {
    max-width: 500px;
  }
}
@media (max-width: 1199px) {
  .coming-soon__img {
    max-width: 450px;
  }
}
@media (max-width: 991px) {
  .coming-soon__img {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .coming-soon__img {
    max-width: 250px;
  }
}
.coming-soon__countdown {
  margin: -11px;
}
@media (max-width: 991px) {
  .coming-soon__countdown {
    margin: -5px;
  }
}
.coming-soon__countdown .single {
  max-width: 200px;
  width: 100%;
  height: 170px;
  background-color: #171717;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-white);
  margin: 11px;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .coming-soon__countdown .single {
    width: 180px;
    height: 150px;
  }
}
@media (max-width: 1199px) {
  .coming-soon__countdown .single {
    width: 160px;
    height: 140px;
  }
}
@media (max-width: 991px) {
  .coming-soon__countdown .single {
    width: 120px;
    height: 100px;
    margin: 5px;
  }
}
.coming-soon__countdown .single h1 {
  color: var(--color-white);
  font-size: 45px;
  margin-bottom: 10px;
}
@media (max-width: 1199px) {
  .coming-soon__countdown .single h1 {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  .coming-soon__countdown .single h1 {
    font-size: 28px;
    margin-bottom: 5px;
  }
}
.coming-soon__form {
  position: relative;
  max-width: 554px;
  margin: 0 auto;
  margin-top: 50px;
}
.coming-soon__form input {
  padding: 20px 30px;
  color: var(--color-white);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #171717;
  height: 72px;
  margin-bottom: 0;
  border: 0;
}
.coming-soon__form input::-webkit-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.coming-soon__form input::-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.coming-soon__form input:-ms-input-placeholder {
  color: #4a4c51;
  opacity: 1;
}
.coming-soon__form input:-moz-placeholder {
  color: #4a4c51;
  opacity: 1;
}
@media (max-width: 767px) {
  .coming-soon__form input {
    height: 55px;
    line-height: 1;
  }
}
.coming-soon__form button {
  position: absolute;
  top: 6px;
  right: 0;
  height: 60px;
  padding: 15px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 400;
  font-size: 18px;
  color: var(--color-white);
}
@media (max-width: 767px) {
  .coming-soon__form button {
    position: unset;
    width: 100%;
    margin-top: 10px;
    height: 55px;
    line-height: 1;
  }
}
.coming-soon__social li:not(:last-child) {
  margin-right: 15px;
}
.coming-soon__social li a {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  margin-top: 10px;
}
.coming-soon__social li a:hover {
  border-color: var(--color-white);
  color: var(--color-white);
}

:root {
  scroll-behavior: inherit;
  --font-four: "Catamaran", sans-serif;
  --grad-color: linear-gradient(
    -90deg,
    rgb(168, 115, 250) 0%,
    rgb(112, 61, 241) 100%
  );
}

body.demo-four {
  color: #454545;
  font-size: 18px;
  font-family: var(--font-four);
  line-height: 1.556;
}
body.demo-four a {
  text-decoration: none;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
body.demo-four a:hover,
body.demo-four a:focus {
  text-decoration: none;
}
body.demo-four .container {
  max-width: 1245px;
}
body.demo-four h1,
body.demo-four h2,
body.demo-four h3,
body.demo-four h4,
body.demo-four h5,
body.demo-four h6 {
  margin: 0;
  line-height: 1.2;
  font-family: var(--font-four);
}
body.demo-four .thm-btn__three {
  color: #fff;
  background-image: none;
  background-color: var(--thm-color-4);
}
body.demo-four .cart_item .remove_btn {
  color: var(--thm-color-4);
}

.ul-li li {
  list-style: none;
  display: inline-block;
}

.inv-footer-widget .instagram-feed .insta_tag a,
.inv-footer-widget .recent-blog-item .blog-text .blog-meta,
.inv-footer-widget .widget-title span,
.inv-blog-item .inv-blog-text .inv-blog-meta .author-meta .inner-text a i,
.inv-video-text-list li:before,
.inv-video-play-item .video-play-icon .inner-icon i,
.inv-growth-list-area .inv-growth-list li:before,
.inv-growth-item .growth-icon i,
.inv-success-item .success-text span,
.inv-service-slider-item .inv-service-text span,
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-list
  li:before,
.inv-section-title .subtitle {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgb(168, 115, 250)),
    to(rgb(112, 61, 241))
  );
  background-image: linear-gradient(
    -90deg,
    rgb(168, 115, 250) 0%,
    rgb(112, 61, 241) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

[data-background] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.inv-section-title .subtitle {
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
  padding-left: 18px;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
}
.inv-section-title .subtitle:before {
  top: 0;
  left: 2px;
  width: 7px;
  content: "";
  height: 13px;
  position: absolute;
  background-image: url(../img/new_home/icon/title-badge.html);
}
.inv-section-title h2 {
  color: #000000;
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2;
  padding-bottom: 5px;
}

@-webkit-keyframes btn_shine {
  100% {
    left: 200%;
  }
}
@keyframes btn_shine {
  100% {
    left: 200%;
  }
}
.inv-btn a {
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  padding: 18px 25px 15px;
  background-color: var(--thm-color-4);
}
.inv-btn a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}
.inv-btn a:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.header-style-four .dropdown,
.header-style-five .dropdown {
  position: relative;
}
.header-style-four .dropdown:after,
.header-style-five .dropdown:after {
  top: 4px;
  right: -18px;
  font-size: 14px;
  color: #c3c7c9;
  font-weight: 300;
  content: "\f078";
  font-weight: 400;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.header-style-four .dropdown:hover .dropdown-menu,
.header-style-five .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  clip: inherit;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  transform: scaleY(1);
}
.header-style-four .dropdown:hover:after,
.header-style-five .dropdown:hover:after {
  color: var(--thm-color-4);
}
.header-style-four .dropdown-menu,
.header-style-five .dropdown-menu {
  left: 0;
  top: 62px;
  z-index: 100;
  margin: 0px;
  padding: 20px 0;
  height: auto;
  min-width: 250px;
  display: block;
  border: none;
  border-radius: 0;
  position: absolute;
  opacity: 0;
  background: #fff;
  border-radius: 2px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.header-style-four .dropdown-menu .dropdown-menu,
.header-style-five .dropdown-menu .dropdown-menu {
  top: 0px;
  left: 100%;
  -webkit-transform: scaleY(0) !important;
  transform: scaleY(0) !important;
  opacity: 0 !important;
  transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
}
.header-style-four .dropdown-menu .dropdown-menu a,
.header-style-five .dropdown-menu .dropdown-menu a {
  font-size: 17px;
  font-weight: 700;
  color: #000000;
}
.header-style-four .dropdown-menu .dropdown-menu a:hover,
.header-style-five .dropdown-menu .dropdown-menu a:hover {
  color: var(--thm-color-4);
}
.header-style-four .dropdown-menu li,
.header-style-five .dropdown-menu li {
  display: block;
  margin: 0 !important;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.header-style-four .dropdown-menu li:last-child,
.header-style-five .dropdown-menu li:last-child {
  border-bottom: none;
}
.header-style-four .dropdown-menu li:after,
.header-style-five .dropdown-menu li:after {
  top: 10px;
  color: #000;
  right: 15px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.header-style-four .dropdown-menu li:hover .dropdown-menu,
.header-style-five .dropdown-menu li:hover .dropdown-menu {
  top: 0;
  -webkit-transform: scaleY(1) !important;
  transform: scaleY(1) !important;
  opacity: 1 !important;
}
.header-style-four .dropdown-menu a,
.header-style-five .dropdown-menu a {
  width: 100%;
  display: block;
  color: #000000 !important;
  position: relative;
  padding: 10px 30px !important;
  font-size: 15px !important;
}
.header-style-four .dropdown-menu a:before,
.header-style-four .dropdown-menu a:after,
.header-style-five .dropdown-menu a:before,
.header-style-five .dropdown-menu a:after {
  display: none;
}
.header-style-four .dropdown-menu a:hover,
.header-style-five .dropdown-menu a:hover {
  margin-left: 15px;
  color: var(--thm-color-4) !important;
}
.header-style-four.sticky-on,
.header-style-five.sticky-on {
  top: 0;
  width: 100%;
  z-index: 20;
  position: fixed;
  padding: 0px 15px 15px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  background-color: #000;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}
.header-style-four.sticky-on .tx-header-content:before,
.header-style-five.sticky-on .tx-header-content:before {
  display: none;
}
.header-style-four.sticky-on .tx-header-top,
.header-style-five.sticky-on .tx-header-top {
  display: none !important;
}
.header-style-four.sticky-on .tx-header-menu-navigation-cta-btn,
.header-style-five.sticky-on .tx-header-menu-navigation-cta-btn {
  padding-top: 20px;
  padding-right: 0px;
}
.header-style-four.sticky-on .tx-header-content .brand-logo,
.header-style-five.sticky-on .tx-header-content .brand-logo {
  padding-top: 10px;
}
.header-style-four.sticky-on .mobile_menu_button,
.header-style-five.sticky-on .mobile_menu_button {
  right: 0;
}

.header-style-four {
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 80px;
  position: absolute;
}
.header-style-four .tx-header-top {
  padding-top: 10px;
  max-width: 1215px;
}
.header-style-four .tx-header-top li {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-left: 40px;
}
.header-style-four .tx-header-top li i {
  margin-right: 6px;
}
.header-style-four .tx-header-top .top-social {
  margin-left: 30px;
}
.header-style-four .tx-header-top .top-social a {
  color: #fff;
  font-size: 15px;
  margin-left: 10px;
}
.header-style-four .tx-header-top .top-social a:hover {
  color: var(--thm-color-5);
}
.header-style-four .tx-header-top-navigation {
  width: 86%;
  position: relative;
}
.header-style-four .tx-header-content {
  z-index: 1;
}
.header-style-four .tx-header-content:before {
  top: 0;
  right: 0;
  z-index: -1;
  content: "";
  width: 86%;
  height: 45px;
  position: absolute;
  background: var(--grad-color);
}
.header-style-four .tx-header-content .brand-logo {
  max-width: 205px;
  margin-right: 55px;
}
.header-style-four .tx-main-navigation-area .navbar-nav {
  display: inherit;
}
.header-style-four .tx-main-navigation-area li {
  margin-right: 50px;
}
.header-style-four .tx-main-navigation-area li a {
  color: #fff;
  font-size: 19px;
  font-weight: 700;
  position: relative;
  padding-bottom: 35px;
}
.header-style-four .tx-main-navigation-area li a:before {
  right: 0;
  top: 30px;
  width: 0%;
  left: auto;
  content: "";
  height: 2px;
  position: absolute;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-bottom: 2px dashed var(--thm-color-4);
}
.header-style-four .tx-main-navigation-area li:hover a {
  color: var(--thm-color-4);
}
.header-style-four .tx-main-navigation-area li:hover a:before {
  left: 0;
  right: auto;
  width: 100%;
}
.header-style-four .tx-header-menu-navigation-cta-btn {
  padding-top: 35px;
  padding-right: 130px;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cart-search button {
  padding: 0;
  color: #fff;
  border: none;
  margin-left: 30px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  background-color: transparent;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cart-search button:hover {
  color: var(--thm-color-4);
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta {
  margin-left: 50px;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta .inner-icon {
  color: #fff;
  width: 45px;
  height: 45px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: #000000;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta .inner-text {
  color: #fff;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta .inner-text span {
  display: block;
  font-size: 14px;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn {
  margin-left: 50px;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn a {
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  padding: 18px 25px 15px;
  display: inline-block;
  background: var(--thm-color-4);
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}
.header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn a:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.header-style-five {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
}
.header-style-five.sticky-on {
  padding: 0px 15px;
  background-color: #fff;
}
.header-style-five.sticky-on .tx-header-top-content {
  display: none !important;
}
.header-style-five .tx-header-top-content {
  z-index: 1;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1345px;
  padding: 12px 0px;
}
.header-style-five .tx-header-top-content:before,
.header-style-five .tx-header-top-content:after {
  left: 0;
  right: 0;
  top: -8px;
  z-index: -2;
  width: 100%;
  content: "";
  height: 100px;
  margin: 0 auto;
  border-radius: 80px;
  position: absolute;
  background-color: var(--thm-color-5);
}
.header-style-five .tx-header-top-content:after {
  z-index: -1;
  width: 99%;
  background-color: #1d1b1b;
}
.header-style-five .tx-header-top-area {
  color: #fff;
  font-size: 16px;
}
.header-style-five .tx-header-top-area .top-cta b {
  font-weight: 800;
}
.header-style-five .tx-header-top-area .top-cta span {
  font-weight: 500;
}
.header-style-five .tx-header-top-area .top-cta i {
  margin-right: 8px;
  color: var(--thm-color-5);
}
.header-style-five .tx-header-top-area .top-slug {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.header-style-five .tx-header-top-area .top-slug i {
  font-size: 14px;
  margin-right: 8px;
  color: var(--thm-color-5);
}
.header-style-five .tx-header-top-area .top-cart-search button {
  padding: 0;
  color: #fff;
  border: none;
  margin-left: 30px;
  background-color: transparent;
}
.header-style-five .tx-header-menu-cta {
  background-color: #fff;
  padding: 20px 20px 20px 30px;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
}
.header-style-five .tx-main-navigation-area .nav {
  display: inherit;
}
.header-style-five .tx-main-navigation-area li {
  margin: 0px 25px;
}
.header-style-five .tx-main-navigation-area li a {
  color: #121212;
  font-size: 19px;
  font-weight: 700;
  position: relative;
  padding-bottom: 35px;
}
.header-style-five .tx-main-navigation-area li a:before {
  right: 0;
  top: 30px;
  width: 0%;
  left: auto;
  content: "";
  height: 2px;
  position: absolute;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-bottom: 2px dashed var(--thm-color-5);
}
.header-style-five .tx-main-navigation-area li a:hover {
  color: var(--thm-color-5) !important;
}
.header-style-five .tx-main-navigation-area li:hover a {
  color: var(--thm-color-5);
}
.header-style-five .tx-main-navigation-area li:hover a:before {
  left: 0;
  right: auto;
  width: 100%;
}
.header-style-five .dropdown:hover:after {
  color: var(--thm-color-5);
}
.header-style-five .tx-header-cta-btn a {
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  border-radius: 5px;
  padding: 20px 35px;
  overflow: hidden;
  position: relative;
  border-bottom-right-radius: 50px;
  display: inline-block;
  background-color: var(--thm-color-5);
}
.header-style-five .tx-header-cta-btn a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}
.header-style-five .tx-header-cta-btn a:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.search-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
  background-color: rgba(0, 0, 0, 0.85);
}
.search-body.search-open {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.search-body .search-form {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-body .search-form .search-form-area {
  width: 100%;
  z-index: 9991;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}
.search-body .search-form input {
  width: 100%;
  height: 60px;
  border: none;
  padding: 0px 30px;
  background-color: #fff;
}
.search-body .search-form input::-webkit-input-placeholder {
  color: #000;
  font-weight: 500;
}
.search-body .search-form input::-moz-placeholder {
  color: #000;
  font-weight: 500;
}
.search-body .search-form input:-ms-input-placeholder {
  color: #000;
  font-weight: 500;
}
.search-body .search-form input::-ms-input-placeholder {
  color: #000;
  font-weight: 500;
}
.search-body .search-form input::placeholder {
  color: #000;
  font-weight: 500;
}
.search-body .search-form button {
  top: 0;
  right: 0;
  color: #fff;
  border: none;
  width: 120px;
  height: 60px;
  position: absolute;
  background: var(--thm-color-4);
}
.search-body .outer-close {
  top: 40px;
  right: 55px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  border-radius: 3px;
  position: absolute;
  background-color: var(--thm-color-4);
}
.search-body .outer-close i {
  color: #fff;
}

.search-open {
  opacity: 1;
  visibility: visible;
}

/*Mobile area*/
/*----------------------------------------------------*/
.mobile_logo {
  top: 10px;
  left: 15px;
  display: none;
  position: absolute;
}

.mobile_menu_content {
  top: 0px;
  bottom: 0;
  left: -350px;
  height: 100vh;
  z-index: 101;
  position: fixed;
  width: 310px;
  overflow-y: scroll;
  background-color: #000;
  padding: 20px 35px 35px 35px;
  -webkit-box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
  box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.mobile_menu_content .mobile-main-navigation {
  width: 100%;
}
.mobile_menu_content .mobile-main-navigation .navbar-nav {
  width: 100%;
}
.mobile_menu_content .inv-btn a {
  padding: 15px 20px;
}
.mobile_menu_content .dropdown:after {
  display: none;
}
.mobile_menu_content .navbar-nav .dropdown-menu {
  position: static !important;
  -webkit-transform: none !important;
  transform: none !important;
}
.mobile_menu_content .mobile-main-navigation .navbar-nav li {
  width: 100%;
  display: block;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.mobile_menu_content .mobile-main-navigation .navbar-nav li a {
  padding: 0;
  width: 100%;
  color: #fff;
  display: block;
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  padding: 10px 30px 10px 0;
}
.mobile_menu_content .m-brand-logo {
  width: 180px;
  margin: 50px auto;
}

.mobile_menu_wrap.mobile_menu_on .mobile_menu_content {
  left: 0px;
  -webkit-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}

.mobile_menu_overlay {
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  right: 0%;
  height: 120vh;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.mobile_menu_overlay_on {
  overflow: hidden;
}

.mobile_menu_wrap.mobile_menu_on .mobile_menu_overlay {
  opacity: 1;
  visibility: visible;
}

.mobile_menu_button {
  right: 15px;
  top: -7px;
  z-index: 5;
  color: #fff;
  display: none;
  font-size: 24px;
  cursor: pointer;
  line-height: 38px;
  position: absolute;
  text-align: center;
}

.mobile_menu .mobile-main-navigation {
  margin-bottom: 30px;
}
.mobile_menu .mobile-main-navigation .navbar-nav li a:after {
  display: none;
}
.mobile_menu .mobile-main-navigation .dropdown > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu {
  border: none;
  display: none;
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px 0px;
  width: 100%;
  background-color: transparent;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li {
  border: none;
  padding: 0 10px;
  line-height: 1;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li:hover {
  background-color: transparent;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li
  a {
  color: #fff !important;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li
  a:hover {
  background-color: transparent;
}
.mobile_menu .dropdown {
  position: relative;
}
.mobile_menu .dropdown .dropdown-btn {
  top: 3px;
  right: 0;
  height: 30px;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
.mobile_menu .dropdown .dropdown-btn.toggle-open {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mobile_menu .mobile_menu_close {
  top: 25px;
  right: 25px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
}

.inv-get-quote-section {
  z-index: 1;
  top: -35px;
  overflow: hidden;
}
.inv-get-quote-section .inv-get-quote-bg {
  top: 0;
  right: -10px;
  z-index: -1;
}

.inv-get-quote-content {
  padding-top: 45px;
  padding-left: 70px;
  padding-bottom: 50px;
}

.inv-get-quote-title h2 {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
}
.inv-get-quote-title p {
  color: #fff;
  font-size: 18px;
}

.inv-form-input-area input {
  height: 60px;
  max-width: 265px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.inv-form-input-area button {
  z-index: 1;
  color: #fff;
  border: none;
  height: 60px;
  font-size: 14px;
  line-height: 1;
  margin-left: 5px;
  font-weight: 700;
  overflow: hidden;
  padding: 25px 45px;
  position: relative;
  border-radius: 4px;
  text-transform: uppercase;
  background: var(--thm-color-4);
}
.inv-form-input-area button:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}
.inv-form-input-area button:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.inv-about-section {
  padding: 80px 0px 170px;
}

.inv-about-img-wrapper .inv-about-img2 {
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.13);
  box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.13);
}
.inv-about-img-wrapper .inv-about-experience {
  top: 50px;
  z-index: 1;
  right: 70px;
  padding: 25px;
  border-radius: 5px;
  background: var(--grad-color);
}
.inv-about-img-wrapper .inv-about-experience h3 {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}
.inv-about-img-wrapper .inv-about-experience p {
  color: #fff;
  line-height: 1.4;
  font-size: 24px;
  max-width: 130px;
  font-weight: 700;
}
.inv-about-img-wrapper .inv-about-counter-area {
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -60px;
  max-width: 290px;
  padding: 15px 25px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.13);
  box-shadow: 0px 0px 90px 0px rgba(19, 19, 19, 0.13);
}
.inv-about-img-wrapper .inv-about-counter-area .inv-about-counter-img {
  width: 95px;
  height: 105px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 5px;
}
.inv-about-img-wrapper .inv-about-counter-area .inv-about-counter-text h3 {
  color: #000000;
  line-height: 1;
  font-size: 45px;
  font-weight: 700;
}
.inv-about-img-wrapper .inv-about-counter-area .inv-about-counter-text p {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.inv-about-text-area {
  padding-left: 50px;
  padding-top: 40px;
}

.inv-about-tab-area {
  margin-top: 38px;
}

.inv-about-tab-btn {
  padding-bottom: 8px;
  margin-bottom: 30px;
  border-bottom: 4px solid #f0f0f0;
}
.inv-about-tab-btn .nav-tabs .nav-link {
  margin: 0;
  padding: 0;
  border: none;
  color: #000000;
  font-size: 19px;
  font-weight: 800;
  border-radius: 0;
  position: relative;
}
.inv-about-tab-btn .nav-tabs .nav-link:before {
  left: 0;
  right: 0;
  width: 0%;
  content: "";
  height: 4px;
  bottom: -12px;
  margin: 0 auto;
  position: absolute;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: var(--thm-color-4);
}
.inv-about-tab-btn .nav-tabs {
  border: none;
}
.inv-about-tab-btn li {
  margin-right: 40px;
}
.inv-about-tab-btn li:last-child {
  margin-right: 0;
}
.inv-about-tab-btn .nav-tabs .nav-link.active,
.inv-about-tab-btn .nav-tabs .nav-link:hover {
  color: var(--thm-color-4);
  background-color: transparent;
}
.inv-about-tab-btn .nav-tabs .nav-link.active:before,
.inv-about-tab-btn .nav-tabs .nav-link:hover:before {
  width: 100%;
}

.inv-about-tab-content-area .inv-about-tab-content .about-tab-icon {
  margin-right: 45px;
}
.inv-about-tab-content-area .inv-about-tab-content .about-tab-icon i {
  color: #000000;
  line-height: 0.75;
  font-size: 70px;
}
.inv-about-tab-content-area .inv-about-tab-content .about-tab-text {
  max-width: 370px;
}
.inv-about-tab-content-area .inv-about-tab-cta {
  margin-top: 45px;
}
.inv-about-tab-content-area .inv-about-tab-cta .inv-cta-info {
  margin-left: 45px;
}
.inv-about-tab-content-area .inv-about-tab-cta .inv-cta-info .inner-icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 100%;
  border: 2px solid #f5f3f3;
  color: var(--thm-color-4);
}
.inv-about-tab-content-area .inv-about-tab-cta .inv-cta-info .inner-text span {
  display: block;
}
.inv-about-tab-content-area .inv-about-tab-cta .inv-cta-info .inner-text b {
  color: #000000;
}

.inv-pricing-content {
  z-index: 2;
  top: -95px;
  padding: 70px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: -95px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 68px 0px rgba(19, 19, 19, 0.11);
  box-shadow: 0px 0px 68px 0px rgba(19, 19, 19, 0.11);
}
.inv-pricing-content .inv-price-shape.shape_1 {
  top: -230px;
  right: -150px;
}
.inv-pricing-content .inv-price-shape.shape_2 {
  left: -150px;
  bottom: -230px;
}

.inv-pricing-tab-btn .nav-tabs .nav-link {
  padding: 0;
  border: none;
  color: #000000;
  font-size: 19px;
  font-weight: 800;
  padding-left: 28px;
  position: relative;
}
.inv-pricing-tab-btn .nav-tabs .nav-link:before {
  top: 3px;
  left: 0;
  width: 20px;
  content: "";
  height: 20px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid #d9d6d6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-pricing-tab-btn .nav-tabs .nav-link:after {
  top: 9px;
  left: 6px;
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-pricing-tab-btn .nav-tabs .nav-link span {
  top: -3px;
  color: #1a1e66;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 15px;
  margin-left: 10px;
  position: relative;
  background-color: #e3e6fb;
}
.inv-pricing-tab-btn li {
  margin-right: 15px;
}
.inv-pricing-tab-btn li:last-child {
  margin-right: 0;
}
.inv-pricing-tab-btn .nav-tabs {
  border: none;
}
.inv-pricing-tab-btn .nav-tabs .nav-link.active:before,
.inv-pricing-tab-btn .nav-tabs .nav-link:hover:before {
  border: 2px solid var(--thm-color-4);
}
.inv-pricing-tab-btn .nav-tabs .nav-link.active:after,
.inv-pricing-tab-btn .nav-tabs .nav-link:hover:after {
  background-color: var(--thm-color-4);
}

.inv-pricing-price-text {
  width: 100%;
  max-width: 620px;
  margin-top: 25px;
}
.inv-pricing-price-text h2 {
  color: #000000;
  font-size: 52px;
  font-weight: 800;
  max-width: 500px;
  padding-bottom: 25px;
}
.inv-pricing-price-text .inv-pricing-text-price .price-text-btn {
  max-width: 330px;
}
.inv-pricing-price-text .inv-pricing-text-price .price-text-btn p {
  padding-bottom: 30px;
}
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-value {
  color: #000000;
  line-height: 1;
  font-size: 57px;
  font-weight: 800;
  margin-bottom: 15px;
}
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-value
  sup {
  top: -18px;
  font-size: 23px;
}
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-value
  sub {
  font-weight: 400;
  font-size: 22px;
}
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-list
  li {
  color: #161616;
  font-size: 19px;
  font-weight: 500;
  padding-left: 23px;
  position: relative;
  margin-bottom: 5px;
}
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-list
  li:last-child {
  margin-bottom: 0;
}
.inv-pricing-price-text
  .inv-pricing-text-price
  .pricing-list-price
  .price-list
  li:before {
  left: 0;
  top: 1px;
  font-size: 17px;
  content: "\f0a9";
  font-weight: 900;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}

.inv-pricing-img-area {
  padding-left: 80px;
}
.inv-pricing-img-area img {
  border-radius: 10px;
}
.inv-pricing-img-area .price-icon-shape {
  left: 10px;
  bottom: 25px;
  width: 130px;
  height: 130px;
  border-radius: 10px;
  background: var(--grad-color);
}
.inv-pricing-img-area .price-icon-shape i {
  color: #fff;
  font-size: 75px;
  line-height: 0.3;
}

.inv-service-section {
  z-index: 1;
  overflow: hidden;
  padding: 115px 0px 190px;
}
.inv-service-section .inv-section-title .subtitle {
  color: var(--thm-color-4);
}
.inv-service-section .inv-section-title h2 {
  color: #fff;
}
.inv-service-section .inv-side-shape.shape_3 {
  left: 0;
  z-index: -1;
  bottom: -90px;
}
.inv-service-section .inv-side-shape.shape_2 {
  left: 0;
  z-index: -1;
  bottom: -130px;
}
.inv-service-section .inv-side-shape.shape_1 {
  left: 0;
  z-index: -1;
  bottom: -125px;
}

.inv-service-slider-area {
  padding-top: 35px;
}

.inv-service-slider-item {
  z-index: 1;
  margin: 0 auto;
  max-width: 385px;
}
.inv-service-slider-item:before {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: "";
  height: 85%;
  border-radius: 8px;
  position: absolute;
  background-color: #1f242c;
}
.inv-service-slider-item .inv-service-img {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 15px;
  display: inline-block;
}
.inv-service-slider-item .inv-service-img img {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-service-slider-item .inv-service-text {
  padding-bottom: 22px;
}
.inv-service-slider-item .inv-service-text h3 a {
  color: #fff;
  font-size: 26px;
  font-weight: 800;
}
.inv-service-slider-item .inv-service-text h3 a:hover {
  letter-spacing: 1px;
}
.inv-service-slider-item .inv-service-text span {
  display: inline-block;
}
.inv-service-slider-item:hover .inv-service-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.inv-carousel-arrow-next-prev .inv-carousel-arrow {
  top: 50%;
  width: 58px;
  height: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-carousel-arrow-next-prev .inv-carousel-arrow:hover {
  color: #fff;
  background-color: #007aff;
}
.inv-carousel-arrow-next-prev .inv-service-arrow-prev {
  left: -70px;
}
.inv-carousel-arrow-next-prev .inv-service-arrow-next {
  right: -70px;
}

.inv-service-bottom-text-btn {
  color: white;
  margin-top: 45px;
}
.inv-service-bottom-text-btn p {
  font-size: 20px;
  padding-bottom: 5px;
}
.inv-service-bottom-text-btn p a {
  color: #fff;
  text-decoration: underline;
}
.inv-service-bottom-text-btn h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 25px;
}

.inv-success-section {
  overflow: hidden;
  padding: 120px 0px;
}
.inv-success-section .inv-section-title {
  margin: 0 auto;
  max-width: 450px;
}

.inv-success-content {
  padding-top: 30px;
}

.inv-success-item-tab .filtr-container-area {
  margin: 0 -15px;
}
.inv-success-item-tab .grid-size-50 {
  width: 50%;
}
.inv-success-item-tab .grid-sizer {
  width: 50%;
}
.inv-success-item-tab .grid-item {
  float: left;
  padding: 15px;
  margin-bottom: 20px;
  display: inline-block;
}

.inv-success-item {
  margin: 0 auto;
  max-width: 585px;
  position: relative;
  padding-bottom: 25px;
}
.inv-success-item .success-img {
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
}
.inv-success-item .success-img img {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-success-item .success-img:before {
  left: 0;
  right: 0;
  bottom: 0;
  width: 0%;
  z-index: 1;
  content: "";
  height: 10px;
  margin: 0 auto;
  position: absolute;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background: var(--grad-color);
}
.inv-success-item .success-text {
  bottom: 0;
  left: 65px;
  z-index: 2;
  border-radius: 5px;
  padding: 26px 50px 26px 30px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 3px 57px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 3px 57px 0px rgba(0, 0, 0, 0.06);
}
.inv-success-item .success-text span {
  font-size: 15px;
  display: inline-block;
  padding-bottom: 5px;
}
.inv-success-item .success-text h3 a {
  color: #000000;
  font-size: 24px;
  font-weight: 800;
}
.inv-success-item .success-text h3 a:hover {
  color: var(--thm-color-4);
}
.inv-success-item .success-text .link-arrow a {
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100px;
  right: -75px;
  visibility: hidden;
  border-radius: 5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  background: var(--grad-color);
}
.inv-success-item:hover .success-img:before {
  width: 100%;
}
.inv-success-item:hover .success-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.inv-success-item:hover .success-text .link-arrow a {
  opacity: 1;
  right: -95px;
  visibility: visible;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.inv-growth-section {
  overflow: hidden;
  padding: 155px 0px 175px;
}
.inv-growth-section .inv-growth-shape.shape_1 {
  right: 0;
  bottom: -110px;
}
.inv-growth-section .inv-growth-shape.shape_2 {
  right: 0;
  bottom: -115px;
}
.inv-growth-section .inv-growth-shape.shape_3 {
  right: 0;
  bottom: -85px;
}

.inv-growth-item {
  z-index: 1;
  padding: 45px;
  max-width: 220px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 40px;
  background-color: #131822;
}
.inv-growth-item:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  content: "";
  height: 100%;
  border-radius: 5px;
  position: absolute;
  background: var(--grad-color);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  -webkit-transform: perspective(300px) rotateX(-90deg);
  transform: perspective(300px) rotateX(-90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.inv-growth-item .growth-icon {
  margin-bottom: 15px;
}
.inv-growth-item .growth-icon i {
  line-height: 0.5;
  font-size: 65px;
}
.inv-growth-item .growth-text {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.2;
}
.inv-growth-item:hover .growth-icon i {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #fff;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.inv-growth-item:hover:before {
  margin-top: 0;
  -webkit-transform: perspective(300px) rotateX(0);
  transform: perspective(300px) rotateX(0);
}

.inv-growth-text-area {
  max-width: 600px;
}
.inv-growth-text-area .inv-section-title h2 {
  color: #fff;
}
.inv-growth-text-area .inv-section-title p {
  color: #9e9e9e;
}

.inv-growth-list-area {
  margin-top: 30px;
}
.inv-growth-list-area .inv-growth-list {
  padding-left: 10px;
}
.inv-growth-list-area .inv-growth-list li {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  padding-left: 30px;
  position: relative;
  margin-bottom: 5px;
}
.inv-growth-list-area .inv-growth-list li:before {
  left: 0;
  top: 1px;
  font-size: 17px;
  content: "\f0a9";
  font-weight: 900;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.inv-growth-list-area .inv-growth-list .inv-btn {
  margin-top: 20px;
}

.inv-team-section {
  padding: 120px 0px;
}
.inv-team-section .inv-section-title {
  margin: 0 auto;
  max-width: 610px;
}
.inv-team-section .inv-team-shape.shape_1 {
  top: 0;
  left: 0;
}
.inv-team-section .inv-team-shape.shape_2 {
  right: 0;
  bottom: 0;
}

.inv-team-content {
  margin-top: 25px;
}

.inv-team-member-item {
  z-index: 1;
  max-width: 585px;
  padding: 0px 50px 40px 70px;
}
.inv-team-member-item:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 85%;
  z-index: -1;
  content: "";
  position: absolute;
  border-radius: 5px;
  background-color: #f0f4fd;
}
.inv-team-member-item .inv-team-img {
  z-index: 1;
  margin-bottom: 28px;
  display: inline-block;
}
.inv-team-member-item .inv-team-img:before {
  width: 70%;
  height: 10px;
  content: "";
  z-index: -1;
  bottom: -8px;
  border-radius: 3px;
  position: absolute;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: var(--grad-color);
}
.inv-team-member-item .inv-team-img img {
  border-radius: 5px;
}
.inv-team-member-item .inv-team-social .social-list {
  margin-bottom: 20px;
}
.inv-team-member-item .inv-team-social .social-list a {
  color: #fff;
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.inv-team-member-item .inv-team-social .social-list a.s-fb {
  background-color: #186cb0;
}
.inv-team-member-item .inv-team-social .social-list a.s-tw {
  background-color: #00b3d3;
}
.inv-team-member-item .inv-team-social .social-list a.s-bh {
  background-color: #074682;
}
.inv-team-member-item .team-contact-info {
  margin-bottom: 30px;
}
.inv-team-member-item .team-contact-info h3 a {
  color: #183153;
  font-size: 30px;
  font-weight: 800;
}
.inv-team-member-item .team-contact-info span a {
  color: #183153;
  font-size: 17px;
  font-weight: 800;
  position: relative;
  display: inline-block;
}
.inv-team-member-item .team-contact-info span a:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #9da5b5;
}
.inv-team-member-item .inv-team-name-text span {
  padding-bottom: 3px;
  display: inline-block;
  color: var(--thm-color-4);
}
.inv-team-member-item .inv-team-name-text h3 {
  padding-bottom: 10px;
}
.inv-team-member-item .inv-team-name-text h3 a {
  color: #000000;
  font-size: 31px;
  font-weight: 800;
}

.inv-team-content .inv-carousel-arrow {
  top: 58%;
  background-color: #f0f4fd;
}
.inv-team-content .inv-team-arrow-prev {
  left: -100px;
}
.inv-team-content .inv-team-arrow-next {
  right: -100px;
}

.inv-testimonial {
  width: 45%;
  padding: 75px 100px 0px 75px;
}

.inv-testimonial-item .quote-icon {
  margin-bottom: 18px;
}
.inv-testimonial-item .inv-testimonial-text {
  color: #c7c5db;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 15px;
}
.inv-testimonial-item .inv-testimonial-author .author-rate {
  margin-bottom: 5px;
}
.inv-testimonial-item .inv-testimonial-author .author-rate li {
  color: #c3b802;
  font-size: 15px;
}
.inv-testimonial-item .inv-testimonial-author .author-name .name {
  color: #fff;
  font-size: 26px;
  font-weight: 800;
}
.inv-testimonial-item .inv-testimonial-author .author-name .designation {
  color: #535ee0;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
}

.inv-testimonial-thumb-area {
  width: 160px;
  bottom: -70px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  margin-right: 55px;
}

.inv-testimonial-slide-for-area {
  max-width: 465px;
  margin-top: 90px;
}

.inv-faq-content {
  width: 55%;
  padding: 120px 0px 120px 120px;
}

.inv-faq-accordion {
  max-width: 570px;
}
.inv-faq-accordion .accordion-header button {
  border: none;
  color: #061d25;
  font-size: 20px;
  font-weight: 800;
  position: relative;
  border-radius: 3px;
  background: transparent;
  padding: 25px 30px 25px 85px;
}
.inv-faq-accordion .accordion-button:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.inv-faq-accordion .accordion-item {
  border: none;
  border-radius: 0;
  position: relative;
  margin-bottom: 25px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(6, 29, 37, 0.1019607843);
  box-shadow: 0px 10px 30px 0px rgba(6, 29, 37, 0.1019607843);
}
.inv-faq-accordion .accordion-item .inv-faq-serial {
  top: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  width: 52px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  background: #868686;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.inv-faq-accordion .accordion-item .inv-faq-serial:before {
  top: 0;
  left: 0;
  z-index: -1;
  height: 0%;
  width: 100%;
  content: "";
  position: absolute;
  background: var(--grad-color);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-faq-accordion .accordion-item.faq_bg .inv-faq-serial:before {
  height: 100%;
}
.inv-faq-accordion .accordion-body {
  color: #666666;
  position: relative;
  background-color: #fff;
  padding: 10px 30px 30px 85px;
}
.inv-faq-accordion .accordion-body:before {
  top: 40%;
  left: 30px;
  height: 80%;
  content: "";
  width: 2px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--base-color);
}
.inv-faq-accordion .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.inv-faq-accordion .accordion-button::after {
  right: 25px;
  height: auto;
  width: auto;
  font-size: 20px;
  content: "\f055";
  font-weight: 300;
  position: absolute;
  background-image: none;
  font-family: "Font Awesome 5 Pro";
}
.inv-faq-accordion .accordion-button:not(.collapsed)::after {
  content: "\f056";
  color: var(--base-color);
}

.inv-video-section {
  padding: 140px 0px 120px;
}

.inv-video-content {
  z-index: 1;
}
.inv-video-content:before {
  right: 0;
  top: 50%;
  width: 60%;
  z-index: -1;
  height: 85%;
  content: "";
  position: absolute;
  background-size: cover;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-image: url(../img/bg/video-bg.jpg);
}

.inv-video-play-item {
  max-width: 670px;
  margin-bottom: 15px;
}
.inv-video-play-item .video-play-img {
  overflow: hidden;
  border-radius: 50px;
}
.inv-video-play-item .video-play-icon {
  left: 45px;
  bottom: 30px;
}
.inv-video-play-item .video-play-icon .inner-icon {
  margin-right: 15px;
}
.inv-video-play-item .video-play-icon .inner-icon i {
  font-size: 75px;
}
.inv-video-play-item .video-play-icon .inner-text span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.inv-video-play-item .video-play-icon .inner-text h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.inv-video-text-area {
  max-width: 460px;
}

.inv-video-text-list {
  margin-top: 35px;
}
.inv-video-text-list li {
  color: #000000;
  font-size: 19px;
  font-weight: 500;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
}
.inv-video-text-list li:before {
  left: 0;
  top: 1px;
  font-size: 17px;
  content: "\f0a9";
  font-weight: 900;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.inv-video-text-list .inv-btn {
  margin-top: 30px;
}

.inv-sponsor-section {
  padding-bottom: 60px;
}

.inv-sponsor-content {
  padding-top: 50px;
}
.inv-sponsor-content .inv-sponsor-item {
  width: 25%;
  height: 100px;
  margin-bottom: 50px;
}

.inv-blog-section {
  padding-bottom: 110px;
}

.inv-blog-content {
  padding-top: 40px;
}

.inv-blog-item {
  margin: 0 auto;
  max-width: 386px;
  margin-bottom: 30px;
}
.inv-blog-item .inv-blog-img {
  overflow: hidden;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.inv-blog-item .inv-blog-img img {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-blog-item .inv-blog-text {
  padding: 25px 35px 30px;
  background-color: #fbfbfb;
}
.inv-blog-item .inv-blog-text h3 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border: 1px solid #f2f2f2;
}
.inv-blog-item .inv-blog-text h3 a {
  color: #333333;
  font-size: 24px;
  font-weight: 700;
}
.inv-blog-item .inv-blog-text h3 a:hover {
  color: var(--thm-color-4);
}
.inv-blog-item .inv-blog-text .inv-blog-meta .author-meta .inner-img {
  width: 33px;
  height: 33px;
  overflow: hidden;
  margin-right: 8px;
  border-radius: 100%;
}
.inv-blog-item .inv-blog-text .inv-blog-meta .author-meta .inner-text a {
  color: #7c7c7c;
  font-size: 15px;
  font-weight: 700;
  margin-right: 25px;
}
.inv-blog-item .inv-blog-text .inv-blog-meta .author-meta .inner-text a i {
  margin-right: 8px;
}
.inv-blog-item:hover .inv-blog-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.inv-footer-cta-section {
  z-index: 1;
  padding: 105px 0px;
}
.inv-footer-cta-section .inv-footer-cta-img {
  margin-bottom: 25px;
}
.inv-footer-cta-section .inv-footer-cta-shape.shape_1 {
  left: 0;
  bottom: 0;
  z-index: -1;
}
.inv-footer-cta-section .inv-footer-cta-shape.shape_2 {
  top: 0;
  right: 0;
  z-index: -1;
}

.inv-footer-content-area {
  z-index: 1;
  border-bottom: 1px solid #21232d;
}
.inv-footer-content-area .inv-footer-shape {
  right: 0;
  bottom: 0;
  z-index: -1;
}

.inv-footer-cta-content {
  padding: 0px 20px;
}

.inv-footer-content {
  padding: 110px 0px 50px;
}

.inv-footer-copyright {
  padding: 25px 0px;
}
.inv-footer-copyright p {
  color: #ebebeb;
  font-size: 15px;
  font-weight: 600;
}
.inv-footer-copyright p a {
  color: var(--thm-color-4);
}

.inv-footer-widget .widget-title {
  padding-bottom: 40px;
}
.inv-footer-widget .widget-title span {
  font-size: 14px;
  padding-bottom: 5px;
  display: inline-block;
}
.inv-footer-widget .widget-title h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
}
.inv-footer-widget .brand-logo {
  margin-bottom: 25px;
}
.inv-footer-widget .logo-widget p {
  color: #bbbbbb;
  padding-bottom: 30px;
}
.inv-footer-widget .logo-widget .logo-cta-info .cta-info {
  margin-bottom: 15px;
}
.inv-footer-widget .logo-widget .logo-cta-info .cta-info .inner-icon {
  width: 35px;
  height: 35px;
  margin-right: 12px;
  border-radius: 100%;
  background: var(--grad-color);
}
.inv-footer-widget .logo-widget .logo-cta-info .cta-info .inner-icon i {
  color: #fff;
  font-size: 13px;
}
.inv-footer-widget .logo-widget .logo-cta-info .cta-info .inner-text {
  color: #bbbbbb;
  font-size: 16px;
  font-weight: 700;
}
.inv-footer-widget .recent-blog-item {
  margin-bottom: 20px;
}
.inv-footer-widget .recent-blog-item .blog-img {
  width: 85px;
  height: 75px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 10px;
}
.inv-footer-widget .recent-blog-item .blog-text {
  max-width: 165px;
}
.inv-footer-widget .recent-blog-item .blog-text .blog-meta {
  font-size: 12px;
  font-weight: 700;
  position: relative;
}
.inv-footer-widget .recent-blog-item .blog-text .blog-meta:before {
  top: 10px;
  left: -42px;
  height: 1px;
  width: 34px;
  content: "";
  position: absolute;
  background: var(--grad-color);
}
.inv-footer-widget .recent-blog-item .blog-text .blog-title h3 a {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
.inv-footer-widget .menu-widget {
  padding-left: 10px;
}
.inv-footer-widget .menu-widget li {
  margin-bottom: 20px;
}
.inv-footer-widget .menu-widget a {
  color: #bbbbbb;
  font-weight: 700;
  padding-left: 15px;
  position: relative;
}
.inv-footer-widget .menu-widget a:before {
  left: 0;
  top: 5px;
  font-size: 12px;
  content: "\f061";
  font-weight: 900;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.inv-footer-widget .menu-widget a:hover {
  color: var(--thm-color-4);
}
.inv-footer-widget .instagram-feed ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.inv-footer-widget .instagram-feed ul li {
  width: 33.33%;
}
.inv-footer-widget .instagram-feed .insta_tag {
  margin-top: 12px;
  display: inline-block;
}
.inv-footer-widget .instagram-feed .insta_tag a {
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}
.inv-footer-widget .instagram-feed .insta_tag a i {
  font-size: 14px;
}

body.demo-five {
  color: #2c2c2c;
  font-size: 18px;
  font-family: var(--font-four);
  line-height: 1.364;
}
body.demo-five a {
  text-decoration: none;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
body.demo-five a:hover,
body.demo-five a:focus {
  text-decoration: none;
}
body.demo-five .container {
  max-width: 1275px;
}
body.demo-five h1,
body.demo-five h2,
body.demo-five h3,
body.demo-five h4,
body.demo-five h5,
body.demo-five h6 {
  margin: 0;
  line-height: 1.2;
  font-family: var(--font-four);
}
body.demo-five .search-body .outer-close,
body.demo-five .search-body .search-form button {
  background-color: var(--thm-color-5);
}
body.demo-five .cart_item .remove_btn {
  color: var(--thm-color-5);
}
body.demo-five .thm-btn__three {
  color: #fff;
  background-image: none;
  background-color: var(--thm-color-5);
}

.inv-section-title-2 .subtitle {
  font-size: 13px;
  font-weight: 600;
  padding: 10px 25px;
  margin-bottom: 10px;
  border-radius: 30px;
  display: inline-block;
  color: var(--thm-color-5);
  background-color: #181818;
}
.inv-section-title-2 h2 {
  color: #fff;
  font-size: 65px;
  font-weight: 800;
}
.inv-section-title-2 p {
  color: #d1d1d1;
  font-size: 22px;
}

.inv-btn-2 a {
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  padding: 20px 30px 18px;
  background-color: var(--thm-color-5);
}
.inv-btn-2 a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}
.inv-btn-2 a:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@-webkit-keyframes border_animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1.05);
    transform: scale(1.24);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0;
  }
}
@keyframes border_animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0;
  }
}
.inv-slider-section-2 {
  overflow: hidden;
}
.inv-slider-section-2 .inv-sldier-mail {
  top: 40%;
  z-index: 2;
  font-weight: 500;
  padding: 30px 13px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: var(--thm-color-5);
}
.inv-slider-section-2 .inv-sldier-mail a {
  color: #fff;
}
.inv-slider-section-2 .inv-sldier-side-social {
  top: 45%;
  z-index: 2;
  right: 50px;
  position: absolute;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.inv-slider-section-2 .inv-sldier-side-social li {
  display: inline-block;
}
.inv-slider-section-2 .inv-sldier-side-social li a {
  color: #fff;
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 50px;
  display: inline-block;
}
.inv-slider-section-2 .inv-sldier-side-social li:last-child a {
  margin-bottom: 0;
}

.inv-slider-item-2 .inv-slider-img-text-area {
  padding: 325px 0px 135px;
}
.inv-slider-item-2 .inv-slider-img-text-area .inv-slider-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.inv-slider-item-2 .inv-slider-img-text-area .inv-slider-text {
  max-width: 615px;
}
.inv-slider-item-2 .inv-slider-img-text-area .inv-slider-text h1 {
  color: #fff;
  font-size: 80px;
  font-weight: 800;
  padding-bottom: 5px;
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}
.inv-slider-item-2 .inv-slider-img-text-area .inv-slider-text p {
  opacity: 0;
  color: #fff;
  font-size: 22px;
  padding-bottom: 48px;
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}
.inv-slider-item-2 .inv-slider-img-text-area .inv-slider-text .inv-btn-2 {
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}
.inv-slider-item-2 .inv-slider-img-text-area .inv-slider-video {
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-icon {
  width: 70px;
  height: 70px;
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-icon
  i {
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin-right: 25px;
  border-radius: 100%;
  background-color: #fff;
  color: #000000;
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-icon
  .video_btn_border {
  top: 10px;
  left: -2px;
  opacity: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  border: 5px solid rgba(255, 255, 255, 0.32);
  -webkit-animation: border_animation 3.9s linear 0s infinite;
  animation: border_animation 3.9s linear 0s infinite;
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-icon
  .video_btn_border.border_wrap-2 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-icon
  .video_btn_border.border_wrap-3 {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-text
  h3 {
  color: #fff;
  font-size: 25px;
  font-weight: 800;
}
.inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video
  .slide-video-text
  span {
  font-size: 18px;
  font-weight: 300;
  color: var(--thm-color-5);
}

.swiper-slide-active
  .inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transition: all 7000ms linear;
  transition: all 7000ms linear;
}
.swiper-slide-active
  .inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-text
  h1 {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
}
.swiper-slide-active
  .inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-text
  p {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 700ms;
  transition-delay: 700ms;
}
.swiper-slide-active
  .inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-text
  .inv-btn-2 {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 900ms;
  transition-delay: 900ms;
}
.swiper-slide-active
  .inv-slider-item-2
  .inv-slider-img-text-area
  .inv-slider-video {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1100ms;
  transition-delay: 1100ms;
}

.inv-slider-bottom {
  z-index: 1;
  padding: 25px 0px;
  background-color: #191825;
}
.inv-slider-bottom:before {
  bottom: 0;
  z-index: -1;
  width: 45.5%;
  content: "";
  height: 108px;
  position: absolute;
  background-color: #faece9;
}
.inv-slider-bottom .slider-counter-item {
  font-weight: 800;
  position: relative;
}
.inv-slider-bottom .slider-counter-item:before {
  top: 0;
  right: -50px;
  width: 1px;
  height: 100%;
  content: "";
  position: absolute;
  background-color: #fccec4;
}
.inv-slider-bottom .slider-counter-item:last-child:before {
  display: none;
}
.inv-slider-bottom .slider-counter-item h3 {
  color: #121211;
  font-size: 45px;
  margin-right: 10px;
}
.inv-slider-bottom .slider-counter-item p {
  color: #121211;
  font-size: 17px;
}
.inv-slider-bottom .inv-slider-bottom-slug-arrow {
  padding-left: 50px;
}
.inv-slider-bottom .inv-slider-bottom-slug-arrow .inv-slider-bottom-slug {
  max-width: 440px;
}
.inv-slider-bottom
  .inv-slider-bottom-slug-arrow
  .inv-slider-bottom-slug
  .slug-img {
  width: 46px;
  height: 48px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 8px;
}
.inv-slider-bottom
  .inv-slider-bottom-slug-arrow
  .inv-slider-bottom-slug
  .slug-text {
  color: #fff;
  font-size: 19px;
  max-width: 375px;
  font-weight: 500;
}
.inv-slider-bottom
  .inv-slider-bottom-slug-arrow
  .inv-carousel-arrow-next-prev
  .inv-carousel-arrow {
  width: 42px;
  height: 42px;
  position: static;
  margin-left: 5px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.inv-slider-bottom
  .inv-slider-bottom-slug-arrow
  .inv-carousel-arrow-next-prev
  .inv-carousel-arrow:hover {
  color: #fff;
  background-color: var(--thm-color-5);
}

.inv-sponsor-section-2 {
  padding: 90px 0px;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.inv-about-section-2 {
  padding: 120px 0px 160px;
}

.inv-about-img-wrapper-2 .inv-about-img_2_1 {
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
}
.inv-about-img-wrapper-2 .inv-about-img_2_2 {
  right: 0;
  bottom: -130px;
  overflow: hidden;
  border-radius: 5px;
}
.inv-about-img-wrapper-2 .inv-about-play {
  left: 38px;
  bottom: -62px;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 5000ms;
  animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.inv-about-text-content-2 {
  padding-left: 30px;
}

.inv-about-feature-icon {
  margin-right: 30px;
}

.inv-about-feature-2 {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #262626;
}

.inv-about-feature-list-icon {
  margin-bottom: 60px;
}

.inv-about-feature-list li {
  color: #fff;
  font-weight: 500;
  padding-left: 25px;
  position: relative;
  margin-bottom: 12px;
}
.inv-about-feature-list li:before {
  top: 0;
  left: 0;
  content: "\f35a";
  font-weight: 900;
  position: absolute;
  color: var(--thm-color-5);
  font-family: "Font Awesome 5 Pro";
}

@-webkit-keyframes y-axis-animation {
  from {
    -webkit-transform: rotateY(45deg);
    transform: rotateY(45deg);
  }
  to {
    -webkit-transform: rotateY(-45deg);
    transform: rotateY(-45deg);
  }
}

@keyframes y-axis-animation {
  from {
    -webkit-transform: rotateY(45deg);
    transform: rotateY(45deg);
  }
  to {
    -webkit-transform: rotateY(-45deg);
    transform: rotateY(-45deg);
  }
}
.inv-service-section-2 {
  overflow: hidden;
  padding: 135px 0px 170px;
}
.inv-service-section-2 .inv-section-title-2 {
  margin: 0 auto;
  max-width: 790px;
}
.inv-service-section-2 .inv-section-title-2 h2 {
  font-size: 55px;
}

.inv-service-content-2 {
  padding-top: 80px;
}

.inv-service-tab-btn-area li {
  margin-bottom: 40px;
}
.inv-service-tab-btn-area .nav {
  display: inherit;
}
.inv-service-tab-btn-area .nav-tabs {
  border: none;
}
.inv-service-tab-btn-area .nav-tabs .nav-item.show .nav-link,
.inv-service-tab-btn-area .nav-tabs .nav-link.active,
.inv-service-tab-btn-area .nav-tabs .nav-link {
  padding: 0;
  border: none;
  background-color: transparent;
}
.inv-service-tab-btn-area .service-item-img-text-area {
  border-bottom: 1px solid #353535;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-item-img-text
  .inner-img {
  top: 0;
  left: 0;
  opacity: 0;
  width: 56px;
  height: 56px;
  overflow: hidden;
  margin-right: 20px;
  position: absolute;
  border-radius: 8px;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-item-img-text
  .inner-text {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-item-img-text
  .inner-text
  h3 {
  padding-bottom: 5px;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-item-img-text
  .inner-text
  h3
  a {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-item-img-text
  .inner-text
  span {
  color: #878787;
  font-size: 20px;
}
.inv-service-tab-btn-area .service-item-img-text-area .service-more {
  top: -5px;
  right: 0;
}
.inv-service-tab-btn-area .service-item-img-text-area .service-more a {
  color: #fff;
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #232323;
}
.inv-service-tab-btn-area .service-item-img-text-area .service-more a i {
  font-size: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.inv-service-tab-btn-area .service-item-img-text-area .service-btn-progress {
  margin-top: 30px;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-btn-progress
  .progress {
  height: 5px;
  overflow: visible;
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-btn-progress
  .progress
  span {
  top: -10px;
  color: #fff;
  right: 38px;
  width: 42px;
  height: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  position: relative;
  border-radius: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--thm-color-5);
}
.inv-service-tab-btn-area
  .service-item-img-text-area
  .service-btn-progress
  .progress-bar {
  border-radius: 10px;
  background-color: var(--thm-color-5);
}
.inv-service-tab-btn-area
  .nav-link
  .service-item-img-text-area
  .service-btn-progress {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-service-tab-btn-area
  .nav-tabs
  .nav-link.active
  .service-item-img-text-area
  .service-btn-progress {
  opacity: 1;
  visibility: visible;
}
.inv-service-tab-btn-area
  .nav-tabs
  .nav-link.active
  .service-item-img-text-area
  .service-more
  a {
  background-color: var(--thm-color-5);
}
.inv-service-tab-btn-area
  .nav-tabs
  .nav-link.active
  .service-item-img-text-area
  .service-item-img-text
  .inner-img {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
}
.inv-service-tab-btn-area
  .nav-tabs
  .nav-link.active
  .service-item-img-text-area
  .service-item-img-text
  .inner-text {
  padding-left: 75px;
}

.inv-service-tab-img-area {
  margin-left: 60px;
  -webkit-transform: rotate(-12deg);
  transform: rotate(-12deg);
}
.inv-service-tab-img-area .service-img {
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
}
.inv-service-tab-img-area .service-more-btn {
  left: 0;
  right: 0;
  margin: 0;
  bottom: -22px;
  text-align: center;
}
.inv-service-tab-img-area .service-more-btn a {
  z-index: 1;
  color: #fff;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  font-weight: 700;
  padding: 15px 38px;
  display: inline-block;
  background-color: var(--thm-color-5);
}
.inv-service-tab-img-area .service-more-btn a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}
.inv-service-tab-img-area .service-more-btn a:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.inv-service-section-3 {
  padding: 120px 0px 0px;
}
.inv-service-section-3 .inv-section-title-2 .subtitle {
  background-color: #fff0ec;
}
.inv-service-section-3 .inv-section-title-2 h2 {
  color: #191825;
  font-size: 55px;
}

.inv-service-content-3 {
  padding: 40px 0px 90px;
  border-bottom: 1px solid #e0e0e0;
}

.inv-service-item-3 {
  margin: 0 auto;
  overflow: hidden;
  max-width: 390px;
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: #fff;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 3px 62px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 3px 62px 0px rgba(0, 0, 0, 0.06);
}
.inv-service-item-3 .inv-service-img {
  overflow: hidden;
}
.inv-service-item-3 .inv-service-img img {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-service-item-3 .inv-service-text-area .inv-service-text {
  padding: 30px 45px;
}
.inv-service-item-3 .inv-service-text-area .inv-service-text:before,
.inv-service-item-3 .inv-service-text-area .inv-service-text:after {
  width: 8px;
  content: "";
  height: 0%;
  position: absolute;
  background-color: var(--thm-color-5);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-service-item-3 .inv-service-text-area .inv-service-text:before {
  top: 0;
  left: 0;
}
.inv-service-item-3 .inv-service-text-area .inv-service-text:after {
  right: 0;
  bottom: 0;
}
.inv-service-item-3 .inv-service-text-area .service-title-icon {
  margin-bottom: 22px;
}
.inv-service-item-3 .inv-service-text-area .service-title-icon .service-icon {
  width: 95px;
  height: 95px;
  margin-right: 25px;
  border-radius: 100%;
  background-color: #f6f6f6;
  -webkit-transition: 0.8s cubic-bezier(0.24, 0.74, 0.58, 1);
  transition: 0.8s cubic-bezier(0.24, 0.74, 0.58, 1);
}
.inv-service-item-3 .inv-service-text-area .service-title-icon .service-icon i {
  color: #000000;
  font-size: 50px;
  line-height: 0.75;
}
.inv-service-item-3
  .inv-service-text-area
  .service-title-icon
  .service-text
  h3 {
  max-width: 170px;
}
.inv-service-item-3
  .inv-service-text-area
  .service-title-icon
  .service-text
  h3
  a {
  color: #000000;
  font-size: 30px;
  font-weight: 800;
}
.inv-service-item-3 .inv-service-text-area .service-desc {
  color: #4c4c4c;
  font-size: 20px;
}
.inv-service-item-3 .inv-service-text-area .service-btn a {
  width: 100%;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 800;
  padding: 18px 0px;
  background-color: var(--thm-color-5);
}
.inv-service-item-3 .inv-service-text-area .service-btn a:hover {
  letter-spacing: 1px;
}
.inv-service-item-3:hover .inv-service-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.inv-service-item-3:hover .service-title-icon .service-icon {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
.inv-service-item-3:hover .inv-service-text-area .inv-service-text:before,
.inv-service-item-3:hover .inv-service-text-area .inv-service-text:after {
  height: 100%;
}

.inv-counter-section-2 {
  padding: 130px 0px;
}

.inv-counter-item {
  max-width: 340px;
  border-radius: 5px;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 28px 45px;
}
.inv-counter-item:last-child {
  margin-bottom: 0;
}
.inv-counter-item .counter-icon {
  margin-right: 40px;
}
.inv-counter-item .counter-icon i {
  font-size: 58px;
  line-height: 0.5;
}
.inv-counter-item .counter-text h3 {
  color: #000000;
  font-size: 35px;
}
.inv-counter-item .counter-text p {
  color: #666666;
}
.inv-counter-item:nth-child(1) .counter-icon i {
  color: #1aa1ef;
}
.inv-counter-item:nth-child(2) .counter-icon i {
  color: #7141b1;
}
.inv-counter-item:nth-child(3) .counter-icon i {
  color: #aa41b1;
}

.inv-counter-text-area {
  max-width: 670px;
}
.inv-counter-text-area .inv-about-text-content-2 {
  padding-left: 0;
}
.inv-counter-text-area .inv-section-title-2 h2 {
  font-size: 55px;
}
.inv-counter-text-area .inv-about-feature-2 {
  border: none;
  margin-top: 0;
}
.inv-counter-text-area .inv-about-feature-list-iconr {
  margin-bottom: 35px;
}

.inv-pricing-section-2 {
  padding: 110px 0px 150px;
}

.inv-pricing-img {
  overflow: hidden;
  border-radius: 8px;
  display: inline-block;
}

.inv-price-img-pricing .inv-pricing-plan {
  right: 0;
  bottom: 42px;
}
.inv-price-img-pricing .pricing-plan-text {
  padding: 35px 32px;
}
.inv-price-img-pricing .pricing-plan-text h3 {
  color: #252525;
  font-size: 32px;
  font-weight: 800;
}
.inv-price-img-pricing .pricing-plan-text h2 {
  color: #252525;
  font-size: 40px;
  font-weight: 700;
}
.inv-price-img-pricing .pricing-plan-text h2 sup {
  top: -15px;
  font-weight: 800;
  font-size: 13px;
}
.inv-price-img-pricing .pricing-plan-text h2 sub {
  bottom: 0px;
  font-size: 13px;
  font-weight: 800;
}
.inv-price-img-pricing .pricing-plan-text p {
  color: #212121;
  font-weight: 600;
  padding-top: 10px;
}

.inv-pricing-text-area {
  padding-top: 15px;
}
.inv-pricing-text-area .inv-section-title-2 .subtitle {
  background-color: #fff0ec;
}
.inv-pricing-text-area .inv-section-title-2 h2 {
  color: #181515;
  font-size: 55px;
}
.inv-pricing-text-area .inv-section-title-2 p {
  color: #2c2c2c;
}
.inv-pricing-text-area .inv-about-feature-list li {
  color: #181515;
  font-size: 19px;
  font-weight: 700;
}
.inv-pricing-text-area .inv-about-feature-2 {
  border: none;
  margin-top: 0;
}
.inv-pricing-text-area .inv-about-feature-list {
  margin-bottom: 30px;
}

.inv-testimonial-section-2 {
  padding: 200px 0px 120px;
}

.inv-testimonial-slider-area-2 {
  padding-left: 120px;
}

.inv-testimonial-slider-indicators {
  left: 0;
  height: 100%;
  position: absolute;
}
.inv-testimonial-slider-indicators .carousel-indicators {
  margin: 0;
  position: static;
  display: inline-block;
}
.inv-testimonial-slider-indicators .carousel-indicators [data-bs-target] {
  margin: 0;
  width: 94px;
  opacity: 1;
  height: 94px;
  border: none;
  text-indent: 0;
  overflow: hidden;
  position: absolute;
  border-radius: 100%;
}
.inv-testimonial-slider-indicators
  .carousel-indicators
  [data-bs-target]:nth-child(1) {
  bottom: 110px;
  left: -115px;
}
.inv-testimonial-slider-indicators
  .carousel-indicators
  [data-bs-target]:nth-child(2) {
  top: -35px;
  left: -95px;
}
.inv-testimonial-slider-indicators
  .carousel-indicators
  [data-bs-target]:nth-child(3) {
  top: -108px;
  left: 15px;
}

.inv-testimonial-item-2 .inv-testimonial-img-author {
  max-width: 218px;
  margin-right: 80px;
}
.inv-testimonial-item-2 .inv-testimonial-img-author .inv-testimonial-img {
  width: 210px;
  height: 210px;
  overflow: hidden;
  margin-bottom: 25px;
  border-radius: 100%;
}
.inv-testimonial-item-2 .inv-testimonial-img-author .inv-testimonial-author h3 {
  font-size: 27px;
  font-weight: 800;
  color: var(--thm-color-5);
}
.inv-testimonial-item-2
  .inv-testimonial-img-author
  .inv-testimonial-author
  span {
  font-size: 14px;
  color: #9aa1a2;
  text-transform: uppercase;
}
.inv-testimonial-item-2 .inv-testimonial-text-2 {
  max-width: 760px;
}
.inv-testimonial-item-2 .inv-testimonial-text-2 .testimonial-desc {
  color: #d0d0d0;
  font-size: 28px;
  margin: 18px 0px;
}
.inv-testimonial-item-2 .inv-testimonial-text-2 .testimonial-ratting li {
  color: #f18f20;
  font-size: 16px;
}

.inv-testimonial-slider-next-prev {
  left: 280px;
  bottom: -15px;
  position: absolute;
}
.inv-testimonial-slider-next-prev .carousel-control-next,
.inv-testimonial-slider-next-prev .carousel-control-prev {
  width: 55px;
  height: 55px;
  opacity: 1;
  position: static;
  line-height: 55px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 100%;
  color: var(--thm-color-5);
  background-color: #272626;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-testimonial-slider-next-prev .carousel-control-next:hover,
.inv-testimonial-slider-next-prev .carousel-control-prev:hover {
  color: #fff;
  background-color: var(--thm-color-5);
}
.inv-testimonial-slider-next-prev .carousel-control-prev-icon,
.inv-testimonial-slider-next-prev .carousel-control-next-icon {
  background-image: none;
}

.inv-portfolio-section {
  padding: 130px 0px 160px;
}

.inv-portfolio-top-content .inv-section-title-2 .subtitle {
  background-color: #fff0ec;
}
.inv-portfolio-top-content .inv-section-title-2 h2 {
  color: #191825;
  font-size: 55px;
}
.inv-portfolio-top-content .inv-carousel-arrow-next-prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.inv-portfolio-top-content .inv-carousel-arrow-next-prev .inv-carousel-arrow {
  color: #282524;
  margin-left: 5px;
  position: static;
  cursor: pointer;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  background-color: #efefef;
}
.inv-portfolio-top-content
  .inv-carousel-arrow-next-prev
  .inv-carousel-arrow:hover {
  color: #fff;
  background-color: #ff613c;
}

.inv-portfolio-item {
  max-width: 505px;
  padding-bottom: 65px;
}
.inv-portfolio-item .inv-portfolio-img {
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
}
.inv-portfolio-item .inv-portfolio-img img {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-portfolio-item .inv-portfolio-text {
  left: 0;
  bottom: 0;
  width: 80%;
  border-radius: 4px;
  padding: 10px 35px 20px;
  background-color: #f8f8f8;
}
.inv-portfolio-item .inv-portfolio-text:before {
  top: 0;
  left: 0;
  width: 12px;
  content: "";
  height: 100%;
  position: absolute;
  background-color: var(--thm-color-5);
}
.inv-portfolio-item .inv-portfolio-text .portfolio-serial {
  font-size: 90px;
  line-height: 1;
  font-weight: 800;
  margin-right: 22px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d2d2d2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-portfolio-item .inv-portfolio-text .portfolio-title {
  max-width: 230px;
}
.inv-portfolio-item .inv-portfolio-text .portfolio-title a {
  color: #191825;
  font-size: 27px;
  font-weight: 800;
}
.inv-portfolio-item:hover .inv-portfolio-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.inv-portfolio-item:hover .inv-portfolio-text .portfolio-serial {
  -webkit-text-fill-color: var(--thm-color-5);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--thm-color-5);
}

.inv-portfolio-content {
  padding-top: 50px;
}

.inv-portfolio-item-slider {
  max-width: 1580px;
  margin: 0 0;
}

.inv-cta-blog-section {
  z-index: 1;
}
.inv-cta-blog-section:before,
.inv-cta-blog-section:after {
  top: 0;
  z-index: -1;
  width: 100%;
  content: "";
  height: 135px;
  position: absolute;
  background-color: #fff;
}
.inv-cta-blog-section:after {
  bottom: 0;
  top: auto;
  height: 160px;
}

.inv-cta-content-2 {
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 110px;
  padding: 100px 110px 160px 75px;
}
.inv-cta-content-2 .inv-cta-text-btn-2 .inv-btn-2 {
  padding-top: 10px;
}
.inv-cta-content-2 .inv-cta-text-2 {
  margin-right: 20px;
}
.inv-cta-content-2 .inv-cta-text-2 span {
  display: block;
  font-size: 20px;
  font-weight: 800;
  padding-bottom: 8px;
  color: var(--thm-color-5);
}
.inv-cta-content-2 .inv-cta-text-2 h3 {
  color: #1e1e1e;
  font-size: 50px;
  font-weight: 800;
}

.inv-blog-item-2 {
  max-width: 604px;
  overflow: hidden;
  border-radius: 5px;
}
.inv-blog-item-2 .inv-blog-img {
  overflow: hidden;
}
.inv-blog-item-2 .inv-blog-img img {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.inv-blog-item-2:before {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  content: "";
  height: 100%;
  opacity: 0.349;
  position: absolute;
  background-color: rgb(0, 0, 0);
}
.inv-blog-item-2 .blog-date {
  top: 40px;
  left: 40px;
  z-index: 2;
  width: 70px;
  color: #fff;
  height: 70px;
  font-weight: 800;
  padding-top: 15px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--thm-color-5);
}
.inv-blog-item-2 .blog-date span {
  display: block;
  font-size: 14px;
}
.inv-blog-item-2 .blog-date b {
  font-size: 20px;
}
.inv-blog-item-2 .blog-title {
  left: 55px;
  z-index: 2;
  bottom: 50px;
  max-width: 350px;
}
.inv-blog-item-2 .blog-title h3 a {
  color: #fff;
  font-size: 27px;
  font-weight: 800;
  display: inline !important;
  background-position-y: -6px;
  background-repeat: no-repeat;
  background-size: 0 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.inv-blog-item-2 .blog-title h3 a:hover {
  background-size: 100% 100%;
}
.inv-blog-item-2:hover .inv-blog-img img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.inv-blog-slider-area-2 {
  padding-top: 40px;
}
.inv-blog-slider-area-2 .inv-carousel-arrow-next-prev .inv-carousel-arrow {
  top: 57%;
}
.inv-blog-slider-area-2
  .inv-carousel-arrow-next-prev
  .inv-carousel-arrow:hover {
  background-color: var(--thm-color-5);
}
.inv-blog-slider-area-2 .inv-blog-arrow-prev-2 {
  left: -100px;
}
.inv-blog-slider-area-2 .inv-blog-arrow-next-2 {
  right: -100px;
}

.inv-partner-secton {
  padding: 100px 0px 80px;
}

.inv-partner-title {
  padding-bottom: 30px;
}
.inv-partner-title h2 {
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.inv-partner-title h2 span {
  margin: 0px 20px;
  white-space: nowrap;
  display: inline-block;
}
.inv-partner-title h2 i {
  width: 100%;
  height: 1.1px;
  position: relative;
  display: inline-block;
  background-color: #ededed;
}

.inv-footer-section-2 {
  padding-top: 85px;
}

.inv-footer-content-2 .footer-logo {
  padding-bottom: 40px;
}

.inv-footer-cta-item-2 {
  padding-bottom: 55px;
}
.inv-footer-cta-item-2 .cta-icon {
  width: 82px;
  height: 82px;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: 22px;
  background-color: #585189;
}
.inv-footer-cta-item-2 .cta-icon i {
  font-size: 24px;
  color: var(--thm-color-5);
}
.inv-footer-cta-item-2 .cta-text a,
.inv-footer-cta-item-2 .cta-text span {
  width: 100%;
  display: block;
}
.inv-footer-cta-item-2 .cta-text a {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}
.inv-footer-cta-item-2 .cta-text span {
  color: #aeaacb;
  font-size: 16px;
  font-weight: 700;
}
.inv-footer-cta-item-2:hover .cta-icon {
  -webkit-animation: icon-bounce 0.8s ease-out infinite;
  animation: icon-bounce 0.8s ease-out infinite;
}

.inv-footer-gallery ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.inv-footer-gallery ul li {
  width: 178px;
  height: 132px;
}
.inv-footer-gallery ul li a {
  display: block;
  position: relative;
}
.inv-footer-gallery ul li a:before {
  top: 0;
  right: 0;
  width: 0%;
  left: auto;
  opacity: 0.8;
  content: "";
  height: 100%;
  position: absolute;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: var(--thm-color-5);
}
.inv-footer-gallery ul li a:after {
  left: 0;
  right: 0;
  top: 40%;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  margin: 0 auto;
  text-align: center;
  content: "\f31d";
  font-weight: 300;
  position: absolute;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.inv-footer-gallery ul li a:hover:before {
  left: 0;
  right: auto;
  width: 100%;
}
.inv-footer-gallery ul li a:hover:after {
  top: 50%;
  opacity: 1;
}

.inv-footer-copyright-social-2 {
  padding: 25px 0px;
}
.inv-footer-copyright-social-2 .inv-footer-copyright-2 {
  font-size: 17px;
  color: #aeaacb;
}
.inv-footer-copyright-social-2 .inv-footer-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.inv-footer-copyright-social-2 .inv-footer-social a {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--thm-color-5);
}
.inv-footer-copyright-social-2 .inv-footer-social a:hover {
  color: #4ccef9;
}

@media screen and (max-width: 1800px) {
  .inv-testimonial {
    width: 55%;
  }
}
@media screen and (max-width: 1700px) {
  .header-style-four {
    padding-left: 20px;
  }
  .header-style-four .tx-header-menu-navigation-cta-btn {
    padding-right: 90px;
  }
  .header-style-four .tx-header-content:before {
    width: 84%;
  }
}
@media screen and (max-width: 1600px) {
  .header-style-four .tx-header-top {
    max-width: 100%;
    padding-right: 15px;
  }
  .header-style-four .tx-header-menu-navigation-cta-btn {
    padding-right: 15px;
  }
  .header-style-four .tx-header-cart-cta-btn .tx-header-cta {
    margin-left: 20px;
  }
  .header-style-four .tx-header-cart-cta-btn .tx-header-cart-search button {
    margin-left: 15px;
  }
  .inv-faq-content {
    padding-left: 40px;
  }
  .inv-footer-cta-section {
    overflow: hidden;
  }
  .inv-footer-cta-section .inv-footer-cta-shape.shape_2 {
    right: -150px;
  }
}
@media screen and (max-width: 1450px) {
  .header-style-four .tx-main-navigation-area li {
    margin-right: 35px;
  }
  .header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn {
    margin-left: 20px;
  }
  .inv-testimonial {
    width: 50%;
    padding: 75px 30px 0px 30px;
  }
  .inv-testimonial-thumb-area {
    bottom: 0;
  }
  .inv-team-content .inv-team-arrow-next {
    right: -75px;
  }
  .inv-team-content .inv-team-arrow-prev {
    left: -75px;
  }
  .inv-blog-slider-area-2 .inv-carousel-arrow-next-prev {
    margin-top: 45px;
  }
  .inv-blog-slider-area-2 .inv-carousel-arrow-next-prev .inv-carousel-arrow {
    margin: 0px 8px;
    position: static;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .inv-blog-slider-area-2 .inv-carousel-arrow-next-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .inv-cta-blog-section {
    padding-bottom: 100px;
  }
  .inv-cta-blog-section:after {
    display: none;
  }
}
@media screen and (max-width: 1350px) {
  .inv-service-slider-area .inv-carousel-arrow-next-prev,
  .inv-team-content .inv-carousel-arrow-next-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .inv-service-slider-area .inv-carousel-arrow-next-prev .inv-carousel-arrow,
  .inv-team-content .inv-carousel-arrow-next-prev .inv-carousel-arrow {
    position: static;
    margin: 0px 8px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .inv-testimonial-slide-for-area {
    max-width: 395px;
    margin-top: 60px;
  }
  .inv-testimonial-thumb-area {
    margin-right: 25px;
  }
  .inv-footer-cta-section .inv-footer-cta-shape.shape_2 {
    right: -250px;
  }
  .inv-slider-section-2 .inv-sldier-mail {
    padding: 25px 8px;
  }
  .inv-slider-section-2 .inv-sldier-side-social {
    right: 15px;
  }
}
@media screen and (max-width: 1320px) {
  .header-style-four .tx-header-content:before {
    width: 81%;
  }
  .header-style-four .tx-main-navigation-area li a {
    font-size: 16px;
  }
  .header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn a {
    padding: 15px;
  }
  .inv-slider-section-2 .inv-sldier-mail {
    display: none;
  }
  .inv-slider-section-2 .inv-sldier-side-social {
    display: none;
  }
  .header-style-five .tx-header-top-content:before,
  .header-style-five .tx-header-top-content:after {
    display: none;
  }
  .header-style-five .tx-header-top-content {
    background-color: #000000;
  }
  .inv-slider-bottom .slider-counter-item:before {
    display: none;
  }
}
@media screen and (max-width: 1250px) {
  .header-style-four .tx-header-cart-cta-btn .tx-header-cta {
    display: none !important;
  }
  .inv-get-quote-section .inv-get-quote-bg {
    display: none;
  }
  .inv-get-quote-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/bg/gq-bg.jpg);
  }
  .inv-team-member-item {
    padding: 0px 30px 40px 45px;
  }
  .inv-faq-content {
    width: 50%;
    padding: 120px 20px 120px 20px;
  }
  .inv-section-title-2 h2 {
    font-size: 55px;
  }
  .inv-portfolio-content {
    padding: 50px 15px 0px;
  }
  .inv-portfolio-item .inv-portfolio-text .portfolio-title a {
    font-size: 22px;
  }
}
@media screen and (max-width: 1150px) {
  .inv-testimonial {
    width: 100%;
  }
  .inv-faq-content {
    width: 100%;
  }
  .inv-testimonial-faq-content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-testimonial-slider-area {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 80px;
  }
  .inv-faq-accordion {
    max-width: 100%;
  }
  .inv-pricing-content {
    padding: 70px 30px;
  }
  .inv-pricing-price-text .inv-pricing-text-price .price-text-btn {
    max-width: 295px;
    margin-right: 25px;
  }
  .inv-pricing-img-area .price-icon-shape {
    left: 30px;
  }
  .header-style-four .tx-header-top .top-social {
    display: none;
  }
  .header-style-five .tx-main-navigation-area li {
    margin: 0px 18px;
  }
  .header-style-five .tx-header-cta-btn a {
    padding: 15px 20px;
  }
  .header-style-five .tx-header-menu-cta {
    padding: 20px 0px 15px;
  }
}
@media screen and (max-width: 1024px) {
  .header-style-four .tx-header-cart-cta-btn .tx-header-cart-search button {
    font-size: 16px;
    margin-left: 10px;
  }
  .header-style-four .dropdown:after {
    top: 7px;
    right: -14px;
    font-size: 10px;
  }
  .header-style-four .tx-main-navigation-area li {
    margin-right: 20px;
  }
  .inv-get-quote-content {
    padding-left: 0;
  }
  .inv-form-input-area input {
    max-width: 220px;
  }
  .header-style-four .tx-header-content:before {
    width: 76%;
  }
  .header-style-four .tx-header-menu-navigation-cta-btn {
    padding-top: 20px;
  }
  .inv-section-title h2 {
    font-size: 45px;
  }
  .inv-about-text-area {
    padding-left: 0;
  }
  .inv-pricing-content {
    padding: 50px 30px;
  }
  .inv-pricing-price-text h2 {
    font-size: 40px;
  }
  .inv-pricing-price-text
    .inv-pricing-text-price
    .pricing-list-price
    .price-value {
    font-size: 40px;
  }
  .inv-pricing-img-area .price-icon-shape {
    display: none !important;
  }
  .inv-team-member-item .inv-team-img {
    width: 150px;
  }
  .inv-team-member-item:before {
    height: 100%;
  }
  .inv-team-member-item .team-contact-info {
    margin-bottom: 0;
  }
  .inv-pricing-img-area {
    padding-left: 30px;
  }
  .inv-section-title-2 h2 {
    font-size: 50px;
  }
  .inv-service-tab-btn-area .service-item-img-text-area .service-more {
    display: none;
  }
  .inv-service-tab-img-area {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  .inv-service-tab-btn-area li {
    margin-bottom: 20px;
  }
  .inv-service-tab-btn-area .service-item-img-text-area .service-btn-progress {
    margin-top: 15px;
  }
  .inv-service-tab-btn-area
    .service-item-img-text-area
    .service-item-img-text
    .inner-text
    h3
    a {
    font-size: 20px;
  }
  .inv-service-item-3
    .inv-service-text-area
    .service-title-icon
    .service-text
    h3
    a {
    font-size: 22px;
  }
  .inv-service-item-3 .inv-service-text-area .inv-service-text {
    padding: 20px;
  }
  .inv-testimonial-item-2 .inv-testimonial-text-2 .testimonial-desc {
    font-size: 22px;
  }
}
@media screen and (max-width: 991px) {
  .header-style-four .tx-header-top,
  .header-style-four .tx-header-content:before {
    display: none !important;
  }
  .header-style-four .tx-main-navigation-area {
    display: none;
  }
  .header-style-four {
    padding-left: 15px;
  }
  .header-style-four .tx-header-cart-cta-btn {
    margin-right: 40px;
  }
  .mobile_menu_button {
    display: block;
  }
  .inv-get-quote-title {
    padding-bottom: 20px;
  }
  .inv-about-img-wrapper {
    margin: 0 auto;
    max-width: 650px;
    margin-bottom: 70px;
  }
  .inv-about-text-area {
    margin: 0 auto;
    max-width: 650px;
  }
  .inv-about-section {
    padding: 100px 0px;
  }
  .inv-growth-item-area {
    max-width: 490px;
    margin: 0 auto 20px;
  }
  .inv-growth-text-area {
    margin: 0 auto;
  }
  .inv-footer-cta-section .inv-footer-cta-shape.shape_2 {
    display: none;
  }
  .inv-footer-widget .recent-blog-item {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
  .inv-footer-widget .instagram-feed {
    max-width: 285px;
  }
  .inv-footer-widget .widget-title {
    padding-bottom: 20px;
  }
  .inv-team-member-item {
    max-width: 460px;
    margin: 0 auto;
  }
  .inv-video-play-item {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .inv-video-text-area {
    margin: 0 auto;
    max-width: 670px;
  }
  .inv-success-item-tab .grid-size-50 {
    width: 100%;
  }
  .inv-success-item-tab .grid-sizer {
    width: 100%;
  }
  .inv-pricing-img-area {
    display: none !important;
  }
  .inv-pricing-tab-item-img-area {
    display: block !important;
    max-width: 570px;
    margin: 0 auto;
  }
  .inv-pricing-tab-btn .nav-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .header-style-four .tx-header-cart-cta-btn .tx-header-cta-btn {
    display: none;
  }
  .header-style-five .tx-main-navigation-area {
    display: none;
  }
  .header-style-five .tx-header-menu-cta {
    border-radius: 0;
  }
  .header-style-five .tx-header-navigation {
    background-color: #fff;
  }
  .inv-slider-bottom .inv-slider-bottom-slug-arrow .inv-slider-bottom-slug,
  .inv-slider-bottom .slider-bottom-counter,
  .inv-slider-bottom:before {
    display: none !important;
  }
  .inv-slider-bottom .inv-slider-bottom-slug-arrow {
    padding-left: 0;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .header-style-five .tx-header-cta-btn {
    margin-right: 40px;
  }
  .header-style-five .mobile_menu_button {
    right: 0;
    top: -60px;
    color: var(--thm-color-5);
  }
  .header-style-five .inv-btn-2 a {
    padding: 15px 25px;
  }
  .inv-about-img-wrapper-2 {
    margin: 0 auto;
    max-width: 600px;
    margin-bottom: 180px;
  }
  .inv-about-text-content-2 {
    margin: 0 auto;
    max-width: 600px;
  }
  .inv-service-tab-btn-area {
    margin: 0 auto;
    max-width: 500px;
  }
  .inv-service-tab-content-2 {
    margin: 0 auto;
    max-width: 700px;
  }
  .inv-counter-item-area,
  .inv-price-img-pricing {
    margin: 0 auto;
    max-width: 650px;
    margin-bottom: 40px;
  }
  .inv-price-img-pricing {
    max-width: 610px;
  }
  .inv-counter-item {
    max-width: 100%;
  }
  .inv-counter-text-area,
  .inv-blog-item-2 {
    margin: 0 auto;
  }
  .inv-testimonial-item-2 .inv-testimonial-img-author {
    margin-right: 50px;
  }
  .inv-testimonial-slider-next-prev {
    position: static;
    margin-top: 20px;
    text-align: center;
  }
  .inv-testimonial-slider-indicators
    .carousel-indicators
    [data-bs-target]:nth-child(1) {
    bottom: 185px;
  }
  .inv-portfolio-item .inv-portfolio-text .portfolio-serial {
    font-size: 70px;
  }
}
@media screen and (max-width: 767px) {
  .inv-video-content:before {
    height: 100%;
  }
  .inv-sponsor-content .inv-sponsor-item {
    width: 33.33%;
  }
  .inv-portfolio-item {
    margin: 0 auto;
  }
  .inv-footer-gallery ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-footer-gallery ul li {
    width: 24.33%;
  }
  .header-style-five .tx-header-top-content {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .inv-growth-item {
    max-width: 100%;
  }
  .inv-growth-item-area {
    max-width: 100%;
  }
  .inv-pricing-text-price {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-pricing-price-text .inv-pricing-text-price .price-text-btn {
    max-width: 100%;
    margin-bottom: 35px;
  }
  .inv-testimonial-slider-area {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-slider-item-2 .inv-slider-img-text-area .inv-slider-text h1 {
    font-size: 50px;
  }
  .inv-slider-item-2 .inv-slider-img-text-area {
    padding: 250px 0px 100px;
  }
  .inv-section-title-2 h2,
  .inv-service-section-2 .inv-section-title-2 h2 {
    font-size: 45px;
  }
  .inv-pricing-img {
    display: block;
    text-align: center;
    margin-bottom: 30px;
  }
  .inv-price-img-pricing .inv-pricing-plan {
    position: static !important;
  }
  .inv-testimonial-slider-indicators .carousel-indicators {
    display: none;
  }
  .inv-testimonial-slider-area-2 {
    padding-left: 0;
  }
  .inv-footer-gallery ul li {
    width: 33.33%;
  }
  .inv-service-tab-img-area {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .inv-get-quote-title h2 {
    font-size: 30px;
  }
  .inv-get-quote-title p {
    font-size: 16px;
  }
  .inv-form-input-area input {
    max-width: 100%;
    height: 50px;
  }
  .header-style-four .tx-header-content .brand-logo {
    margin-right: 0;
  }
  .inv-about-section {
    padding: 60px 0px;
  }
  .inv-about-img-wrapper .inv-about-experience {
    top: 0;
    right: 0;
  }
  .inv-about-img-wrapper .inv-about-img2 {
    display: none;
  }
  .inv-about-img-wrapper .inv-about-experience h3 {
    font-size: 50px;
  }
  .inv-about-img-wrapper .inv-about-experience p {
    font-size: 20px;
  }
  .inv-section-title h2 {
    font-size: 32px;
  }
  .inv-about-tab-content-area .inv-about-tab-content .about-tab-icon {
    margin-bottom: 15px;
  }
  .inv-about-tab-content-area .inv-about-tab-content .about-tab-icon i {
    font-size: 50px;
  }
  .inv-about-tab-content-area .inv-about-tab-cta .inv-cta-info {
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
  }
  .inv-about-tab-content-area .inv-about-tab-cta {
    margin-top: 20px;
  }
  .inv-btn a {
    padding: 15px 20px;
  }
  .inv-service-section {
    padding: 60px 0px;
  }
  .inv-carousel-arrow-next-prev .inv-carousel-arrow {
    height: 45px;
    width: 45px;
  }
  .inv-pricing-content {
    top: 0;
    margin-bottom: 0;
  }
  .inv-success-section,
  .inv-team-section {
    padding: 60px 0px;
  }
  .inv-success-content {
    padding-top: 15px;
  }
  .inv-success-item .success-text {
    left: 0;
  }
  .inv-success-item:hover .success-text .link-arrow a {
    display: none !important;
  }
  .inv-pricing-price-text h2 {
    font-size: 30px;
    padding-bottom: 15px;
  }
  .inv-growth-section {
    padding: 60px 0px;
  }
  .inv-testimonial-slide-for-area {
    max-width: 100%;
  }
  .inv-faq-accordion .accordion-body {
    padding: 10px 30px 30px 70px;
  }
  .inv-faq-accordion .accordion-header button {
    padding: 25px 30px 25px 70px;
  }
  .inv-sponsor-content .inv-sponsor-item {
    width: 50%;
  }
  .inv-footer-cta-section .inv-footer-cta-shape.shape_1 {
    display: none;
  }
  .inv-footer-cta-section {
    padding: 60px 0px;
  }
  .inv-footer-content {
    padding-top: 60px;
  }
  .inv-service-bottom-text-btn h3 {
    font-size: 26px;
  }
  .inv-service-bottom-text-btn p {
    font-size: 18px;
  }
  .inv-team-member-item {
    padding: 20px 15px 30px;
  }
  .inv-team-member-item .team-contact-info h3 a {
    font-size: 20px;
  }
  .inv-team-member-item .inv-team-social .social-list a {
    width: 30px;
    height: 30px;
  }
  .inv-team-member-item .inv-team-social .social-list {
    margin-bottom: 10px;
  }
  .inv-team-member-item .inv-team-name-text h3 a {
    font-size: 26px;
  }
  .inv-team-img-social {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .inv-sponsor-content .inv-sponsor-item {
    padding: 0px 20px;
  }
  .inv-blog-section {
    padding-bottom: 60px;
  }
  .inv-service-slider-item .inv-service-text h3 a {
    font-size: 22px;
  }
  .inv-video-section {
    padding: 60px 0px;
  }
  .header-style-four .tx-header-content .brand-logo {
    padding-top: 15px;
  }
  .header-style-five .tx-header-cta-btn {
    display: none;
  }
  .inv-slider-item-2 .inv-slider-img-text-area .inv-slider-video {
    position: static;
    margin-top: 15px;
  }
  .inv-sponsor-section-2 {
    padding: 60px 0px;
  }
  .inv-about-img-wrapper-2 .inv-about-img_2_2 {
    display: none;
  }
  .inv-about-img-wrapper-2 {
    margin-bottom: 100px;
  }
  .header-style-five .mobile_menu_button {
    top: -55px;
  }
  .inv-section-title-2 h2,
  .inv-service-section-2 .inv-section-title-2 h2 {
    font-size: 32px;
  }
  .inv-about-text-content-2 {
    padding-left: 0;
  }
  .inv-about-section-2,
  .inv-service-section-2,
  .inv-counter-section-2,
  .inv-portfolio-section {
    padding: 60px 0px;
  }
  .inv-counter-text-area .inv-section-title-2 h2,
  .inv-service-section-3 .inv-section-title-2 h2,
  .inv-pricing-text-area .inv-section-title-2 h2,
  .inv-portfolio-top-content .inv-section-title-2 h2 {
    font-size: 32px;
  }
  .inv-service-content-3 {
    padding-bottom: 60px;
  }
  .inv-pricing-section-2 {
    padding-bottom: 60px;
  }
  .inv-testimonial-item-content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-testimonial-item-2 .inv-testimonial-img-author {
    margin-bottom: 20px;
  }
  .inv-testimonial-section-2 {
    padding: 60px 0px;
  }
  .inv-cta-content-2 {
    padding: 60px 30px;
  }
  .inv-cta-content-2 .inv-cta-text-2 h3 {
    font-size: 30px;
  }
  .inv-footer-gallery ul li {
    width: 50%;
    margin-bottom: 15px;
  }
  .inv-footer-copyright-social-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .inv-footer-copyright-social-2 .inv-footer-copyright-2 {
    padding-bottom: 15px;
  }
  .inv-slider-item-2 .inv-slider-img-text-area {
    padding: 200px 0px 100px;
  }
}
@media screen and (max-width: 400px) {
  .inv-success-item .success-text h3 a {
    font-size: 20px;
  }
  .inv-success-item .success-text {
    padding: 15px 20px;
  }
  .inv-team-member-item .inv-team-img {
    margin-right: 30px;
  }
  .inv-slider-item-2 .inv-slider-img-text-area .inv-slider-text h1 {
    font-size: 45px;
  }
  .inv-price-img-pricing .inv-pricing-plan .price-img {
    display: none;
  }
  .inv-blog-item-2 .blog-title h3 a {
    font-size: 22px;
  }
  .inv-blog-item-2 .blog-date {
    top: 20px;
    left: 20px;
  }
  .inv-blog-item-2 .blog-title {
    left: 20px;
    bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .inv-faq-accordion .accordion-button::after {
    right: 15px;
  }
  .inv-video-play-item .video-play-icon .inner-icon i {
    font-size: 50px;
  }
  .inv-video-play-item .video-play-icon .inner-text h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 320px) {
  .inv-about-feature-icon {
    display: none;
  }
  .inv-counter-item .counter-text h3 {
    font-size: 28px;
  }
  .inv-footer-gallery ul li {
    margin-bottom: 0;
  }
}
/*----------------------------------------*/
/*  14. footer
/*----------------------------------------*/
.footer__widget p {
  color: #eeedef;
  font-size: 16px;
  line-height: 27px;
  opacity: 0.83;
}
.footer__widget .thm-btn {
  padding: 10px 33px;
  overflow: hidden;
}
.footer__widget .widget-title {
  font-size: 24px;
  color: var(--color-white);
  position: relative;
  margin-bottom: 25px;
  letter-spacing: -0.2px;
}
.footer__widget .widget-title::before {
  position: absolute;
  content: "";
  bottom: -8px;
  left: 0;
  width: 28px;
  height: 2px;
}
.footer__logo {
  margin-bottom: 20px;
}
.footer__newslater {
  position: relative;
  margin-top: 30px;
}
.footer__newslater input {
  padding: 0 30px;
  background-color: #361f61;
  border: none;
  color: var(--color-white);
  font-size: 14px;
  height: 44px;
  margin: 0;
}
.footer__newslater input::-webkit-input-placeholder {
  color: var(--color-white);
  opacity: 1;
}
.footer__newslater input::-moz-placeholder {
  color: var(--color-white);
  opacity: 1;
}
.footer__newslater input:-ms-input-placeholder {
  color: var(--color-white);
  opacity: 1;
}
.footer__newslater input:-moz-placeholder {
  color: var(--color-white);
  opacity: 1;
}
.footer__newslater button {
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  font-size: 14px;
  height: 100%;
  color: var(--color-white);
}
.footer__info li:not(:last-child) {
  margin-bottom: 9px;
}
.footer__info li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.footer__info li a i {
  min-width: 18px;
  margin-right: 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 4px;
  font-size: 15px;
}
.footer__info p {
  font-size: 14px;
  line-height: 24px;
}
.footer__open-hour h5 {
  font-size: 14px;
  text-decoration: underline;
  margin-bottom: 10px;
  color: var(--color-white);
}
.footer__open-hour p {
  font-size: 14px;
  line-height: 24px;
}
.footer__copyright-text {
  color: var(--color-white);
  font-size: 14px;
  padding: 20px 0;
  position: relative;
}
.footer__copyright-text::before {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(4.78%, rgba(55, 43, 75, 0.4)),
    color-stop(49.21%, rgba(255, 255, 255, 0.1647058824)),
    color-stop(93.92%, rgba(55, 43, 75, 0.4))
  );
  background: linear-gradient(
    90deg,
    rgba(55, 43, 75, 0.4) 4.78%,
    rgba(255, 255, 255, 0.1647058824) 49.21%,
    rgba(55, 43, 75, 0.4) 93.92%
  );
  width: 100%;
  height: 1px;
  z-index: 2;
  right: 0;
  width: 85%;
  margin: auto;
}
.footer__copyright-text a {
  color: currentColor;
}

.widget-gallery {
  margin: -6px;
  margin-top: 10px;
  overflow: hidden;
}
.widget-gallery li {
  float: left;
  text-align: center;
  max-width: 33.33%;
  padding: 6px;
}
.widget-gallery li a {
  position: relative;
  display: block;
}
.widget-gallery li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 4, 46, 0.67);
  -webkit-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
  opacity: 0;
  z-index: 1;
}
.widget-gallery li a::after {
  content: "\f002";
  font-size: 14px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: #fff;
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 250ms linear 0ms;
  transition: all 250ms linear 0ms;
  opacity: 0;
}
.widget-gallery li a:hover::before,
.widget-gallery li a:hover::after {
  opacity: 1;
}

.site-footer {
  overflow: hidden;
}
.site-footer [class^="col-"]:nth-child(2) .footer__widget {
  padding-left: 15px;
  margin-right: -20px;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"]:nth-child(2) .footer__widget {
    padding-left: 0;
    margin-right: 0;
  }
}
.site-footer [class^="col-"]:nth-child(3) .footer__widget {
  padding-left: 32px;
  margin-right: -20px;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"]:nth-child(3) .footer__widget {
    padding-left: 0;
    margin-right: 0;
  }
}
.site-footer [class^="col-"]:nth-child(4) .footer__widget {
  padding-left: 20px;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"]:nth-child(4) .footer__widget {
    padding-left: 0;
  }
}

.footer-style-two .social-links a i:nth-child(2) {
  color: var(--thm-color-2);
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: transparent;
}
.footer-style-two .widget-gallery li a::before {
  background-color: rgba(10, 24, 63, 0.67);
}

.footer-style-three .footer__widget .thm-btn {
  padding: 12px 29px;
}
.footer-style-three .footer__newslater input {
  background-color: var(--color-black);
}
.footer-style-three .widget-gallery li a::before {
  background-color: rgba(0, 0, 0, 0.67);
}

:root {
  scroll-behavior: inherit;
  --font-four: "Catamaran", sans-serif;
  --grad-color: linear-gradient(
    -90deg,
    rgb(168, 115, 250) 0%,
    rgb(112, 61, 241) 100%
  );
}

body.inv-landing {
  color: #39393a;
  font-size: 18px;
  font-family: var(--body-font);
  line-height: 1.556;
}
body.inv-landing a {
  text-decoration: none;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
body.inv-landing a:hover,
body.inv-landing a:focus {
  text-decoration: none;
}
body.inv-landing .container {
  max-width: 1550px;
}
body.inv-landing h1,
body.inv-landing h2,
body.inv-landing h3,
body.inv-landing h4,
body.inv-landing h5,
body.inv-landing h6 {
  margin: 0;
  line-height: 1.2;
  font-family: var(--heading);
}

[data-background] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.ul-li ul {
  margin: 0;
  padding: 0;
}
.ul-li ul li {
  list-style: none;
  display: inline-block;
}

.inv-lan-section-title-watermark {
  top: -25px;
  left: 0;
  z-index: -1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  font-size: 278px;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  font-weight: 700;
  position: absolute;
  white-space: nowrap;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eeeeee),
    color-stop(100.22%, #fff)
  );
  background: linear-gradient(180deg, #eeeeee 0%, #fff 100.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.inv-lan-section-title .subtitle {
  font-size: 15px;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 30px;
  margin-bottom: 28px;
  display: inline-block;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 21px 0px rgba(83, 83, 83, 0.1);
  box-shadow: 0px 10px 21px 0px rgba(83, 83, 83, 0.1);
}
.inv-lan-section-title .subtitle span {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2be492),
    color-stop(100.22%, #3888ff)
  );
  background: linear-gradient(90deg, #2be492 0%, #3888ff 100.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.inv-lan-section-title h2 {
  color: #131115;
  font-size: 48px;
  font-weight: 800;
}
.inv-lan-header-section {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding-top: 30px;
  position: absolute;
}
.inv-lan-header-section .inv-lan-header-btn {
  right: 0;
  top: 20px;
  position: absolute;
}
.inv-lan-header-section .inv-lan-header-btn a {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  padding: 12px 15px;
  display: inline-block;
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2be492),
    color-stop(50%, #3888ff),
    to(#2be492)
  );
  background: linear-gradient(90deg, #2be492 0%, #3888ff 50%, #2be492);
  background-size: 200%, 1px;
  -webkit-transition: all 200ms linear 0ms;
  transition: all 200ms linear 0ms;
}
.inv-lan-header-section .inv-lan-header-btn a:hover {
  background-position: 120%;
}
.inv-lan-header-section .dropdown {
  position: relative;
}
.inv-lan-header-section .dropdown:after {
  top: 4px;
  right: -18px;
  font-size: 14px;
  color: #c3c7c9;
  font-weight: 300;
  content: "\f078";
  font-weight: 400;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.inv-lan-header-section .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  clip: inherit;
  -webkit-transform: scaleY(1);
  -khtml-transform: scaleY(1);
  transform: scaleY(1);
}
.inv-lan-header-section .dropdown:hover:after {
  color: var(--thm-color-4);
}
.inv-lan-header-section .dropdown-menu {
  left: 0;
  top: 62px;
  z-index: 100;
  margin: 0px;
  padding: 20px 0;
  height: auto;
  min-width: 250px;
  display: block;
  border: none;
  border-radius: 0;
  position: absolute;
  opacity: 0;
  background: #fff;
  border-radius: 2px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.inv-lan-header-section .dropdown-menu .dropdown-menu {
  top: 0px;
  left: 100%;
  -webkit-transform: scaleY(0) !important;
  transform: scaleY(0) !important;
  opacity: 0 !important;
  transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
}
.inv-lan-header-section .dropdown-menu .dropdown-menu a {
  font-size: 17px;
  font-weight: 700;
  color: #000000;
}
.inv-lan-header-section .dropdown-menu .dropdown-menu a:hover {
  color: var(--thm-color-4);
}
.inv-lan-header-section .dropdown-menu li {
  display: block;
  margin: 0 !important;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.inv-lan-header-section .dropdown-menu li:last-child {
  border-bottom: none;
}
.inv-lan-header-section .dropdown-menu li:after {
  top: 10px;
  color: #000;
  right: 15px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.inv-lan-header-section .dropdown-menu li:hover .dropdown-menu {
  top: 0;
  -webkit-transform: scaleY(1) !important;
  transform: scaleY(1) !important;
  opacity: 1 !important;
}
.inv-lan-header-section .dropdown-menu a {
  width: 100%;
  display: block;
  color: #000000 !important;
  position: relative;
  padding: 10px 30px !important;
  font-size: 15px !important;
}
.inv-lan-header-section .dropdown-menu a:before,
.inv-lan-header-section .dropdown-menu a:after {
  display: none;
}
.inv-lan-header-section .dropdown-menu a:hover {
  margin-left: 15px;
  color: var(--thm-color-4) !important;
}
.inv-lan-header-section.sticky-on {
  top: 0;
  width: 100%;
  z-index: 20;
  position: fixed;
  padding: 15px 0px 30px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  background-color: #000;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}
.inv-lan-header-section.sticky-on .site-logo {
  top: 7px;
}
.inv-lan-header-section.sticky-on .inv-lan-header-btn {
  top: 15px;
}

.inv-lan-header-main-menu .menu-navigation.left-nav {
  float: left;
  text-align: left;
}
.inv-lan-header-main-menu .menu-navigation.right-nav {
  float: right;
  margin-right: 170px;
  text-align: right;
}
.inv-lan-header-main-menu .site-logo {
  left: 0;
  right: 0;
  top: 12px;
  width: 150px;
  margin: 0 auto;
  position: absolute;
}
.inv-lan-header-main-menu .menu-navigation {
  padding-top: 30px;
}
.inv-lan-header-main-menu .menu-navigation li {
  margin-right: 50px;
}
.inv-lan-header-main-menu .menu-navigation li a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  padding-bottom: 38px;
}
.inv-lan-header-main-menu .menu-navigation li a:hover {
  color: #fff;
}

/*Mobile area*/
/*----------------------------------------------------*/
.mobile_logo {
  top: 10px;
  left: 15px;
  display: none;
  position: absolute;
}

.mobile_menu_content {
  top: 0px;
  bottom: 0;
  left: -350px;
  height: 100vh;
  z-index: 101;
  position: fixed;
  width: 310px;
  overflow-y: scroll;
  background-color: #000;
  padding: 20px 35px 35px 35px;
  -webkit-box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
  box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.19);
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.mobile_menu_content .mobile-main-navigation {
  width: 100%;
}
.mobile_menu_content .mobile-main-navigation .navbar-nav {
  width: 100%;
}
.mobile_menu_content .inv-btn a {
  padding: 15px 20px;
}
.mobile_menu_content .dropdown:after {
  display: none;
}
.mobile_menu_content .navbar-nav .dropdown-menu {
  position: static !important;
  -webkit-transform: none !important;
  transform: none !important;
}
.mobile_menu_content .mobile-main-navigation .navbar-nav li {
  width: 100%;
  display: block;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.mobile_menu_content .mobile-main-navigation .navbar-nav li a {
  padding: 0;
  width: 100%;
  color: #fff;
  display: block;
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  padding: 10px 30px 10px 0;
}
.mobile_menu_content .m-brand-logo {
  width: 180px;
  margin: 50px auto;
}

.mobile_menu_wrap.mobile_menu_on .mobile_menu_content {
  left: 0px;
  -webkit-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}

.mobile_menu_overlay {
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  right: 0%;
  height: 120vh;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.mobile_menu_overlay_on {
  overflow: hidden;
}

.mobile_menu_wrap.mobile_menu_on .mobile_menu_overlay {
  opacity: 1;
  visibility: visible;
}

.mobile_menu_button {
  right: 15px;
  top: -7px;
  z-index: 5;
  color: #fff;
  display: none;
  font-size: 24px;
  cursor: pointer;
  line-height: 38px;
  position: absolute;
  text-align: center;
}

.mobile_menu .mobile-main-navigation {
  margin-bottom: 30px;
}
.mobile_menu .mobile-main-navigation .navbar-nav li a:after {
  display: none;
}
.mobile_menu .mobile-main-navigation .dropdown > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu {
  border: none;
  display: none;
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px 0px;
  width: 100%;
  background-color: transparent;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li {
  border: none;
  padding: 0 10px;
  line-height: 1;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li:hover {
  background-color: transparent;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li
  a {
  color: #fff !important;
}
.mobile_menu
  .mobile_menu_content
  .mobile-main-navigation
  .navbar-nav
  .dropdown-menu
  li
  a:hover {
  background-color: transparent;
}
.mobile_menu .dropdown {
  position: relative;
}
.mobile_menu .dropdown .dropdown-btn {
  top: 3px;
  right: 0;
  height: 30px;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
.mobile_menu .dropdown .dropdown-btn.toggle-open {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mobile_menu .mobile_menu_close {
  top: 25px;
  right: 25px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
}

.inv-lan-banner-text {
  margin: 0 auto;
  max-width: 885px;
  padding: 285px 0px 135px;
}
.inv-lan-banner-text h1 {
  color: #fff;
  font-size: 72px;
  font-weight: 800;
}

.inv-lan-banner-bottom-text {
  margin: 0 auto;
  max-width: 1040px;
  overflow: hidden;
  padding: 85px 120px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.inv-lan-banner-bottom-text h2 {
  color: #1fc2ff;
  font-weight: 700;
  font-size: 36px;
  padding-bottom: 35px;
}
.inv-lan-banner-bottom-text p {
  color: #d7ddee;
  font-size: 22px;
}

.inv-lan-feature-section {
  padding: 105px 0px;
}

.inv-lan-feature-content {
  padding-top: 50px;
}

.inv-lan-feature-item {
  margin-bottom: 70px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-lan-feature-item .inner-icon {
  width: 100px;
  height: 100px;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 28px;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 9px 21px 0px rgba(85, 85, 85, 0.11);
  box-shadow: 0px 9px 21px 0px rgba(85, 85, 85, 0.11);
}
.inv-lan-feature-item .inner-text {
  color: #131115;
  font-size: 20px;
  font-weight: 600;
}
.inv-lan-feature-item:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}
.inv-lan-feature-content {
  padding-bottom: 80px;
}
.inv-lan-feature-content .more-demo-btn {
  left: 0;
  right: 0;
  z-index: 2;
  bottom: -155px;
  position: absolute;
}
.inv-lan-feature-content .more-demo-btn a {
  color: #fff;
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
  padding-top: 50px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  border-radius: 100%;
  background-image: linear-gradient(
    1deg,
    rgb(0, 249, 76) 0%,
    rgb(2, 113, 255) 100%
  );
}
.inv-lan-feature-content .more-demo-btn a span {
  display: block;
  margin: 0 auto;
  line-height: 1.1;
  max-width: 80px;
}
.inv-lan-feature-content .more-demo-btn a i {
  width: 100%;
  padding-top: 10px;
  text-align: center;
  -webkit-animation: jumpInfinite 1.5s infinite;
  animation: jumpInfinite 1.5s infinite;
}

.inv-lan-keyfeature-section {
  padding: 90px 0px 60px;
  overflow: hidden;
}

.inv-lan-keyfeature-content {
  padding-bottom: 70px;
}

.inv-lan-keyfeature-item {
  margin-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.inv-lan-keyfeature-item .inner-icon {
  width: 70px;
  height: 70px;
  margin-right: 20px;
}
.inv-lan-keyfeature-item .inner-text {
  color: #02091d;
  width: 160px;
  font-size: 18px;
  font-weight: 600;
}
.inv-lan-keyfeature-item:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.inv-lan-demo-page-section {
  z-index: 1;
  padding: 170px 0px 50px;
}
.inv-lan-demo-page-section .inv-lan-section-title {
  margin: 0 auto;
  max-width: 910px;
}

.inv-demo-page-content {
  padding-top: 50px;
}

.inv-demo-page-item {
  margin: 0 auto;
  max-width: 475px;
  margin-bottom: 80px;
}
.inv-demo-page-item .inner-img {
  margin-bottom: 30px;
}
.inv-demo-page-item .inv-demo-hover {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.25s cubic-bezier(0.3, 0.3, 0, 0.8);
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0, 0.8);
  opacity: 0;
}
.inv-demo-page-item .inv-demo-hover .hover-img {
  top: -40px;
  right: -40px;
  bottom: -40px;
  left: -40px;
  position: absolute;
  -webkit-filter: blur(15px);
  filter: blur(15px);
}
.inv-demo-page-item .inner-text {
  color: #000;
  margin-top: 15px;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--heading);
}
.inv-demo-page-item .inv-demo-preview-btn {
  left: 0;
  right: 0;
  top: 50%;
  z-index: 2;
  opacity: 0;
  margin-top: 25px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.25s cubic-bezier(0.3, 0.3, 0, 0.8);
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0, 0.8);
}
.inv-demo-page-item .inv-demo-preview-btn a {
  color: #fff;
  margin: 0px 8px;
  font-weight: 800;
  border-radius: 5px;
  padding: 12px 15px;
  display: inline-block;
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2be492),
    color-stop(50%, #3888ff),
    to(#2be492)
  );
  background: linear-gradient(90deg, #2be492 0%, #3888ff 50%, #2be492);
  background-size: 200%, 1px;
  -webkit-transition: all 200ms linear 0ms;
  transition: all 200ms linear 0ms;
}
.inv-demo-page-item .inv-demo-preview-btn a i {
  margin-right: 5px;
}
.inv-demo-page-item .inv-demo-preview-btn a:hover {
  background-position: 120%;
}
.inv-demo-page-item:hover .inv-demo-preview-btn {
  margin-top: 0;
  opacity: 1;
}
.inv-demo-page-item:hover .inv-demo-hover {
  opacity: 1;
}

.inv-lan-faq-section {
  z-index: 1;
  padding: 135px 0px 120px;
}
.inv-lan-faq-section .container {
  max-width: 1300px !important;
}

.inv-lan-faq-top-content .inv-lan-section-title {
  max-width: 630px;
}
.inv-lan-faq-top-content .inv-lan-faq-top-text {
  color: #000;
  font-weight: 500;
  font-size: 20px;
  max-width: 475px;
}

.inv-lan-faq-feature {
  padding-top: 50px;
}

.inv-lan-faq-feature-item .inner-icon {
  margin-right: 28px;
}
.inv-lan-faq-feature-item .inner-text {
  max-width: 260px;
}
.inv-lan-faq-feature-item .inner-text h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 15px;
}
.inv-lan-faq-feature-item .inner-text p {
  color: #39393a;
  padding-bottom: 15px;
}
.inv-lan-faq-feature-item .inner-text a {
  color: #a2a2a2;
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
}
.inv-lan-faq-feature-item .inner-text a:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2be492),
    color-stop(50%, #3888ff),
    to(#2be492)
  );
  background: linear-gradient(90deg, #2be492 0%, #3888ff 50%, #2be492);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.inv-lan-feed-accordion {
  padding-top: 130px;
}
.inv-lan-feed-accordion .accordion-header button {
  color: #000000;
  font-size: 20px;
  border: none;
  font-weight: 600;
  padding: 15px 0px;
  background: transparent;
}
.inv-lan-feed-accordion .accordion-button:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.inv-lan-feed-accordion .accordion-item {
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
}
.inv-lan-feed-accordion .accordion-body {
  padding: 10px 0px 20px;
}
.inv-lan-feed-accordion .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
}
.inv-lan-feed-accordion .accordion-button::after {
  right: 25px;
  height: auto;
  width: auto;
  font-size: 15px;
  content: "\f078";
  font-weight: 900;
  position: absolute;
  background-image: none;
  font-family: "Font Awesome 5 Pro";
}
.inv-lan-feed-accordion .accordion-button:not(.collapsed)::after {
  content: "\f068";
}

.inv-lan-testimonial-section {
  padding: 120px 0px;
}
.inv-lan-testimonial-section .container {
  max-width: 1300px !important;
}

.inv-lan-testimonial-top-content .inv-lan-section-title {
  max-width: 530px;
}

.inv-lan-testimonial-slider-area {
  z-index: 1;
  padding-top: 50px;
}
.inv-lan-testimonial-slider-area .shape_1 {
  bottom: 0;
  right: 33%;
  z-index: -1;
}

.inv-lan-testimonial-top-text h3 {
  color: #000000;
  line-height: 1;
  font-size: 100px;
  font-weight: 600;
}
.inv-lan-testimonial-top-text .inv-lan-client-ratting .ratting-text {
  color: #000000;
  max-width: 180px;
  font-size: 18px;
  font-weight: 500;
}
.inv-lan-testimonial-top-text .inv-lan-client-ratting .ratting-star li {
  color: #eead08;
}
.inv-lan-testimonial-top-text .inv-lan-client-ratting .ratting-star span {
  font-weight: 500;
  font-size: 16px;
}

.inv-lan-testimonial-item {
  padding: 75px 45px 40px;
  position: relative;
  margin-top: 50px;
  opacity: 0.5;
  border-radius: 50px;
  margin-bottom: 60px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.inv-lan-testimonial-item:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 50px;
  position: absolute;
  background-color: #fff;
}
.inv-lan-testimonial-item .inner-icon {
  top: -42px;
  width: 94px;
  height: 94px;
  overflow: hidden;
  position: absolute;
  border-radius: 100%;
}
.inv-lan-testimonial-item .inner-text .desc-text {
  color: #000000;
  font-size: 17px;
}
.inv-lan-testimonial-item .testimonial-author {
  margin-top: 35px;
}
.inv-lan-testimonial-item .testimonial-author .author-text h3 {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}
.inv-lan-testimonial-item .testimonial-author .author-text span {
  color: #39393a;
  font-weight: 600;
}
.inv-lan-testimonial-item .testimonial-author .ratting-star {
  color: #eead08;
}

.inv-lan-testimonial-slider .swiper-slide-active .inv-lan-testimonial-item {
  opacity: 1;
  -webkit-box-shadow: 0 20px 40px rgba(10, 15, 68, 0.12);
  -ms-box-shadow: 0 20px 40px rgba(10, 15, 68, 0.12);
  -o-box-shadow: 0 20px 40px rgba(10, 15, 68, 0.12);
  box-shadow: 0 20px 40px rgba(10, 15, 68, 0.12);
}

.inv-lan-footer-section {
  bottom: 0;
}
.inv-lan-footer-section .footer-copyright {
  padding: 15px 0px;
  background-color: rgba(0, 0, 0, 0.8);
}
.inv-lan-footer-section .footer-copyright p {
  color: #ffffff;
}

.inv-lan-footer-content {
  margin: 0 auto;
  max-width: 700px;
  padding: 100px 0px;
}
.inv-lan-footer-content p {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.inv-lan-footer-content h2 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  padding: 30px 0px;
}
.inv-lan-footer-content a {
  font-weight: 800;
  font-size: 16px;
  padding: 12px 15px;
  border-radius: 5px;
  display: inline-block;
  background-color: #000000;
}
.inv-lan-footer-content a span {
  font-weight: 800;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2be492),
    color-stop(100.22%, #3888ff)
  );
  background: linear-gradient(90deg, #2be492 0%, #3888ff 100.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 991px) {
  .inv-lan-header-main-menu .menu-navigation {
    display: none;
  }
  .inv-lan-header-section .site-logo {
    margin: 0;
    position: static;
  }
  .inv-lan-header-section .inv-lan-header-btn {
    position: static;
    margin-right: 45px;
  }
  .inv-lan-header-main-menu .inv-lan-header-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .inv-lan-header-section.sticky-on {
    padding: 15px 0px;
  }
  .inv-lan-header-main-menu .mobile_menu_button {
    top: -42px;
    right: 0;
    display: block;
  }
  .inv-lan-faq-top-content,
  .inv-lan-testimonial-top-content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-lan-faq-top-content .inv-lan-section-title {
    padding-bottom: 15px;
  }
  .inv-lan-faq-feature-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-lan-faq-feature-item .inner-icon {
    margin-bottom: 15px;
  }
  .inv-lan-client-ratting {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-lan-testimonial-top-text .inv-lan-client-ratting .ratting-text {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .inv-lan-faq-feature-item {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 680px) {
  .inv-lan-banner-text h1 {
    font-size: 50px;
  }
  .inv-lan-banner-bottom-text {
    padding: 50px 30px;
  }
  .inv-demo-page-item .inv-demo-preview-btn {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .inv-demo-page-item .inv-demo-preview-btn a {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 480px) {
  .inv-lan-banner-text h1 {
    font-size: 40px;
  }
  .inv-lan-banner-text {
    padding: 150px 0px 60px;
  }
  .inv-lan-banner-bottom-text h2 {
    font-size: 26px;
    padding-bottom: 15px;
  }
  .inv-lan-banner-bottom-text p {
    font-size: 18px;
  }
  .inv-lan-section-title h2 {
    font-size: 30px;
  }
  .inv-lan-feature-section {
    padding: 60px 0px;
  }
  .inv-lan-header-main-menu .site-logo {
    width: 90px;
  }
  .inv-lan-header-section .inv-lan-header-btn a {
    padding: 10px 12px;
  }
  .inv-lan-testimonial-item {
    padding: 75px 15px 40px;
  }
  .inv-lan-footer-content h2 {
    font-size: 30px;
  }
}

.dark-2 {
  background-image: url(../img/bg/breadcrumb_bg.jpg);
}

.dark-4 {
  background-image: url(../img/bg/footer_bg.jpg);
}

.dark-3 {
  background-image: url(../img/bg/counter_bg.jpg);
}

/* Add your transition styles */
.grid-item {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.grid-item-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.grid-item-enter-active {
  opacity: 1;
  transform: translateY(0);
}

nav.nav.flex-column h4 {
  color: white;
}

.w-full {
  width: -webkit-fill-available;
  width: -moz-available;
}
.content-managament {
  height: 300px;
  overflow: auto;
}

.content-managament::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}

/* Track */
.content-managament::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
.content-managament::-webkit-scrollbar-thumb {
  background: #ff491e;
  border-radius: 8px;
}

/* Handle on hover */
.content-managament::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.box-button-navigate button.active {
  background-color: var(--color-primary);
  background-image: linear-gradient( 1deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100% );
  background-image: linear-gradient( 1deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100% );
  background-image: -webkit-linear-gradient( 1deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100% );
  color: white;
}

.box-button-navigate button {
  width: 40%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;

}

.box-button-navigate {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0px;
}